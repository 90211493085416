import { useFetchStores } from "@multicines/services";
import { useShippingAddressStore } from "@multicines/stores";
import { useGeoStore } from "@multicines/stores";
import { events } from "artisn/analytics";
import { useEffect } from "react";

import CONSTANTS from "config/constants";
import { useGlobalsStore } from "stores/globals/globals.store";
import { useStoresStore } from "stores/stores/stores.store";

const { MAX_DISTANCE } = CONSTANTS;
const { logSetLocation, logChangeLocation } = events.geo;

const useListenGeo = () => {
  const selectedShippingAddress = useShippingAddressStore(
    store => store.selectedShippingAddress
  );
  const selectedCoordinates = useGeoStore(state => state.selectedCoordinates);
  const setSelectedCoordinates = useGeoStore(
    state => state.setSelectedCoordinates
  );
  const deviceCoordinates = useGeoStore(state => state.deviceCoordinates);
  const selectedStore = useStoresStore(store => store.selectedStore);
  const setSelectedStore = useStoresStore(store => store.setSelectedStore);
  const { data: stores } = useFetchStores();
  const { storeId: selectedStoreId } = selectedStore?.[0] ?? {};
  const { distance: selectedStoreDistance } = selectedStore?.[0] ?? {};
  const setShowDistanceAlert = useGlobalsStore(
    state => state.setShowDistanceAlert
  );
  const setCanShowDistanceAlert = useGlobalsStore(
    state => state.setCanShowDistanceAlert
  );
  /**
   * Change selected coordinates every time selected shipping address changes.
   *
   * For cases where the user doesn't have any stored shipping addresses, we
   * also save the last selected user coordinates as a fallback.
   *
   */
  useEffect(() => {
    (async () => {
      if (!selectedShippingAddress && !deviceCoordinates) return;

      setSelectedCoordinates(prev => {
        const { lat: previousLat, lng: previousLng } = prev ?? {};
        const coords = selectedShippingAddress ?? deviceCoordinates;
        const { lat, lng } = coords ?? {};

        if (
          typeof previousLat === "undefined" ||
          typeof previousLng === "undefined"
        ) {
          logSetLocation();
        } else {
          logChangeLocation();
        }

        if (!lat || !lng) return prev;

        return { lat, lng };
      });
    })();
  }, [selectedShippingAddress, deviceCoordinates, setSelectedCoordinates]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCanShowDistanceAlert(true);
    }, 8 * 1000);
    return () => clearTimeout(timer);
  }, [setCanShowDistanceAlert]);

  useEffect(() => {
    if (!selectedCoordinates) return;
    if (typeof selectedStoreDistance !== "number") return;
    setShowDistanceAlert(selectedStoreDistance > MAX_DISTANCE);
  }, [selectedCoordinates, selectedStoreDistance, setShowDistanceAlert]);

  useEffect(() => {
    if (
      typeof selectedStoreDistance === "number" ||
      typeof selectedStoreId === "undefined"
    ) {
      return;
    }
    const store = stores?.find(store => store[0]?.storeId === selectedStoreId);
    if (!store) return;
    setSelectedStore(store);
  }, [
    stores,
    selectedStoreId,
    selectedStoreDistance,
    setSelectedStore,
    setShowDistanceAlert
  ]);
};
export default useListenGeo;
