// Theme store
import { getStoreSetState } from "@multicines/stores";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { defaultValues } from "./theme.store.helpers";
import { ThemeStoreValues } from "./theme.store.types";

export const useThemeStore = create<ThemeStoreValues>()(
  devtools(
    (set, get) => {
      const values: ThemeStoreValues = {
        ...defaultValues,
        setSelectedTheme: payload => {
          const prev = get().selectedTheme;
          const selectedTheme = getStoreSetState(payload, prev);
          set({ selectedTheme }, false, {
            type: "setSelectedTheme"
          });
        }
      };
      return values;
    },
    { name: "Theme store" }
  )
);
