import styled from "styled-components";

import { AddressFormModalStyledProps as Props } from "./AddressFormModal.types";
import CONSTANTS from "config/constants";
import { getMaxHeight } from "utils/styling.utils";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, mobile } = BREAKPOINTS;

const AddressFormModalStyled = styled.div<Props>`
  padding: 0;
  width: 100%;
  height: min(${getMaxHeight()}, 100rem);
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  .AddressFormModal {
    position: relative;

    &__header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 1.6rem;
      padding-top: 3.2rem;

      &__button {
        font-weight: 600;
        font-size: 1.6rem;
        padding: 1rem 2rem;
        font-family: inherit;
        height: 4rem;

        @media (max-width: ${tablet}px) {
          font-size: 1.4rem;
          padding: 0.4rem 0.8rem;
        }

        @media (max-width: ${mobile}px) {
          font-size: 1.2rem;
        }
      }
    }

    &__info {
      font-size: 1.2rem;
      line-height: 1.6rem;

      &__title {
        color: var(--palette-gray-s0-l40);
        padding-bottom: 0.6rem;
      }

      &__position {
        color: var(--palette-black-s0-l10);
        width: 15.2rem;
      }
    }

    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding: 1.6rem;
      font-weight: 600;
      position: relative;
      background-color: var(--palette-white);
      width: 100%;

      &--fixed {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
      }
    }

    &__icon {
      position: absolute;
      cursor: pointer;
      padding: 0;
    }

    &__go-back {
      left: 1.6rem;
    }

    &__close {
      right: 1.6rem;
      z-index: 2;
    }

    &__places {
      margin-top: 18.8rem;
    }

    &__address-list {
      .AddressList__item {
        margin-bottom: 1.6rem;
      }
    }

    &__empty {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &__icon {
        width: 13.6rem;
        height: 13.6rem;
        border-radius: 90%;
        background-color: var(--palette-gray-s0-l98);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__title {
        padding-top: 2rem;
        font-size: 2rem;
      }
    }

    &__address-form {
      padding: 0 2.4rem;
      position: absolute;
      background-color: var(--palette-white);
      overflow-y: auto;
      width: 100%;
      height: min(${getMaxHeight()}, 100rem);
      display: ${props => (props.step === 2 ? "block" : "none")};

      @media (max-width: ${tablet}px) {
        padding: 0 1.6rem;
      }
    }

    &__static-map {
      margin-top: 5.6rem;
      position: relative;
    }

    &__coverage {
      background-color: var(--palette-red-s61-l87);
      color: var(--palette-red-s65-l60);
      font-size: 1.2rem;
      padding: 0.6rem 0.8rem;
    }

    &__map-search {
      padding: 2.4rem;
      padding-top: 0.8rem;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.4rem;
      cursor: pointer;
      border-bottom: 0.8rem solid var(--palette-gray-s0-l98);
      width: 100%;
      position: fixed;
      background-color: var(--palette-white);
      top: 11.4rem;
      z-index: 1;

      @media (max-width: ${tablet}px) {
        padding: 1.6rem;
      }

      @media (max-width: 600px) {
        width: 100%;
      }

      & + .MapAddressPreview {
        margin-top: 18rem;
      }

      svg {
        margin-right: 1.6rem;
      }
    }

    &__map-address-preview {
      @media (max-width: ${tablet}px) {
        padding: 0 1.6rem;
      }

      .MapAddressPreview__info-card {
        .InfoCard__content__title {
          @media (max-width: ${tablet}px) {
            font-size: 1.4rem;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }

    &__address-in-map {
      position: absolute;
      background-color: var(--palette-white);
      width: 100%;
      height: min(${getMaxHeight()}, 100rem);
      z-index: ${props => (props.step === 3 ? "2" : "-1")};
    }

    &__map-preview {
      background-color: var(--palette-white);
      overflow-y: auto;
      width: 100%;
      height: min(${getMaxHeight()}, 100rem);
      display: ${props => (props.step === 1 ? "block" : "none")};

      .SearchAddress {
        position: fixed;
        width: 100%;
        top: 3.8rem;
        z-index: 1;

        @media (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
`;

export default AddressFormModalStyled;
