import React from "react";

import { ArtisnProviderProps as Props } from "./ArtisnProvider.types";

const ArtisnProvider: React.FC<Props> = props => {
  const { children } = props;

  return <>{children}</>;
};

ArtisnProvider.defaultProps = {};

export default ArtisnProvider;
