import { useFetchUser } from "@multicines/services";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { useAuthStore } from "stores/auth/auth.store";
import { useModalsStore } from "stores/modals/modals.store";

const useListenUser = () => {
  const { push, asPath } = useRouter();
  const setClubName = useAuthStore(state => state.setClubName);
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const {
    data: user,
    error,
    isSuccess: isSuccessUser
  } = useFetchUser({ isAnonymous, uid });
  const setPrivacyPoliticsModalVisible = useModalsStore(
    state => state.setPrivacyPoliticsModalVisible
  );

  const { message } = error ?? {};
  const { privacyAcceptDate } = user ?? {};

  // Check if user is registered in Multiclub or not
  useEffect(() => {
    if (isAnonymous) {
      setClubName(undefined);
      return;
    }
    const userAdditionalInfo = user?.additionalInfo;
    if (!userAdditionalInfo) return;
    setClubName(userAdditionalInfo.clubName);
  }, [isAnonymous, setClubName, user]);

  // Redirect user depending on how complete is its registered process
  useEffect(() => {
    if (!isAnonymous && user) return;
    if (asPath === "/profile/support") return;
    if (asPath === "/signup-user" || asPath.includes("redirect")) return;
    if (message === "No user for uid") {
      push(`/signup-user/?redirect=${asPath ?? "/"}`);
    }
  }, [message, push, isAnonymous, uid, user, asPath]);

  useEffect(() => {
    if (isAnonymous) return;
    if (!isSuccessUser) return;
    if (privacyAcceptDate) return;

    const timer = setTimeout(() => {
      setPrivacyPoliticsModalVisible(true);
    }, 5 * 1000);

    return () => {
      clearTimeout(timer);
      setPrivacyPoliticsModalVisible(false);
    };
  }, [
    isAnonymous,
    isSuccessUser,
    setPrivacyPoliticsModalVisible,
    privacyAcceptDate
  ]);
};

export default useListenUser;
