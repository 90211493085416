// useListenTheme customHook

import { useEffect } from "react";

import { useThemeStore } from "stores/theme/theme.store";
import themes from "styles/theme";
import { isDarkThemePreferred } from "utils/theme.utils";

const useListenTheme = () => {
  const setSelectedTheme = useThemeStore(state => state.setSelectedTheme);
  const { light, dark } = themes;

  useEffect(() => {
    if (typeof window === "undefined") return;
    const themeChangeHandler = (e: MediaQueryListEvent) => {
      setSelectedTheme(e.matches ? dark : light);
    };
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    matchMedia.addEventListener("change", themeChangeHandler);

    return () => matchMedia.removeEventListener("change", themeChangeHandler);
  }, [light, dark, setSelectedTheme]);

  useEffect(() => {
    (async () => {
      const isDark = await isDarkThemePreferred();
      const initialTheme = isDark ? dark : light;
      setSelectedTheme(initialTheme);
    })();
  }, [light, dark, setSelectedTheme]);
};

export default useListenTheme;
