import { doc, onSnapshot } from "firebase/firestore";
import EmailSVG from "images/email.svg";
import FacebookSVG from "images/facebook.svg";
import GoogleSVG from "images/google.svg";
import React, { useEffect, useState } from "react";

import Styles from "./BenefitsNotification.styles";
import { BenefitsNotificationProps as Props } from "./BenefitsNotification.types";
import FloatingButton from "components/global/FloatingButton/FloatingButton";
import { db } from "config/artisn.config";
import CONSTANTS from "config/constants";

const { ACCOUNT_ID } = CONSTANTS.ARTISN;

const BenefitsNotification: React.FC<Props> = props => {
  const { title = "¿Beneficios exclusivos?" } = props;
  const { message = "Ingresa con tu cuenta y disfruta de beneficios" } = props;
  const { onClick, isAnonymous } = props;
  const [withWhatsapp, setWithWhatsapp] = useState(false);

  useEffect(() => {
    (async () => {
      await onSnapshot(doc(db, `appParameters/${ACCOUNT_ID}`), doc => {
        if (doc.exists()) {
          setWithWhatsapp(doc.data()?.featureFlags?.withWhatsapp);
        }
      });
    })();
  }, []);

  return (
    <>
      {isAnonymous ? (
        <Styles className="BenefitsNotification" onClick={onClick}>
          <div className="BenefitsNotification__content">
            <p className="BenefitsNotification__content__title">{title}</p>
            <p className="BenefitsNotification__content__message">{message}</p>
          </div>
          <div className="BenefitsNotification__iconContainer">
            <FacebookSVG viewBox="0 0 22 22" />
            <GoogleSVG viewBox="0 0 22 22" />
            <EmailSVG viewBox="0 0 22 15" />
          </div>
        </Styles>
      ) : null}
      {withWhatsapp ? <FloatingButton /> : null}
    </>
  );
};

BenefitsNotification.defaultProps = {};

export default BenefitsNotification;
