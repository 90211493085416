import { usePutShippingAddress } from "@multicines/services";
import { useDeleteShippingAddress } from "@multicines/services";
import { useShippingAddressStore } from "@multicines/stores";
import { events } from "artisn/analytics";
import LocationSVG from "images/location.svg";
import TrashSVG from "images/trash.svg";
import React, { useState } from "react";

import Styles, { DeleteAddressModal } from "./ChooseAddress.styles";
import { ChooseAddressProps as Props } from "./ChooseAddress.types";
import ListItem from "components/global/ListItem/ListItem";
import useAnalytics from "hooks/analytics/useAnalytics";
import useTalkShop from "hooks/useTalkShop";
import { useAuthStore } from "stores/auth/auth.store";
import { defaultFunction } from "utils/common.utils";
import { trimFields } from "utils/form.utils";
import { createErrorNotification } from "utils/notifications.utils";
import { dismissErrorNotification } from "utils/notifications.utils";

const { logSelectShippingAddress } = events.shipping;

const ChooseAddress: React.FC<Props> = props => {
  const { shippingAddress, actions, onClick = defaultFunction } = props;
  const { onEdit: onPressEdit = defaultFunction, dropdownFixed } = props;
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const { isTalkShop } = useTalkShop();
  const analyticsHook = useAnalytics({
    isTalkShop
  });
  const { mainStreet, secondaryStreet, number } = shippingAddress;
  const { nickname, id, default: isDefault } = shippingAddress;
  const selectedShippingAddress = useShippingAddressStore(
    store => store.selectedShippingAddress
  );
  const setSelectedShippingAddress = useShippingAddressStore(
    store => store.setSelectedShippingAddress
  );
  const putState = usePutShippingAddress({ isAnonymous, uid });
  const {
    mutateAsync: updateAddress,
    isLoading: isPutLoading,
    reset: resetPut
  } = putState;
  const isActive = selectedShippingAddress?.id === id;
  const [openModal, setOpenModal] = useState(false);
  const deleteState = useDeleteShippingAddress({ isAnonymous, uid });
  const {
    mutateAsync: deleteAddress,
    isLoading: isDeleteLoading,
    reset: resetDelete
  } = deleteState;
  const isLoading = isPutLoading || isDeleteLoading;

  const { commonParams } = analyticsHook;

  const onEdit = () => {
    onPressEdit(shippingAddress);
  };

  const onDelete = () => {
    setOpenModal(true);
  };

  const onDefault = async () => {
    const newShippingAddress = trimFields(shippingAddress);
    await updateAddress(
      { ...newShippingAddress, default: true },
      {
        onError: () => {
          dismissErrorNotification();
          createErrorNotification(
            "Se produjo un error al intentar actualizar los datos de la dirección."
          );
        }
      }
    );
    resetPut();
  };

  const clickHandler = () => {
    logSelectShippingAddress({
      address: `${mainStreet} ${secondaryStreet} ${number}`,
      alias: nickname,
      addressId: id,
      ...commonParams
    });

    setSelectedShippingAddress(shippingAddress);
    onClick(shippingAddress);
  };

  const onClose = () => setOpenModal(false);

  const deleteHandler = async () => {
    await deleteAddress(id, {
      onError: () => {
        dismissErrorNotification();
        createErrorNotification(
          "Se produjo un error al intentar eliminar los datos de la dirección"
        );
      },
      onSuccess: () => {
        setSelectedShippingAddress(undefined);
        onClose();
      }
    });
    resetDelete();
  };

  return (
    <Styles className="ChooseAddress" isActive={isActive} disabled={isLoading}>
      <ListItem
        title={nickname}
        icon={<LocationSVG />}
        description={`${mainStreet}, ${secondaryStreet}-${number}`}
        isLoading={isLoading}
        isDefault={isDefault}
        onClick={clickHandler}
        onEdit={onEdit}
        onDelete={onDelete}
        onDefault={onDefault}
        actions={actions}
        isFixed={dropdownFixed}
      />
      <DeleteAddressModal
        opened={openModal}
        className="DeleteAddressModal"
        icon={<TrashSVG />}
        title="¿Deseas proceder a eliminar?"
        description="Una vez eliminado no podrás recuperar esta información"
        confirmText="Eliminar"
        confirmAction={deleteHandler}
        cancelText="Cancelar"
        cancelAction={onClose}
        onClose={onClose}
      />
    </Styles>
  );
};

ChooseAddress.defaultProps = {};

export default ChooseAddress;
