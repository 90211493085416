// Date utility functions
import { Store } from "artisn/types";

import { WeekDay } from "types/date.types";

export const weekDays: Record<string, WeekDay> = {
  SUNDAY: { es: "Domingo", en: "Sunday", dayOfWeek: 0 },
  MONDAY: { es: "Lunes", en: "Monday", dayOfWeek: 1 },
  TUESDAY: { es: "Martes", en: "Tuesday", dayOfWeek: 2 },
  WEDNESDAY: { es: "Miércoles", en: "Wednesday", dayOfWeek: 3 },
  THURSDAY: { es: "Jueves", en: "Thursday", dayOfWeek: 4 },
  FRIDAY: { es: "Viernes", en: "Friday", dayOfWeek: 5 },
  SATURDAY: { es: "Sábado", en: "Saturday", dayOfWeek: 6 },
  SPECIAL: { es: "Especial", en: "Special", dayOfWeek: 7 }
};

export const transformSecondsToHours = (seconds: number) => {
  let minutes;

  const hours = Math.floor(seconds / 3600);
  minutes = Math.floor((seconds / 60) % 60);
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return hours + ":" + minutes;
};

export const sortSchedule = (
  schedule: Store["schedules"],
  catalogueId: string
) => {
  const filteredSchedule = schedule.filter(
    item => item.catalogueId === catalogueId
  );

  return filteredSchedule.sort((date1, date2) => {
    const a = weekDays[date1.day].dayOfWeek;
    const b = weekDays[date2.day].dayOfWeek;

    if (a > b) {
      return 1;
    }

    if (a < b) {
      return -1;
    }

    return 0;
  });
};

export const getHours = (seconds: number) => {
  return Math.floor(seconds / 3600);
};

export const getMinutes = (seconds: number) => {
  let minutes;

  minutes = Math.floor((seconds / 60) % 60);
  minutes = minutes < 10 ? 0 + minutes : minutes;

  return minutes;
};
