// Project constants
import { Capacitor } from "@capacitor/core";
import { CONSTANTS as SHARED_CONSTANTS } from "@multicines/utils";
import { ArtisnPlatform } from "artisn/types";

import { PickUpOption } from "types/common.types";

export const DEFAULT_PICK_UP_OPTION = {
  id: 0,
  name: "Quiero mi pedido ahora",
  forNow: true
};

export const PICK_UP_OPTIONS: PickUpOption[] = [
  DEFAULT_PICK_UP_OPTION,
  { id: 1, name: "Hora programada", forNow: false }
];

const WHATSAPP_PHONE_NUMBER = "593939377652";

const CONSTANTS = {
  ...SHARED_CONSTANTS,
  // REST API configurations
  API: {
    ...SHARED_CONSTANTS.API,
    MOCK_SERVICES: false,
    MOCK_WITH_INITIAL_USER_DATA: true
  },
  // Activate or deactivate features
  FEATURE_FLAGS: {
    ...SHARED_CONSTANTS.FEATURE_FLAGS,
    // Allows the user to activate or deactivate the dark mode
    WITH_DARK_MODE: true,
    // Show AnonymousPasswordForm component in Checkout
    WITH_CHECKOUT_SIGNUP: false,
    // Activates the express checkout mode for payphone payments
    WITH_PAYPHONE_EXPRESS_CHECKOUT: false,
    // Show product modal
    WITH_PRODUCT_MODAL: true,
    // Whether loyalty functionality should be enabled
    WITH_LOYALTY: true,
    // Activates the purchase by WhatsApp
    WITH_TALK_SHOP: true
  },
  STORAGE: {
    ...SHARED_CONSTANTS.STORAGE,
    PENDING_ORDER_TOKEN: "artisn-pending-order",
    TEMP_SAFARI_ORDER_TOKEN: "artisn-temp-order",
    // Stores talkShop provider
    TALKSHOP_PROVIDER: "TALKSHOP_PROVIDER",
    // Stores talkShop identifier
    TALKSHOP_IDENTIFIER: "TALKSHOP_IDENTIFIER",
    // Stores talkShop medium
    TALKSHOP_MEDIUM: "TALKSHOP_MEDIUM"
  },
  // CSS Break Points
  BREAKPOINTS: {
    mobile: 420,
    tablet: 768,
    desktop: 1024,
    wide: 1440,
    semiWide: 1728,
    hd: 2560
  },
  // General settings
  GENERAL: {
    ...SHARED_CONSTANTS.GENERAL,
    // Platform running the application
    PLATFORM: Capacitor.getPlatform() as ArtisnPlatform
  },
  // Date and time format strings
  DATES_FORMAT: {
    HOUR_MINUTE: "HH:mm",
    COMPLETE_DATE_TIME: "YYYY-MM-DD HH:mm:ss"
  },
  INTEGRATIONS: {
    ...SHARED_CONSTANTS.INTEGRATIONS,
    WHATSAPP: {
      PHONE_NUMBER: WHATSAPP_PHONE_NUMBER,
      RETURN_TO_APP_URL: `https://api.whatsapp.com/send/?phone=${WHATSAPP_PHONE_NUMBER}&text&app_absent=0`
    }
  }
};

export default CONSTANTS;
