import styled from "styled-components";

import { ShoppingCartProductStyledProps as Props } from "./ShoppingCartProduct.types";
import { ShoppingCartProductPlaceholderProps as PlaceholderProps } from "./ShoppingCartProduct.types";
import CONSTANTS from "config/constants";

const { desktop, tablet, mobile } = CONSTANTS.BREAKPOINTS;

const ShoppingCartProductStyled = styled.div<Props>`
  display: flex;
  align-items: center;
  padding: 1.6rem 1.2rem;
  background-color: var(--palette-mid-blue-s15-l8);
  border-radius: 1.6rem;
  margin-bottom: 0.8rem;

  @media (max-width: ${tablet - 1}px) {
    padding: 1.6rem 0;
  }

  .ShoppingCartProduct {
    &__container {
      align-self: center;
      margin-right: 1.2rem;

      @media (max-width: ${tablet}px) {
        margin-right: 0.8rem;
      }

      @media (max-width: ${tablet - 1}px) {
        margin: 0 0.8rem;
      }
    }

    &__image {
      width: 8rem;
      height: 8rem;
      border-radius: 1.2rem;
    }

    &__product {
      display: flex;
      flex: 1;
      justify-content: space-between;
    }

    &__counter {
      flex: 1;
      margin-right: 0;

      @media (max-width: ${desktop}px) {
        margin-right: 1.2rem;
      }

      @media (max-width: ${tablet}px) {
        margin-left: 0.8rem;
        margin-right: 0;
      }

      @media (max-width: ${mobile}px) {
        margin-left: 0;
      }
    }

    &__title {
      margin-bottom: 0.8rem;
      font-size: 1.6rem;
      font-weight: 700;
      color: var(--palette-white);

      @media (max-width: ${tablet}px) {
        font-size: 1.4rem;
      }
    }

    &__description {
      margin-bottom: 0.8rem;
      font-size: 1.4rem;
      color: var(--palette-light-blue-s10-l60);
    }

    &__price-container {
      justify-content: space-between;
      align-items: flex-end;

      @media (max-width: ${tablet}px) {
        flex: 0.5;
      }

      @media (max-width: ${mobile}px) {
        flex: 0.8;
      }
    }

    &__icon {
      display: flex;
      align-items: center;

      place-content: end;
    }

    &__price {
      font-size: 1.6rem;
      font-weight: 700;
      color: var(--palette-white);

      @media (max-width: ${tablet}px) {
        font-size: 1.4rem;
        margin-left: -1.2rem;
      }
    }

    &__close {
      margin-left: 0.8rem;
      background-color: unset;
      border: none;

      @media (max-width: ${mobile}px) {
        margin-right: 0.8rem;
        margin-left: 0.2rem;
      }

      @media (max-width: ${tablet - 1}px) {
        margin-right: 0.8rem;
        background-color: unset;
        border: none;
        margin-left: 0.2rem;
      }
    }

    &__edit {
      padding: 1.6rem 0;

      &-text {
        font-size: 1.4rem;
        font-weight: 700;
        color: var(--palette-light-blue-s10-l60);
      }
    }
  }
`;

export const ShoppingCartProductPlaceholderStyled = styled.div<PlaceholderProps>`
  height: 16.6rem;
  display: flex;
  padding: 0.8rem;

  @media (max-width: ${tablet - 1}px) {
    height: 11.4rem;
  }

  .ShoppingCartProductPlaceholder {
    &__details {
      padding: 1.2rem 0.8rem;
    }

    &__image {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 10rem;
      overflow: hidden;
      position: relative;
      width: 10rem;
    }

    &__price {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 1.6rem;
      overflow: hidden;
      position: relative;
      width: 4.2rem;
    }

    &__name {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 1.6rem;
      overflow: hidden;
      position: relative;
      width: 18.4rem;

      @media (max-width: ${tablet - 1}px) {
        width: 16rem;
      }
    }

    &__description {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 1.6rem;
      overflow: hidden;
      margin-top: 0.8rem;
      margin-bottom: 1.2rem;
      position: relative;
      width: 7.2rem;
    }

    &__shine {
      animation: shine 2.5s ease-in-out infinite;
      background-color: var(--palette-gray-s0-l98);
      height: 300%;
      position: absolute;
      width: 25%;
      opacity: 0.4;
    }
  }
`;

export default ShoppingCartProductStyled;
