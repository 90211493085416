// useTalkShop customHook
import { Storage } from "@capacitor/storage";
import { useCountriesStore } from "@multicines/stores";
import { useRouter } from "next/router";
import { ParsedUrlQueryInput } from "querystring";
import { useCallback, useMemo } from "react";

import CONSTANTS from "config/constants";
import { useStoresStore } from "stores/stores/stores.store";
import { useTalkShopStore } from "stores/talkshop/talkShop.store";

const { WITH_TALK_SHOP } = CONSTANTS.FEATURE_FLAGS;
const { TALKSHOP_PROVIDER, TALKSHOP_IDENTIFIER } = CONSTANTS.STORAGE;
const { TALKSHOP_MEDIUM } = CONSTANTS.STORAGE;

const useTalkShop = () => {
  const { push, replace } = useRouter();
  const selectedCity = useCountriesStore(state => state.selectedCity);
  const selectedStore = useStoresStore(store => store.selectedStore);
  const talkShopProvider = useTalkShopStore(state => state.talkShopProvider);
  const talkShopIdentifier = useTalkShopStore(
    state => state.talkShopIdentifier
  );
  const talkShopMedium = useTalkShopStore(state => state.talkShopMedium);

  const { id: cityId } = selectedCity ?? {};
  const [cinemaStore] = selectedStore ?? [];
  const { storeId } = cinemaStore ?? {};

  const provider = talkShopProvider?.trim();
  const identifier = talkShopIdentifier?.trim();
  const utm_medium = talkShopMedium?.trim();
  const hasProvider = !!provider && provider.length > 0;
  const hasIdentifier = !!identifier && identifier.length > 0;
  const isTalkShop = hasProvider && hasIdentifier && WITH_TALK_SHOP;

  const queryStore = cityId && storeId ? { cityId, storeId } : {};

  const queryTalkShop = useMemo(
    () =>
      isTalkShop
        ? {
            utm_source: provider,
            identifier,
            utm_medium
          }
        : {},
    [identifier, isTalkShop, utm_medium, provider]
  );

  const navigateWithTalkShop = useCallback(
    (pathname: string, query?: ParsedUrlQueryInput) => {
      push({
        pathname,
        query: {
          ...queryStore,
          ...queryTalkShop,
          ...query
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cityId, queryTalkShop, storeId]
  );

  const replaceWithTalkShop = useCallback(
    (pathname: string, query?: ParsedUrlQueryInput) => {
      replace({
        pathname,
        query: {
          ...queryStore,
          ...queryTalkShop,
          ...query
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cityId, queryTalkShop, storeId]
  );

  const saveTalkShopInStorage = useCallback(async () => {
    if (!WITH_TALK_SHOP) return;

    await Storage.set({
      key: TALKSHOP_PROVIDER,
      value: JSON.stringify(talkShopProvider)
    });

    await Storage.set({
      key: TALKSHOP_IDENTIFIER,
      value: JSON.stringify(talkShopIdentifier)
    });

    await Storage.set({
      key: TALKSHOP_MEDIUM,
      value: JSON.stringify(talkShopMedium)
    });
  }, [talkShopIdentifier, talkShopMedium, talkShopProvider]);

  const removeTalkShopStorage = useCallback(async () => {
    await Storage.remove({ key: TALKSHOP_PROVIDER });
    await Storage.remove({ key: TALKSHOP_IDENTIFIER });
    await Storage.remove({ key: TALKSHOP_MEDIUM });
  }, []);

  const getTalkShopInLocalStorage = useCallback(async () => {
    if (!WITH_TALK_SHOP) return {};

    const { value: providerValue } = await Storage.get({
      key: TALKSHOP_PROVIDER
    });
    const { value: identifierValue } = await Storage.get({
      key: TALKSHOP_IDENTIFIER
    });
    const { value: mediumValue } = await Storage.get({
      key: TALKSHOP_MEDIUM
    });

    if (!providerValue || !identifierValue) return {};
    const provider = JSON.parse(providerValue ?? "")?.trim();
    const identifier = JSON.parse(identifierValue ?? "")?.trim();
    const utm_medium = JSON.parse(mediumValue ?? "")?.trim();
    const hasProvider = !!provider && provider.length > 0;
    const hasIdentifier = !!identifier && identifier.length > 0;
    const isTalkShop = hasProvider && hasIdentifier;

    if (!isTalkShop) return {};

    return {
      utm_source: provider,
      identifier,
      utm_medium
    };
  }, []);

  return useMemo(
    () => ({
      isTalkShop,
      getTalkShopInLocalStorage,
      navigateWithTalkShop,
      replaceWithTalkShop,
      saveTalkShopInStorage,
      removeTalkShopStorage
    }),
    [
      getTalkShopInLocalStorage,
      isTalkShop,
      navigateWithTalkShop,
      removeTalkShopStorage,
      replaceWithTalkShop,
      saveTalkShopInStorage
    ]
  );
};

export default useTalkShop;
