import LocationSVG from "images/location.svg";
import React from "react";

import Styles from "./MapAddressPreview.styles";
import { MapAddressPreviewProps as Props } from "./MapAddressPreview.types";
import InfoCard from "components/global/InfoCard/InfoCard";
import { defaultFunction } from "utils/common.utils";

const MapAddressPreview: React.FC<Props> = props => {
  const { address, onClick = defaultFunction, className } = props;

  return (
    <Styles className={`MapAddressPreview ${className}`} onClick={onClick}>
      <InfoCard
        className="MapAddressPreview__info-card"
        Icon={LocationSVG}
        title={address}
        value=""
      />
    </Styles>
  );
};

MapAddressPreview.defaultProps = {
  className: ""
};

export default MapAddressPreview;
