import styled from "styled-components";

import { MergeCartModalStyledProps as Props } from "./MergeCartModal.types";
import InfoActionModal from "../InfoActionModal/InfoActionModal";

const MergeCartModalStyled = styled(InfoActionModal)<Props>`
  .MergeCartModal {
  }

  .InfoActionModal {
    &__title {
      padding-top: 4.2rem;
    }

    &__description {
      padding-top: 2rem;
    }

    &__buttons {
      padding-top: 3.2rem;
    }

    &__button {
      padding: 1.6rem 1rem;
    }
  }
`;

export default MergeCartModalStyled;
