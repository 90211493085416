import { getStoreSetState } from "@multicines/stores";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { defaultValues, defaultFilters } from "./globals.helpers";
import { GlobalsStoreValues } from "./globals.store.types";

export const useGlobalsStore = create<GlobalsStoreValues>()(
  devtools(
    (set, get) => {
      const values: GlobalsStoreValues = {
        ...defaultValues,
        setCanShowDistanceAlert: payload => {
          const prev = get().canShowDistanceAlert;
          const canShowDistanceAlert = getStoreSetState(payload, prev);
          set({ canShowDistanceAlert }, false, {
            type: "setCanShowDistanceAlert",
            payload
          });
        },
        setDisabledWeb: payload => {
          const prev = get().disabledWeb;
          const disabledWeb = getStoreSetState(payload, prev);
          set({ disabledWeb });
        },
        setShowHighTrafficModal: payload => {
          const prev = get().showHighTrafficModal;
          const showHighTrafficModal = getStoreSetState(payload, prev);
          set({ showHighTrafficModal });
        },
        setShowDistanceAlert: payload => {
          const prev = get().showDistanceAlert;
          const showDistanceAlert = getStoreSetState(payload, prev);
          set({ showDistanceAlert }, false, {
            type: "setShowDistanceAlert",
            payload
          });
        },
        setModalityFilter: payload => {
          const prev = get().modalityFilter;
          const modalityFilter = getStoreSetState(payload, prev);
          set({ modalityFilter }, false, {
            type: "setModalityFilter",
            payload
          });
        },
        setLanguageFilter: payload => {
          const prev = get().languageFilter;
          const languageFilter = getStoreSetState(payload, prev);
          set({ languageFilter }, false, {
            type: "setLanguageFilter",
            payload
          });
        },
        setTheaterFilter: payload => {
          const prev = get().theaterFilter;
          const theaterFilter = getStoreSetState(payload, prev);
          set({ theaterFilter }, false, {
            type: "setTheaterFilter",
            payload
          });
        },
        setFormatFilter: payload => {
          const prev = get().formatFilter;
          const formatFilter = getStoreSetState(payload, prev);
          set({ formatFilter }, false, {
            type: "setFormatFilter",
            payload
          });
        },
        setNewOrder: payload => {
          const prev = get().newOrder;
          const newOrder = getStoreSetState(payload, prev);
          set({ newOrder }, false, {
            type: "setNewOrder",
            payload
          });
        },
        resetGlobalsContext: () => {
          set({ ...defaultValues }, false, { type: "resetGlobalsContext" });
        },
        resetFilters: () => {
          set({ ...defaultFilters }, false, { type: "resetFilters" });
        }
      };
      return values;
    },
    { name: "Globals store" }
  )
);
