import styled from "styled-components";

import { AddressFormStyledProps as Props } from "./AddressForm.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { mobile } = BREAKPOINTS;

const AddressFormStyled = styled.div<Props>`
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  background-color: var(--palette-white);

  .AddressForm {
    &__phone-input,
    &__text-input {
      padding-bottom: 2.4rem;
    }

    &__nickname-input {
      display: none;
      padding-top: 2.4rem;
      width: 100%;

      .TextInput__input {
        width: 100%;
      }

      &--show {
        display: block;
      }
    }

    &__checkbox {
      padding-top: 3.2rem;
    }

    &__line {
      width: 100%;
      height: 0.8rem;
      background-color: var(--palette-gray-s0-l98);
      margin-bottom: 2.4rem;
    }

    &__buttonGroup {
      padding-bottom: 3.2rem;
      padding-top: 3.2rem;

      &__title {
        color: var(--palette-gray-s0-l80);
        padding-bottom: 0.4rem;
        font-size: 1.2rem;
      }

      &__options {
        display: flex;
      }
    }

    &__option {
      &:not(:first-of-type) {
        padding-left: 0.8rem;
      }

      &__radio {
        display: none;

        &:checked + .AddressForm__option__button {
          border-color: var(--palette-black-s0-l10);
        }
      }

      &__button {
        display: inline-flex;
        cursor: pointer;
        height: 4.4rem;
        align-items: center;
        padding: 1.2rem;
        border: 0.1rem solid var(--palette-gray-s0-l95);
        border-radius: 0.4rem;
        width: 10.4rem;
        text-align: center;
        font-weight: 600;
        color: var(--palette-black-s0-l10);
        font-size: 1.2rem;

        @media (max-width: ${mobile}px) {
          width: 100%;
        }

        svg {
          margin-right: 0.8rem;

          path {
            fill: var(--palette-black-s0-l10);
          }
        }
      }
    }

    &__phone-title,
    &__buttonGroup-title {
      color: var(--palette-gray-s0-l80);
      padding-bottom: 1.6rem;
      font-size: 1.2rem;
    }

    &__phone-title {
      padding-bottom: 0.4rem;
      line-height: 1.6rem;
    }

    &__phone-number {
      font-size: 1.2rem;
      line-height: 1.6rem;
      padding-bottom: 3.2rem;
    }

    &__error-message {
      color: var(--palette-red-s65-l60);
      font-size: 1.2rem;
      padding-bottom: 2.4rem;
    }

    &__button {
      width: 100%;
      padding: 1.2rem;
      margin-top: 0.8rem;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
`;

export default AddressFormStyled;
