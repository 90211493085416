import { useEffect } from "react";

import { facebookProvider, getAuth } from "config/artisn.config";
import useAuth from "hooks/useAuth/useAuth";

const useListenAuth = () => {
  const { listenAuthState } = useAuth();

  useEffect(() => {
    return listenAuthState();
  }, [listenAuthState]);

  useEffect(() => {
    const { conversionsApiConfig } = facebookProvider ?? {};
    const { countryISOCode, eventSourceUrl } = conversionsApiConfig ?? {};
    const { fbAccessToken, apiURL } = conversionsApiConfig ?? {};
    const { fbPixelId } = conversionsApiConfig ?? {};

    if (!countryISOCode || !apiURL || !fbPixelId) return;
    if (!eventSourceUrl || !fbAccessToken) return;

    const idTokenSubscribe = getAuth.onIdTokenChanged(async user => {
      if (!user) return;
      const token = await user.getIdToken();
      facebookProvider.updateConfig({
        isTrackingAllowed: false,
        conversionsApiConfig: {
          ...facebookProvider.conversionsApiConfig,
          fbPixelId,
          countryISOCode,
          apiURL,
          eventSourceUrl,
          fbAccessToken,
          authToken: `Bearer ${token}`
        }
      });
    });

    return idTokenSubscribe;
  }, []);
};

export default useListenAuth;
