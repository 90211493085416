import styled from "styled-components";

export const ButtonStyled = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  background-color: transparent;
  box-shadow: none;
  border-radius: 1.2rem;
  border: none;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 1rem 2.4rem;
  min-width: max-content;
  transition: all 0.4s ease 0s;

  &:hover:not([disabled]) {
    box-shadow: none;
    cursor: pointer;
  }

  &:disabled {
    border: none;
    background-color: var(--palette-gray-s0-l80);
    color: var(--palette-white);
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const PrimaryButtonStyled = styled(ButtonStyled)`
  background-color: var(--palette-yellow-s97-l54);
  color: var(--palette-black-s15-l8);

  &:hover:not([disabled]) {
    box-shadow: 0rem 0rem 0rem var(--palette-gray-s0-l95),
      0rem 0rem 0.4rem var(--palette-gray-s0-l92);
  }
`;

export const SecondaryButtonStyled = styled(ButtonStyled)`
  background-color: var(--palette-gray-s0-l30-a40);
  color: var(--palette-white);

  &:hover:not([disabled]) {
    box-shadow: 0rem 0rem 0rem var(--palette-gray-s0-l95),
      0rem 0rem 0.4rem var(--palette-gray-s0-l92);
  }
`;

export const LinkButtonStyled = styled(ButtonStyled)`
  background-color: transparent;
  color: var(--palette-yellow-s97-l54);
`;
