import React, { useEffect } from "react";
import { animated, useSpring } from "react-spring";

import Styles from "./DistanceNotification.styles";
import { DistanceNotificationProps as Props } from "./DistanceNotification.types";
import ChangeStore from "components/Cart/ChangeStore/ChangeStore";
import Close from "components/global/Close/Close";
import useI18n from "hooks/useI18n";
import { useGlobalsStore } from "stores/globals/globals.store";

const DistanceNotification: React.FC<Props> = props => {
  const setCanShowDistanceAlert = useGlobalsStore(
    state => state.setCanShowDistanceAlert
  );
  const setShowDistanceAlert = useGlobalsStore(
    state => state.setShowDistanceAlert
  );
  const t = useI18n();

  const [animationStyles, api] = useSpring(() => {
    return {
      from: { width: "1%" },
      config: { tension: 280, friction: 150 }
    };
  }, []);

  useEffect(() => {
    api.start({ width: "100%" });
  }, [api]);

  const handleDistanceAlert = () => {
    setCanShowDistanceAlert(false);
    setShowDistanceAlert(false);
  };

  return (
    <Styles className="DistanceNotification">
      <div className="DistanceNotification__row">
        <p className="DistanceNotification__title">
          {t.global.distanceAlert.title}
        </p>
        <ChangeStore
          buttonStyle="DistanceNotification__button"
          textButton={t.common.change}
        />
        <Close
          className="DistanceNotification__close"
          onClick={handleDistanceAlert}
        />
      </div>
      <div className="DistanceNotification__gradient-bar">
        <animated.div
          className="DistanceNotification__gradient-bar__progress"
          style={animationStyles}
        />
      </div>
    </Styles>
  );
};

DistanceNotification.defaultProps = {};

export default DistanceNotification;
