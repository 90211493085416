import { useCataloguesStore } from "@multicines/stores";
import { events } from "artisn/analytics";
import { useEffect, useRef } from "react";

import { useAnalyticsStore } from "stores/analytics/analytics.store";
import { Workflow } from "types/workflow.types";

const { logSelectWorkflow } = events.workflow;

const useListenGlobals = () => {
  const selectedCatalogue = useCataloguesStore(
    state => state.selectedCatalogue
  );
  const analyticsInitialized = useAnalyticsStore(
    state => state.analyticsInitialized
  );
  const { name } = selectedCatalogue ?? {};
  const selectWorkflowLogged = useRef(false);

  useEffect(() => {
    if (selectWorkflowLogged.current || !analyticsInitialized) return;
    logSelectWorkflow({
      workflow: name as Workflow
    });
    selectWorkflowLogged.current = true;
  }, [analyticsInitialized, name]);
};

export default useListenGlobals;
