// Theme store helper functions and data

import { StoreInitialValues } from "@multicines/stores";

import { ThemeStoreValues } from "./theme.store.types";
import themes from "styles/theme";
import { isDarkThemePreferredSync } from "utils/theme.utils";

const { light, dark } = themes;
const initialTheme = isDarkThemePreferredSync() ? dark : light;

export const defaultValues: StoreInitialValues<ThemeStoreValues> = {
  selectedTheme: initialTheme
};
