// Custom Events

import { events } from "artisn/analytics";

import CONSTANTS from "config/constants";

const { logCustomEvent } = events;
const { PLATFORM } = CONSTANTS.GENERAL;

/**
 * Event used when user view billboard screen.
 * @since 1.0.0
 */
export const logViewSnackCatalogue = (logData?: object) => {
  logCustomEvent("view_catalogue", {
    category_item: "snacks",
    ...logData
  });
};

/**
 * Event used when user click in shopping cart explore more snacks catalogue.
 * @since 1.0.0
 */
export const logExploreSnacksCatalogue = (logData?: object) => {
  logCustomEvent("explore_snacks_catalogue", {
    ...logData
  });
};

/**
 * Event used when user click in snacks banner.
 * @since 1.0.0
 */
export const logSelectSnacksBanner = (logData?: object) => {
  logCustomEvent("select_snacks_banner", {
    platform: PLATFORM ?? "banner without platform",
    ...logData
  });
};

/**
 * Event used when user click in menu tab.
 * @since 1.0.0
 */
export const logSelectTab = (logData?: object) => {
  logCustomEvent("select_tab", {
    ...logData
  });
};
