import styled from "styled-components";

import { SelectCinemaStyledProps as Props } from "./SelectCinema.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const SelectCinemaStyled = styled.div<Props>`
  background-color: var(--palette-dark-blue-s13-l11);
  border-radius: 1.6rem;
  padding: 2.4rem;

  @media (max-width: ${mobile}px) {
    padding: 2.4rem 0.8rem;
  }

  .SelectCinema {
    &__brands {
      display: flex;
      justify-content: center;
      margin-bottom: 1.6rem;
    }

    &__name {
      color: var(--palette-white);

      &__container {
        text-align: start;
      }
    }

    &__distance {
      color: var(--palette-light-blue-s10-l60);
    }

    &__button {
      color: var(--palette-mid-blue-s15-l8);
      font-weight: 600;
      font-size: 1.4rem;
      min-width: 100%;
      padding: 1.2rem;
      margin-top: 1.6rem;
      margin-bottom: 1.6rem;
    }

    &__city {
      display: flex;
      padding-top: 2.4rem;
      padding-bottom: 0.8rem;
      width: 100%;
      scroll-snap-type: x proximity;
      position: relative;
      scrollbar-width: none;

      &__name {
        background-color: var(--palette-mid-blue-s15-l8);
        border: 0.1rem solid var(--palette-mid-blue-s15-l20);
        border-radius: 2.4rem;
        margin-right: 0.8rem;
        font-weight: 600;
        font-size: 1.4rem;

        &:hover:not([disabled]) {
          box-shadow: 0rem 0rem 0rem var(--palette-gray-s0-l95),
            0rem 0rem 0.4rem var(--palette-gray-s0-l92);
        }
      }

      &__selected {
        background-color: var(--palette-mid-blue-s15-l20);
      }
    }

    &__store {
      padding: 1.6rem 0;

      &__name {
        background-color: var(--palette-dark-blue-s13-l11);
        padding: 1.6rem;
        border: none;
        box-shadow: none;
        border-radius: 0.8rem;
        min-width: 100%;
        justify-content: space-between;
        margin-bottom: 0.8rem;
        font-weight: 600;
        font-size: 1.4rem;

        &:hover:not([disabled]) {
          background-color: var(--palette-mid-blue-s15-l20);
          border: none;
          box-shadow: none;
        }
      }

      &__selected {
        background-color: var(--palette-mid-blue-s15-l20);
        border: none;

        path {
          fill: var(--palette-primary);
        }
      }
    }

    &__item {
      border: none;
      box-shadow: none;
      color: var(--palette-white);
      width: 20rem;
      display: flex;
      align-items: center;
      transition: all 0.2s;
      padding: 1rem 1.6rem;
      background-color: transparent;

      & > *:not(:last-child) {
        margin-right: 0rem;
      }

      &:focus,
      &:hover,
      &:active {
        box-shadow: none;
      }

      &:focus-visible {
        box-shadow: none;
        outline: 0.1rem solid var(--palette-primary);
        outline-offset: -0.1rem;
      }
    }
  }
`;

export default SelectCinemaStyled;
