// useAnalytics customHook

import { checkInit } from "artisn/analytics";
import { useMemo } from "react";

import { UseAnalyticsProps as Props } from "./useAnalytics.types";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";
import { logCancelAttemptUnsubscribe } from "utils/analytics/customEvents.utils";
import { logInitTalkshop } from "utils/analytics/customEvents.utils";
import { logMulticlubRegistrationFinish } from "utils/analytics/customEvents.utils";
import { logSelectAdsBanner } from "utils/analytics/customEvents.utils";
import { logSelectDate } from "utils/analytics/customEvents.utils";
import { logSelectHour } from "utils/analytics/customEvents.utils";
import { logSelectLocationCinema } from "utils/analytics/customEvents.utils";
import { logSelectLogin } from "utils/analytics/customEvents.utils";
import { logSelectMembership } from "utils/analytics/customEvents.utils";
import { logSelectMulticlubLogin } from "utils/analytics/customEvents.utils";
import { logSelectMulticlubLoginProfile } from "utils/analytics/customEvents.utils";
import { logSelectPurchaseHistory } from "utils/analytics/customEvents.utils";
import { logSelectRegistration } from "utils/analytics/customEvents.utils";
import { logUnsubscribeAccount } from "utils/analytics/customEvents.utils";
import { logUpdatePolitics } from "utils/analytics/customEvents.utils";
import { logViewMulticlub } from "utils/analytics/customEvents.utils";
import { logViewMulticlubRegistered } from "utils/analytics/customEvents.utils";
import { logViewPolitics } from "utils/analytics/customEvents.utils";
import { logViewProfile } from "utils/analytics/customEvents.utils";
import { logViewRegistrationStart } from "utils/analytics/customEvents.utils";
import { logContentView } from "utils/analytics/customMovieEvents.utils";
import { logCancelProcess } from "utils/analytics/customMovieEvents.utils";
import { logDeselectSeat } from "utils/analytics/customMovieEvents.utils";
import { logSelectMoviesBanner } from "utils/analytics/customMovieEvents.utils";
import { logSelectMovie } from "utils/analytics/customMovieEvents.utils";
import { logSelectSeat } from "utils/analytics/customMovieEvents.utils";
import { logReservation } from "utils/analytics/customMovieEvents.utils";
import { logRemoveTickets } from "utils/analytics/customMovieEvents.utils";
import { logSelectTickets } from "utils/analytics/customMovieEvents.utils";
import { logSelectTrailer } from "utils/analytics/customMovieEvents.utils";
import { logViewTickets } from "utils/analytics/customMovieEvents.utils";
import { logViewMovieDetails } from "utils/analytics/customMovieEvents.utils";
import { logViewSeats } from "utils/analytics/customMovieEvents.utils";
import { logViewMovieCatalogue } from "utils/analytics/customMovieEvents.utils";
import { logSelectTab } from "utils/analytics/customSnackEvents.utils";
import { logExploreSnacksCatalogue } from "utils/analytics/customSnackEvents.utils";
import { logSelectSnacksBanner } from "utils/analytics/customSnackEvents.utils";
import { logViewSnackCatalogue } from "utils/analytics/customSnackEvents.utils";

const useAnalytics = (props: Props) => {
  const { isSnackWorkflow = false, isTalkShop = false } = props;
  const clubName = useAuthStore(state => state.clubName);
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const selectedStore = useStoresStore(store => store.selectedStore);
  const isAnalyticsInitialized = checkInit();

  const [ticketStore, snackStore] = selectedStore ?? [];
  const { vendor: ticketVendor } = ticketStore ?? {};
  const { name: ticketVendorName } = ticketVendor ?? {};
  const { storeName: ticketStoreName } = ticketStore ?? {};
  const { vendor: snackVendor } = snackStore ?? {};
  const { storeName: snackStoreName } = snackStore ?? {};
  const { name: snackVendorName } = snackVendor ?? {};
  const workflowParams = useMemo(() => {
    if (isSnackWorkflow) {
      return {
        store_name: snackStoreName,
        vendor_name: snackVendorName
      };
    }

    return {
      store_name: ticketStoreName,
      vendor_name: ticketVendorName
    };
  }, [
    isSnackWorkflow,
    snackStoreName,
    snackVendorName,
    ticketStoreName,
    ticketVendorName
  ]);

  const userParams = useMemo(() => {
    if (isAnonymous) {
      return { user_type: "anonymous" };
    }

    return {
      user_type: clubName ? "multiclub" : "registered"
    };
  }, [clubName, isAnonymous]);

  const talkShopParams = useMemo(() => {
    if (isTalkShop) {
      return {
        utm_source: "whatsapp"
      };
    }

    return {};
  }, [isTalkShop]);

  const commonParams = useMemo(
    () => ({
      ...workflowParams,
      ...userParams,
      ...talkShopParams
    }),
    [workflowParams, userParams, talkShopParams]
  );

  const canBeSendAnalytics =
    typeof window !== "undefined" && isAnalyticsInitialized && isAnonymous;

  const ticketEvents = useMemo(() => {
    return {
      logViewMovieCatalogue: (params?: object) => {
        logViewMovieCatalogue({ ...commonParams, ...params });
      },
      logSelectMovie: (params?: object) => {
        logSelectMovie({ ...commonParams, ...params });
      },
      logViewMovieDetails: (params?: object) => {
        logViewMovieDetails({ ...commonParams, ...params });
      },
      logSelectTrailer: (params?: object) => {
        logSelectTrailer({ ...commonParams, ...params });
      },
      logViewTickets: (params?: object) => {
        logViewTickets({ ...commonParams, ...params });
      },
      logSelectTickets: (params?: object) => {
        logSelectTickets({ ...commonParams, ...params });
      },
      logRemoveTickets: (params?: object) => {
        logRemoveTickets({ ...commonParams, ...params });
      },
      logViewSeats: (params?: object) => {
        logViewSeats({ ...commonParams, ...params });
      },
      logSelectSeat: (params?: object) => {
        logSelectSeat({ ...commonParams, ...params });
      },
      logDeselectSeat: (params?: object) => {
        logDeselectSeat({ ...commonParams, ...params });
      },
      logContentView: (params?: object) => {
        logContentView({ ...commonParams, ...params });
      },
      logReservation: (params?: object) => {
        logReservation({ ...commonParams, ...params });
      },
      logCancelProcess: (params?: object) => {
        logCancelProcess({ ...commonParams, ...params });
      },
      logSelectMoviesBanner: (params?: object) => {
        logSelectMoviesBanner({ ...commonParams, ...params });
      }
    };
  }, [commonParams]);

  const snackEvents = useMemo(() => {
    return {
      logViewSnackCatalogue: (params?: object) => {
        logViewSnackCatalogue({ ...commonParams, ...params });
      },
      logExploreSnacksCatalogue: (params?: object) => {
        logExploreSnacksCatalogue({ ...commonParams, ...params });
      },
      logSelectSnacksBanner: (params?: object) => {
        logSelectSnacksBanner({ ...commonParams, ...params });
      }
    };
  }, [commonParams]);

  const generalEvents = useMemo(() => {
    return {
      logSelectLocationCinema: (params?: object) => {
        logSelectLocationCinema({ ...commonParams, ...params });
      },
      logSelectLogin: (params?: object) => {
        logSelectLogin({ ...commonParams, ...params });
      },
      logSelectTab: (params?: object) => {
        logSelectTab({ ...commonParams, ...params });
      },
      logViewRegistrationStart: (params?: object) => {
        logViewRegistrationStart({
          ...commonParams,
          ...params
        });
      },
      logSelectRegistration: (params?: object) => {
        logSelectRegistration({ ...commonParams, ...params });
      },
      logUpdatePolitics: (params?: object) => {
        logUpdatePolitics({ ...commonParams, ...params });
      },
      logViewPolitics: (params?: object) => {
        logViewPolitics({ ...commonParams, ...params });
      },
      logSelectMulticlubLogin: (params?: object) => {
        logSelectMulticlubLogin({
          ...commonParams,
          ...params
        });
      },
      logSelectAdsBanner: (params?: object) => {
        logSelectAdsBanner({ ...commonParams, ...params });
      },
      logSelectDate: (params?: object) => {
        logSelectDate({ ...commonParams, ...params });
      },
      logSelectHour: (params?: object) => {
        logSelectHour({ ...commonParams, ...params });
      },
      logSelectPurchaseHistory: (params?: object) => {
        logSelectPurchaseHistory({ ...commonParams, ...params });
      },
      logSelectMembership: (params?: object) => {
        logSelectMembership({ ...commonParams, ...params });
      },
      logViewProfile: (params?: object) => {
        logViewProfile({ ...commonParams, ...params });
      },
      logViewMulticlubRegistered: (params?: object) => {
        logViewMulticlubRegistered({
          ...commonParams,
          ...params
        });
      },
      logSelectMulticlubLoginProfile: (params?: object) => {
        logSelectMulticlubLoginProfile({
          ...commonParams,
          ...params
        });
      },
      logMulticlubRegistrationFinish: (params?: object) => {
        logMulticlubRegistrationFinish({
          ...commonParams,
          ...params
        });
      },
      logViewMulticlub: (params?: object) => {
        logViewMulticlub({
          ...commonParams,
          ...params
        });
      },
      logCancelAttemptUnsubscribe: (params?: object) => {
        logCancelAttemptUnsubscribe({
          ...commonParams,
          ...params
        });
      },
      logUnsubscribeAccount: (params?: object) => {
        logUnsubscribeAccount({
          ...commonParams,
          ...params
        });
      },
      logInitTalkshop: (params?: object) => {
        logInitTalkshop({
          ...commonParams,
          ...params
        });
      }
    };
  }, [commonParams]);

  return useMemo(() => {
    return {
      canBeSendAnalytics,
      commonParams,
      userParams,
      talkShopParams,
      ...ticketEvents,
      ...snackEvents,
      ...generalEvents
    };
  }, [
    canBeSendAnalytics,
    commonParams,
    generalEvents,
    snackEvents,
    talkShopParams,
    ticketEvents,
    userParams
  ]);
};

export default useAnalytics;
