// useTalkshop customHook

import { useRouter } from "next/router";
import { useEffect } from "react";

import { TalkShopProvider as ITalkShopProvider } from "services/talkShop/talkShop/talkShop.service.types";
import { useTalkShopStore } from "stores/talkshop/talkShop.store";
import { sanitizeQueryParams } from "utils/common.utils";

const useListenTalkshop = () => {
  const { query } = useRouter();
  const { utm_source, identifier, utm_medium } = sanitizeQueryParams(query);
  const setTalkShopProvider = useTalkShopStore(
    state => state.setTalkShopProvider
  );
  const setTalkShopIdentifier = useTalkShopStore(
    state => state.setTalkShopIdentifier
  );
  const setTalkShopMedium = useTalkShopStore(state => state.setTalkShopMedium);

  useEffect(() => {
    if (!utm_source || !identifier) return;
    setTalkShopProvider(utm_source as ITalkShopProvider);
    setTalkShopIdentifier(identifier);
    setTalkShopMedium(utm_medium);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier, utm_medium, utm_source]);
};

export default useListenTalkshop;
