import CheckSVG from "images/check.svg";
import React, { ChangeEvent, useState } from "react";

import Styles from "./Checkbox.styles";
import { CheckboxProps as Props } from "./Checkbox.types";

const Checkbox = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    id,
    name,
    label,
    className,
    errorMessage,
    defaultChecked,
    onChange,
    ...inputProps
  } = props;
  const [isChecked, setIsChecked] = useState(!!defaultChecked);
  const errorMessageId = `${id ?? name}-error`;

  const activeClassName = isChecked ? "Checkbox__check--active" : "";

  const localOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
    setIsChecked(event.currentTarget.checked);
  };

  const renderLabel = () => {
    if (!label) return null;
    if (typeof label === "string") {
      return <p className="Checkbox__label">{label}</p>;
    }
    return label;
  };

  const renderErrorMessage = () => {
    if (!errorMessage) return null;

    if (typeof errorMessage === "string") {
      return (
        <span id={errorMessageId} className="Checkbox__errorMessage">
          {errorMessage}
        </span>
      );
    }

    return <div className="Checkbox__errorMessage">{errorMessage}</div>;
  };

  return (
    <Styles className={`Checkbox ${className}`}>
      <div className="Checkbox__container">
        <div className={`Checkbox__check ${activeClassName}`}>
          <input
            {...inputProps}
            id={id ?? name}
            name={name}
            type="checkbox"
            className="Checkbox__input"
            onChange={localOnChange}
            checked={isChecked}
            aria-invalid={!!errorMessage?.toString()}
            aria-errormessage={errorMessageId}
            ref={ref}
          />
          {isChecked ? <CheckSVG className="Checkbox__icon" /> : null}
        </div>
        {renderLabel()}
      </div>
      {renderErrorMessage()}
    </Styles>
  );
});

Checkbox.displayName = "Checkbox";

Checkbox.defaultProps = {
  className: ""
};

export default Checkbox;
