import styled from "styled-components";

import { AddressListStyledProps as Props } from "./AddressList.types";
import CONSTANTS from "config/constants";

const { desktop } = CONSTANTS.BREAKPOINTS;

const AddressListStyled = styled.div<Props>`
  flex: 1;

  .AddressList {
    &__item {
      &:not(:last-child) {
        margin-bottom: 3.2rem;
      }
    }

    &__student-icon {
      margin-right: 2rem;

      path {
        fill: var(--palette-gray-s0-l80);
      }
    }

    &__empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;

      &__title {
        color: var(--palette-black-s0-l10);
        font-size: 2.4rem;
        padding-bottom: 1.2rem;
        padding-top: 2rem;
      }

      &__description {
        color: var(--palette-gray-s0-l40);
        font-size: 1.2rem;
        line-height: 1.6rem;

        @media (min-width: ${desktop}px) {
          max-width: 26.4rem;
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
      }

      &__icon {
        width: 12.8rem;
        height: 12.8rem;
        background-color: var(--palette-gray-s0-l98);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          margin: 0;
          width: 5.6rem;
          height: 4.8rem;
        }
      }
    }
  }
`;

export default AddressListStyled;
