import styled from "styled-components";

import { StoreNotificationStyledProps as Props } from "./StoreNotification.types";

const StoreNotificationStyled = styled.div<Props>`
  .StoreNotification {
  }
`;

export default StoreNotificationStyled;
