// Form utility functions
import { DocumentType } from "artisn/types";
import * as yup from "yup";

import { sendOtpFormValues } from "../components/Otp/LoginOtp/LoginOtp.types";
import { SignInFormValues } from "../components/signIn/SignIn/SignInForm/SignInForm.types";
import { RecoverFormValues } from "components/ProfileMembership/ResetPassword/RecoverPasswordSection/RecoverPasswordSection.types";
import { ResetPasswordFormValues } from "components/ProfileMembership/ResetPassword/ResetPasswordForm/ResetPasswordForm.types";
import { SignUpFormValues } from "components/ProfileMembership/SignUpModal/SignUpForm/SignUpForm.types";
import { CardFormValues } from "components/checkout/CardForm/CardForm.types";
import { CheckoutFormValues } from "components/checkout/Checkout/Checkout.types";
import { CodeFormValues } from "components/corporateTickets/CodeRedemInput/CodeRedemInput.types";
import { ContactFormValues } from "components/global/ContactForm/ContactForm.types";
import { allowedSymbols } from "components/global/PasswordStrength/PasswordStrength.helpers";
import { BillingDataFormValues } from "components/profileBilling/BillingDataForm/BillingDataForm.types";
import { UserFormValues } from "components/profileUser/UserForm/UserForm.types";
import { ConfirmDeleteAccountFormValues } from "components/profileUser/unsubscribe/Confirm/Confirm.types";
import { MagicLinkFormValues } from "components/signIn/MagicLinkForm/MagicLinkForm.types";
import { SignUpUserFormValues } from "components/signUpUser/SignUpUser/SignUpUserForm/SignUpUserForm.types";
import { FormShape } from "types/common.types";

export const documentTypes = ["Cédula", "RUC", "Pasaporte"];
const [ci, ruc, passport] = documentTypes;

export const validationMessages = {
  required: "Campo requerido",
  invalidEmail: "Correo electrónico no válido",
  minLength: (min: number) => `El campo debe tener ${min} caracteres mínimo`,
  maxLength: (max: number) => `El campo debe tener ${max} caracteres máximo`,
  onlyNumbers: "El campo debe contener solo números",
  phoneNumber: "El campo debe comenzar con 0",
  matchFields: "Los campos no coinciden",
  length: (len: number) => `El campo debe tener ${len} caracteres`
};

const containSpecialCharacters = (value?: string) => {
  if (!value) return false;
  return /[\]#^[<>;,:.'_@$!%*&"¡¿?°/()=|+~{}-]/.test(value);
};

const { invalidEmail, required, maxLength, minLength } = validationMessages;
const { onlyNumbers, phoneNumber, matchFields, length } = validationMessages;

export const validationRules = {
  requiredDocumentType: yup.string().required(required),
  email: yup.string().email(invalidEmail).trim(),
  requiredString: yup
    .string()
    .required(required)
    .min(3, minLength(3))
    .max(50, maxLength(50))
    .trim(),
  requiredStringMild: yup
    .string()
    .required(required)
    .min(1, minLength(1))
    .max(50, maxLength(50))
    .trim(),
  requiredNumber: yup
    .number()
    .typeError(onlyNumbers)
    .required(required)
    .min(3, minLength(3))
    .max(50, maxLength(50)),
  requiredBoolean: yup.bool().oneOf([true], required),
  requiredEmail: yup
    .string()
    .required(required)
    .email(invalidEmail)
    .min(3, minLength(3))
    .max(50, maxLength(50))
    .trim(),
  requiredPhoneNumber: yup
    .string()
    .required(required)
    .matches(/^0/, phoneNumber)
    .matches(/^[0-9]+$/, onlyNumbers)
    .min(10, minLength(10))
    .max(10, maxLength(10)),
  customPhoneNumber: (min: number, max: number) =>
    yup
      .string()
      .required(required)
      .matches(/^[0-9]+$/, onlyNumbers)
      .min(min, minLength(min))
      .max(max, maxLength(max)),
  minLength: (min: number) =>
    yup.string().required(required).min(min, minLength(min)),
  maxLength: (max: number) =>
    yup.string().required(required).max(max, maxLength(max)),
  customName: (min: number, max: number) =>
    yup
      .string()
      .required(required)
      .min(min, minLength(min))
      .max(max, maxLength(max))
      .matches(
        /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/u,
        "El campo debe tener solo letras"
      )
      .trim(),
  comments: (min: number, max: number) =>
    yup
      .string()
      .required(required)
      .min(min, minLength(min))
      .max(max, maxLength(max))
      .trim(),
  password: yup
    .string()
    .test("len", minLength(8), value => (value?.toString().length ?? 0) >= 8)
    .matches(/[A-Z]/, "Debe contener al menos una letra mayúscula")
    .matches(/[0-9]/, "Debe contener al menos un número")
    .matches(
      new RegExp(allowedSymbols),
      "Debe contener al menos un caracter especial"
    )
    .trim(),
  matchEmailValidator: (emailCompare: string) => {
    return yup
      .string()
      .required(required)
      .email(invalidEmail)
      .oneOf([emailCompare]);
  },
  twoOrMoreWords: (message?: string) =>
    yup
      .string()
      .required(required)
      .matches(/^[^\d]+$/, "El campo no debe contener números")
      .matches(
        /^[a-zA-Z\u00C0-\u1FFF]+\s+([a-zA-Z\u00C0-\u1FFF]+\s?)+/,
        message ?? "El campo debe contener mínimo dos palabras"
      ),
  checkBlankSpace: yup
    .string()
    .required(required)
    .min(6, "Debe contener al menos 6 caracteres")
    .max(50, maxLength(50))
    .matches(
      /^[^\s].+[^\s]$/,
      "El campo no puede contener espacios en blanco al inicio o al final"
    ),
  documentRules: (documentType: DocumentType | string = ci!) => {
    switch (documentType) {
      case "CI" || "Cédula" || ci:
        return yup
          .string()
          .required(required)
          .matches(/^\d+$/, { message: onlyNumbers })
          .typeError(onlyNumbers)
          .test(
            "len",
            "El campo debe tener 10 caracteres",
            value => value?.toString().length === 10
          )
          .nullable()
          .trim();
      case "RUC" || ruc:
        return yup
          .string()
          .matches(/^\d+$/, { message: onlyNumbers })
          .typeError(onlyNumbers)
          .required(required)
          .test(
            "len",
            "El campo debe tener 13 caracteres",
            value => value?.toString().length === 13
          )
          .trim();
      case "PASSPORT" || "Pasaporte" || passport:
        return yup
          .string()
          .required(required)
          .test(
            "special characters",
            "No se permiten caracteres especiales",
            value => !containSpecialCharacters(value)
          )
          .max(20, maxLength(20))
          .trim();
      default:
        break;
    }
  }
};

export const getMaxDocumentLength = (documentType: string) => {
  switch (documentType) {
    case "CI" || ci:
      return 10;
    case ruc:
      return 13;
    default:
      return 20;
  }
};

export const trimFields = <T>(obj: T): T => {
  if (!obj) return obj;
  return JSON.parse(JSON.stringify(obj).replace(/"\s+|\s+"/g, '"'));
};

const { requiredEmail, requiredString, requiredPhoneNumber } = validationRules;
const { documentRules, customName, requiredDocumentType } = validationRules;
const { requiredStringMild, password, comments } = validationRules;
const { requiredBoolean } = validationRules;

export const getSignUpFormSchema = (documentType: string | undefined = ci!) => {
  return yup.object().shape<FormShape<SignUpFormValues>>({
    email: requiredEmail,
    documentType: requiredStringMild,
    document: documentRules(documentType),
    dateOfBirth: requiredString,
    terms: yup.bool().oneOf([true], required).required(required)
  });
};

export const getEmailAndPasswordFormSchema = () => {
  return yup.object().shape<FormShape<SignInFormValues>>({
    email: requiredEmail,
    password: requiredString
  });
};

export const getMagicLinkFormSchema = () => {
  return yup.object().shape<FormShape<MagicLinkFormValues>>({
    email: requiredEmail
  });
};

export const getRecoverFormSchema = () => {
  return yup.object().shape<FormShape<RecoverFormValues>>({
    email: requiredEmail
  });
};

export const getBillingFormSchema = (documentType: string = ci!) => {
  return yup.object().shape<FormShape<BillingDataFormValues>>({
    name: documentType === ruc ? requiredString : customName(3, 50),
    lastname: documentType === ruc ? undefined : customName(3, 50),
    documentType: requiredDocumentType,
    document: documentRules(documentType),
    email: requiredEmail,
    phone: requiredPhoneNumber,
    address: requiredString
  });
};

export const getResetPasswordFormSchema = () => {
  return yup.object().shape<FormShape<ResetPasswordFormValues>>({
    password,
    passwordConfirmation: password
      .concat(requiredString)
      .oneOf([yup.ref("password")], matchFields)
  });
};

export const getContactFormSchema = (documentType: string = ci!) => {
  return yup.object().shape<FormShape<ContactFormValues>>({
    names: customName(3, 50),
    lastNames: customName(3, 50),
    phone: requiredPhoneNumber,
    date: requiredString,
    document: documentRules(documentType),
    movie: customName(3, 50),
    comments: comments(3, 500),
    store: customName(3, 50)
  });
};

export const getEditProfileFormSchema = (documentType: string = ci!) => {
  return yup.object().shape<FormShape<UserFormValues>>({
    firstName: customName(3, 50),
    lastName: customName(3, 50),
    phone: requiredPhoneNumber,
    email: requiredEmail,
    documentType: requiredDocumentType,
    document: documentRules(documentType),
    dateOfBirth: requiredString
  });
};

export const getCardFormSchema = (documentType: DocumentType | undefined) => {
  return yup.object().shape<FormShape<CardFormValues>>({
    documentType: requiredDocumentType,
    document: documentRules(documentType),
    name: customName(3, 50),
    lastName: customName(3, 50),
    email: requiredEmail
  });
};

export const getUserFormSchema = (documentType: DocumentType | undefined) => {
  return yup.object().shape<FormShape<UserFormValues>>({
    firstName: customName(3, 50),
    lastName: customName(3, 50),
    phone: requiredPhoneNumber,
    dateOfBirth: requiredString,
    documentType: requiredDocumentType,
    document: documentRules(documentType),
    email: requiredEmail
  });
};

export const getCheckoutUserFormSchema = (documentType: string = ci!) =>
  yup.object().shape<FormShape<CheckoutFormValues>>({
    documentType: requiredDocumentType,
    name: documentType === ruc ? requiredString : customName(3, 50),
    lastname: documentType === ruc ? undefined : requiredString,
    document: documentRules(documentType),
    email: requiredEmail,
    phone: requiredPhoneNumber
  });

export const confirmDeleteAccountSchema = (emailUser: string) => {
  return yup.object().shape<FormShape<ConfirmDeleteAccountFormValues>>({
    email: validationRules.matchEmailValidator(emailUser)
  });
};

export const getCorporateFormSchema = (len: number) => {
  return yup.object().shape<FormShape<CodeFormValues>>({
    code: requiredString.length(len, length(len))
  });
};

export const signUpUserSchema = (
  isMulticlub: boolean,
  documentType: string
) => {
  return yup.object().shape<FormShape<SignUpUserFormValues>>({
    name: customName(3, 50),
    lastname: customName(3, 50),
    phone: requiredPhoneNumber,
    terms: requiredBoolean,
    document: isMulticlub ? documentRules(documentType) : undefined,
    dateOfBirth: isMulticlub ? requiredString : undefined,
    acceptMulticlubTerms: isMulticlub ? requiredBoolean : undefined
  });
};

export const getSendOtpEmailSchema = () => {
  return yup.object().shape<FormShape<sendOtpFormValues>>({
    email: requiredEmail
  });
};
