import { lighten, darken } from "polished";

const primary = "hsla(41, 97%, 54%, 1)";

const palette = {
  empty: "", // Fixes a bug with styled components that appends a coma
  "primary-dark": darken(0.12, primary),
  "primary-light": lighten(0.47, primary),
  // This variable is for capacitor use only, it represents the primary color.
  "primary-hex": "#4811d4",
  "purple-s85-l45": primary,
  "purple-s72-l92": "hsla(257, 72%, 92%, 1)",
  primary: "var(--palette-purple-s85-l45)",
  "green-s100-l40": "hsla(88, 100%, 40%, 1)",
  "green-s63-l42": "hsla(145, 63%, 42%, 1)",
  "red-s61-l87": "hsla(0, 61%, 87%, 1)",
  "red-s65-l60": "hsla(0, 65%, 60%, 1)",
  "red-s95-l65": "hsla(360, 95%, 65%, 1)",
  "blue-s89-l52": "hsla(214, 89%, 52%, 1)",
  "blue-s100-l22": "hsla(184, 100%, 22%, 1)",
  "blue-s100-l45": "hsla(185, 100%, 45%, 1)",
  "pink-s75-l65": "hsla(335, 75%, 65%, 1)",
  "yellow-s97-l54": "hsla(40, 97%, 54%, 1)",
  "dark-blue-s13-l11": "hsla(223, 13%, 11%, 1)",
  "mid-blue-s5-l80": "hsla(225, 5%, 80%, 1)",
  "mid-blue-s10-l23": "hsla(227, 10%, 23%, 0.4)",
  "mid-blue-s13-l18": "hsla(225, 13%, 18%, 1)",
  "mid-blue-s13-l18-a06": "hsla(225, 13%, 18%, 0.6)",
  "mid-blue-s15-l8": "hsla(225, 15%, 8%, 1)",
  "mid-blue-s15-l10": "hsla(225, 15%, 10%, 1)",
  "mid-blue-s15-l20": "hsla(225, 15%, 20%, 1)",
  "light-blue-s10-l60": "hsla(230, 10%, 60%, 1)",
  "light-blue-s9-l61": "hsla(230, 9%, 61%, 1)",
  "yellow-s100-l55": "hsla(50, 100%, 54%, 1)",
  "brown-s0-l0": "hsla(30, 29%, 56%, 1)",
  // This variable is for capacitor use only, it represents the black color.
  "black-hex": "#000000",
  black: "hsla(0, 0%, 0%, 1)",
  "black-s0-l10": "hsla(0, 0%, 10%, 1)",
  "black-s15-l8": "hsla(220, 15%, 7%, 1)",
  "gray-s0-l20-a04": "hsla(0, 0%, 20%, 0.04)",
  "gray-s0-l20-a08": "hsla(0, 0%, 20%, 0.08)",
  "gray-s0-l20-a16": "hsla(0, 0%, 20%, 0.16)",
  "gray-s0-l20-a20": "hsla(0, 0%, 20%, 0.20)",
  "gray-s0-l20-a70": "hsla(0, 0%, 20%, 0.70)",
  "gray-s0-l20": "hsla(0, 0%, 20%, 1)",
  "gray-s0-l30": "hsla(0, 0%, 30%, 1)",
  "gray-s0-l30-a40": "hsla(0, 0%, 30%, 0.4)",
  "gray-s0-l35": "hsla(0, 0%, 35%, 1)",
  "gray-s0-l40-a40": "hsla(0, 0%, 40%, 0.4)",
  "gray-s0-l40": "hsla(0, 0%, 40%, 1)",
  "gray-s0-l70-a48": "hsla(0, 0%, 70%, 0.48)",
  "gray-s0-l70": "hsla(0, 0%, 70%, 1)",
  "gray-s0-l80": "hsla(0, 0%, 80%, 1)",
  "gray-s0-l85": "hsla(0, 0%, 85%, 1)",
  "gray-s0-l90": "hsla(0, 0%, 90%, 1)",
  "gray-s0-l92": "hsla(0, 0%, 92%, 1)",
  "gray-s0-l95": "hsla(0, 0%, 95%, 1)",
  "gray-s0-l98": "hsla(0, 0%, 98%, 1)",
  // This variable is for capacitor use only, it represents the white color.
  "white-hex": "#ffffff",
  white: "hsla(0, 0%, 100%, 1)",
  text: {
    primary: "var(--palette-primary)",
    white: "var(--palette-white)"
  }
};

const fonts = {
  primary: "'Montserrat', sans-serif"
};

const sizes = {
  page: {
    minWidth:
      "calc(100vw - (100vw - 100%) - env(safe-area-inset-left) - env(safe-area-inset-right))",
    maxWidth:
      "calc(100vw - (100vw - 100%) - env(safe-area-inset-left) - env(safe-area-inset-right))",
    minHeight:
      "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
    maxHeight:
      "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
    columns:
      "[fullBleed-start] minmax(1.6rem, 1fr) [content-start] minmax(0, 160rem) [content-end] minmax(1.6rem, 1fr) [fullBleed-end]"
  },
  navbar: {
    height: "calc(7.6rem + env(safe-area-inset-top))",
    "height-mobile": "calc(10.8rem + env(safe-area-inset-top))",
    "height-mobile-without-search": "calc(5.8rem + env(safe-area-inset-top))"
  }
};

const variables = { palette, fonts, sizes };

export default variables;
