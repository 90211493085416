import { useShoppingCartStore } from "@multicines/stores";
import { Image } from "artisn-ui-react";
import React from "react";

import { AddToCartNotificationStyled } from "./AddToCartNotification.styles";
import { AddToCartNotificationModifiable } from "./AddToCartNotification.styles";
import { AddToCartNotificationProps as Props } from "./AddToCartNotification.types";
import { defaultImageConfig } from "../AddToWishlistNotification/AddToWishlistNotification.helpers";
import CartPayButton from "components/Cart/CartPayButton/CartPayButton";
import ShoppingCartProduct from "components/Cart/ShoppingCartProduct/ShoppingCartProduct";
import Button from "components/global/Button/Button";
import useShoppingCart from "hooks/useShoppingCart/useShoppingCart";
import { useStoresStore } from "stores/stores/stores.store";
import { defaultFunction } from "utils/common.utils";
import { dismissAddToCartNotification } from "utils/notifications.utils";

const AddToCartNotification: React.FC<Props> = props => {
  const { className, product, showDrawer = defaultFunction } = props;
  const { amount = 1, modifiable } = props;
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);
  const { setProduct } = useShoppingCart();
  const selectedStore = useStoresStore(store => store.selectedStore);

  const [, snackStore] = selectedStore ?? [];

  const { images, name } = product ?? {};
  const { name: shoppingCartName } = shoppingCart ?? {};

  if (modifiable) {
    return (
      <AddToCartNotificationModifiable
        className={`AddToCartNotification ${className}`}
        showCloseIcon={true}
        onClose={dismissAddToCartNotification}
      >
        <p className="AddToCartNotification__description">Haz agregado</p>
        {product ? (
          <ShoppingCartProduct
            product={product}
            onChangeQuantity={async (value: number) => {
              if (!snackStore) {
                console.error(
                  "AddToCartNotification: snacksStore is undefined"
                );
                return;
              }

              await setProduct?.(product, {
                amount: value,
                shoppingCartName,
                store: snackStore
              });
            }}
            initialQuantity={amount}
          />
        ) : null}
        <div className="AddToCartNotification__buttons">
          <Button
            className="AddToCartNotification__button"
            onClick={showDrawer}
          >
            Ver carrito
          </Button>
          <CartPayButton shoppingCart={shoppingCart} />
        </div>
      </AddToCartNotificationModifiable>
    );
  }

  return (
    <AddToCartNotificationStyled
      className={`AddToCartNotification ${className}`}
      showCloseIcon={false}
    >
      {images?.[0] ? (
        <Image
          image={images?.[0].url}
          config={defaultImageConfig}
          alt="Action icon"
          className="AddToCartNotification__image"
        />
      ) : null}
      <p className="AddToCartNotification__description">Haz agregado: {name}</p>
      <Button
        className="AddToCartNotification__button"
        mode="LINK"
        onClick={showDrawer}
      >
        Ver carrito
      </Button>
    </AddToCartNotificationStyled>
  );
};

AddToCartNotification.defaultProps = {};

export default AddToCartNotification;
