import { useGeoStore } from "@multicines/stores";
import { useCataloguesStore } from "@multicines/stores";
import { Notification } from "artisn-ui-react";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";

import Styles from "./StoreNotification.styles";
import { StoreNotificationProps as Props } from "./StoreNotification.types";
import ShippingAddressModal from "components/checkout/ShippingAddressModal/ShippingAddressModal";
import { useStoresStore } from "stores/stores/stores.store";
import { dismissClosedStoreNotification } from "utils/notifications.utils";
import { dismissBenefitsNotification } from "utils/notifications.utils";
import { dismissNoCoverageNotification } from "utils/notifications.utils";
import { createClosedStoreNotification } from "utils/notifications.utils";
import { createNoCoverageNotification } from "utils/notifications.utils";

const StoreNotification: React.FC<Props> = props => {
  const selectedStore = useStoresStore(store => store.selectedStore);
  const noCoverage = useStoresStore(store => store.noCoverage);
  const setShowStoreNotification = useStoresStore(
    store => store.setShowStoreNotification
  );
  const [ticketsStore] = selectedStore ?? [];
  const { isOpen } = ticketsStore ?? {};
  const isOpenLoading = typeof isOpen === "undefined";
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const selectedCatalogue = useCataloguesStore(
    state => state.selectedCatalogue
  );
  const { name } = selectedCatalogue ?? {};
  const selectedCatalogueName = name.toUpperCase();
  const { pathname } = useRouter();
  const pages = ["products", "categories"];
  const allowedToShow = pages.some(page => pathname.includes(page));
  const showStoreNotification =
    !isOpenLoading && !isOpen && !noCoverage && allowedToShow;
  const setSelectedCoordinates = useGeoStore(
    state => state.setSelectedCoordinates
  );

  const showStoreModal = useCallback(() => {
    if (selectedCatalogueName === "DELIVERY") {
      setOpenAddressModal(true);
    }
    setShowStoreNotification(true);
  }, [selectedCatalogueName, setShowStoreNotification]);

  // const showAddressModal = useCallback(() => {
  //   setOpenAddressModal(true);
  //   setShowStoreNotification(true);
  // }, [setShowStoreNotification]);

  const handleDefaultCoordinates = useCallback(() => {
    // TODO: Quito coordinates
    setSelectedCoordinates({
      lat: -0.1879668617094344,
      lng: -78.48067809354744
    });
    dismissNoCoverageNotification();
  }, [setSelectedCoordinates]);

  const closeNoCoverage = () => {
    dismissNoCoverageNotification();
  };

  const closeStoreNotification = () => {
    dismissClosedStoreNotification();
  };

  useEffect(() => {
    if (noCoverage) {
      dismissBenefitsNotification();
      createNoCoverageNotification(
        handleDefaultCoordinates,
        openAddressModal,
        closeNoCoverage
      );
    } else {
      dismissNoCoverageNotification();
    }
    return () => Notification.destroy();
  }, [noCoverage, openAddressModal, allowedToShow, handleDefaultCoordinates]);

  useEffect(() => {
    if (showStoreNotification) {
      createClosedStoreNotification(
        selectedCatalogueName,
        showStoreModal,
        openAddressModal,
        closeStoreNotification
      );
    } else {
      dismissClosedStoreNotification();
    }
    return () => Notification.destroy();
  }, [
    showStoreNotification,
    showStoreModal,
    openAddressModal,
    selectedCatalogueName
  ]);

  return (
    <Styles className="StoreNotification">
      <ShippingAddressModal
        opened={openAddressModal}
        onClose={() => setOpenAddressModal(false)}
      />
    </Styles>
  );
};

StoreNotification.defaultProps = {};

export default StoreNotification;
