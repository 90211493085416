import styled from "styled-components";

import { SelectCinemaPlaceholderStyledProps as Props } from "./SelectCinemaPlaceholder.types";

const SelectCinemaPlaceholderStyled = styled.div<Props>`
  display: flex;
  flex: 1;
  flex-direction: column;

  .SelectCinemaPlaceholder {
    &__store {
      width: 50%;
      margin-left: 0.8rem;
      margin-bottom: 0.8rem;
      background-color: var(--palette-dark-blue-s13-l11);
      height: 2.4rem;
      border-radius: 0.8rem;
    }

    &__distance {
      width: 80%;
      margin-left: 0.8rem;
      margin-bottom: 2.4rem;
      background-color: var(--palette-dark-blue-s13-l11);
      height: 2.4rem;
      border-radius: 0.8rem;
    }
  }
`;

export default SelectCinemaPlaceholderStyled;
