import { useState, useCallback, useEffect } from "react";
import { useMemo, useLayoutEffect, useRef } from "react";

import { UseCounterConfig } from "./useCounter.types";

const defaultFunction = () => {};

const useCounter = (config: UseCounterConfig) => {
  const { onChange = defaultFunction, initialValue = 0, min = 0 } = config;
  const { onAdd = defaultFunction, onSubtract = defaultFunction } = config;
  const { max = 100, value } = config;
  const [quantity, setQuantity] = useState(initialValue);
  const prevQuantity = useRef(initialValue);
  const latestQuantity = useRef(initialValue);

  const add = useCallback(() => {
    if (quantity < max) {
      setQuantity(prev => {
        const newQuantity = prev + 1;
        prevQuantity.current = prev;
        onAdd(newQuantity);
        return newQuantity;
      });
    }
  }, [quantity, max, onAdd]);

  const subtract = useCallback(() => {
    if (quantity > min) {
      setQuantity(prev => {
        const newQuantity = prev - 1;
        prevQuantity.current = prev;
        onSubtract(newQuantity);
        return newQuantity;
      });
    }
  }, [quantity, min, onSubtract]);

  useLayoutEffect(() => {
    if (latestQuantity.current === quantity) return;
    if (quantity === prevQuantity.current) return;
    onChange(quantity, prevQuantity.current);
    latestQuantity.current = quantity;
  }, [quantity, onChange]);

  useEffect(() => {
    if (!value) return;
    setQuantity(value);
  }, [value]);

  return useMemo(
    () => ({
      add,
      subtract,
      quantity
    }),
    [add, subtract, quantity]
  );
};

export default useCounter;
