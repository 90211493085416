import styled from "styled-components";

import { ErrorStateStyledProps as Props } from "./ErrorState.types";

const ErrorStateStyled = styled.div<Props>`
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  flex: 1;

  .ErrorState {
    &__title {
      color: var(--palette-light-blue-s10-l60);
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 2.4rem;
      padding-bottom: 0.4rem;
      text-align: center;
    }

    &__message {
      font-size: 2.4rem;
      padding-bottom: 1.6rem;
      font-weight: 600;
      color: var(--palette-white);
      line-height: 2.4rem;
      width: 24rem;
      text-align: center;
    }
  }
`;

export default ErrorStateStyled;
