import styled from "styled-components";

import { SuccessNotificationStyledProps as Props } from "./SuccessNotification.types";
import Notification from "components/global/notifications/Notification/Notification";

const SuccessNotificationStyled = styled(Notification)<Props>`
  display: flex;
  align-items: center;
  border-top: 0.4rem solid var(--palette-mid-blue-s15-l10);

  &:first-child:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.4rem;
    background: linear-gradient(
      270deg,
      var(--palette-purple-s85-l45) -16%,
      var(--palette-pink-s75-l65) 48%,
      var(--palette-blue-s100-l45) 112%
    );
    top: 0;
    left: 0;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
  }

  .SuccessNotification {
    &__title {
      color: var(--palette-white);
      font-weight: 600;
      line-height: 2rem;
      font-size: 1.6rem;
      padding: 0.4rem 0;
    }

    &__message {
      font-size: 1.4rem;
      color: var(--palette-gray-s0-l90);
      line-height: 2rem;
    }

    &__icon {
      min-width: 4.8rem;
    }

    &__row {
      padding-left: 1.6rem;
    }
  }
`;

export default SuccessNotificationStyled;
