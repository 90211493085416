import React from "react";

import Styles from "./EmptyState.styles";
import { EmptyStateProps as Props } from "./EmptyState.types";
import Button from "../Button/Button";

const EmptyState: React.FC<Props> = props => {
  const { className, title, icon, description, buttonTitle, onClick } = props;

  return (
    <Styles className={`EmptyState ${className}`}>
      {icon ? icon : null}
      {title ? <p className="EmptyState__title">{title}</p> : null}
      {description ? (
        <p className="EmptyState__message">{description}</p>
      ) : null}
      {buttonTitle && onClick ? (
        <Button mode="PRIMARY" className="EmptyState__button" onClick={onClick}>
          {buttonTitle}
        </Button>
      ) : null}
    </Styles>
  );
};

EmptyState.defaultProps = {
  className: ""
};

export default EmptyState;
