import styled from "styled-components";

import { HighTrafficModalStyledProps as Props } from "./HighTrafficModal.types";

const HighTrafficModalStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50rem;
  padding: 2.4rem;
  border-radius: 4rem;
  background-color: var(--palette-mid-blue-s15-l10);
  margin: 0 auto;

  .HighTrafficModal {
    &__title {
      font-size: 2.8rem;
      font-weight: 600;
      text-align: center;
      color: var(--palette-white);
      margin-bottom: 3.2rem;
      padding: 0 3.2rem;
    }

    &__description {
      font-size: 1.6rem;
      font-weight: 400;
      text-align: center;
      color: var(--palette-white);
      margin-bottom: 3.2rem;
      padding: 0 2.4rem;
    }

    &__button {
      font-size: 1.6rem;
      width: 24rem;
      height: 5.6rem;
      font-weight: 600;
      border-radius: 1.2rem;
      color: var(--palette-black);
    }

    &__modal {
      border-radius: 2rem;
    }

    &__svg {
      width: 14.8rem;
      height: 14.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2.4rem;
    }
  }
`;

export default HighTrafficModalStyled;
