import { useVendorsStore } from "@multicines/stores";
import { events } from "artisn/analytics";
import { useEffect, useRef } from "react";

import CONSTANTS from "config/constants";
import { useAnalyticsStore } from "stores/analytics/analytics.store";

const { DEFAULT_VENDOR } = CONSTANTS.ARTISN;
const { logSetVendor, logChangeVendor } = events.vendor;

const useListenVendor = () => {
  const analyticsInitialized = useAnalyticsStore(
    state => state.analyticsInitialized
  );
  const selectedVendorId = useVendorsStore(store => store.selectedVendorId);
  const prevVendorId = useRef(selectedVendorId);

  useEffect(() => {
    if (!analyticsInitialized) return;

    logSetVendor({
      vendorId: DEFAULT_VENDOR.id
    });
  }, [analyticsInitialized]);

  useEffect(() => {
    if (!analyticsInitialized || prevVendorId.current === selectedVendorId) {
      return;
    }
    prevVendorId.current = selectedVendorId;

    logChangeVendor({
      previousVendorId: prevVendorId.current,
      nextVendorId: selectedVendorId
    });
  }, [analyticsInitialized, selectedVendorId]);
};

export default useListenVendor;
