import React from "react";

import Styles from "./Modal.styles";
import { ModalProps as Props } from "./Modal.types";

const Modal: React.FC<Props> = props => {
  return <Styles {...props} />;
};

Modal.defaultProps = {};

export default Modal;
