import { getProductTotals } from "artisn/products";
import { Clickable, Image } from "artisn-ui-react";
import React, { useMemo, useState } from "react";

import Styles from "./ShoppingCartProduct.styles";
import { ShoppingCartProductProps as Props } from "./ShoppingCartProduct.types";
import Close from "components/global/Close/Close";
import Counter from "components/global/Counter/Counter";
import useI18n from "hooks/useI18n";
import useUtils from "hooks/useUtils";

const ShoppingCartProduct: React.FC<Props> = props => {
  const {
    product,
    initialQuantity = 0,
    onChangeQuantity,
    onDelete,
    disabled,
    onClick,
    minCounter = 1,
    onAdd,
    onSubtract,
    ...rest
  } = props;
  const { showDetails } = props;
  const { name, description } = product;
  const [count, setCount] = useState(minCounter);
  const { images } = product ?? {};
  const [image] = images ?? [];
  const { url } = image ?? {};
  const totals = useMemo(() => {
    if (!product) return;
    return getProductTotals(product);
  }, [product]);
  const { netPrice } = totals ?? {};
  const t = useI18n();
  const { formatByCurrency } = useUtils();

  const onChange = (value: number) => {
    setCount(value);
    onChangeQuantity(value);
  };

  const editHandler = () => {
    onClick?.();
  };

  return (
    <Styles className="ShoppingCartProduct" {...rest} count={count}>
      <div className="ShoppingCartProduct__container">
        {url ? (
          <Image
            alt="image"
            className="ShoppingCartProduct__image"
            image={url}
          />
        ) : (
          <div className="ShoppingCartProduct__image" />
        )}
      </div>
      <div className="ShoppingCartProduct__product">
        <div className="ShoppingCartProduct__counter">
          <p className="ShoppingCartProduct__title">
            {name} {showDetails ? ` (${initialQuantity})` : null}
          </p>
          <p className="ShoppingCartProduct__description">{description}</p>
          {!showDetails ? (
            <Counter
              className="ShoppingCartProduct__counter"
              initialValue={initialQuantity}
              onChange={onChange}
              onAdd={onAdd}
              onSubtract={onSubtract}
              disabled={disabled}
              min={minCounter}
              value={initialQuantity}
            />
          ) : null}
        </div>
        {!showDetails ? (
          <div className="ShoppingCartProduct__price-container">
            <div className="ShoppingCartProduct__icon">
              <p className="ShoppingCartProduct__price">
                {formatByCurrency(netPrice)}
              </p>
              <Close
                className="ShoppingCartProduct__close"
                onClick={onDelete}
              />
            </div>
            <Clickable
              className="ShoppingCartProduct__edit"
              onClick={editHandler}
            >
              <p className="ShoppingCartProduct__edit-text">{t.common.edit}</p>
            </Clickable>
          </div>
        ) : null}
      </div>
    </Styles>
  );
};

ShoppingCartProduct.defaultProps = {};

export default ShoppingCartProduct;
