// useUtils custom hook
import { useCountriesStore } from "@multicines/stores";
import { formatByCurrency as formatByCurrencyUtil } from "@multicines/utils";
import { useCallback, useMemo } from "react";

const useUtils = () => {
  const selectedCountry = useCountriesStore(state => state.selectedCountry);

  const formatByCurrency = useCallback(
    (value: number | null | undefined) => {
      const { currencySymbol } = selectedCountry ?? {};

      return formatByCurrencyUtil(value ?? 0, {
        ...selectedCountry,
        symbol: currencySymbol.concat(" "),
        locale: "en-US"
      });
    },
    [selectedCountry]
  );

  return useMemo(() => ({ formatByCurrency }), [formatByCurrency]);
};

export default useUtils;
