import { Storage } from "@capacitor/storage";
import { useFetchBillingData } from "@multicines/services";
import { useBillingDataStore } from "@multicines/stores";
import { AdditionalInfo, BillingData } from "artisn/types";
import { useEffect } from "react";

import CONSTANTS from "config/constants";
import { useAuthStore } from "stores/auth/auth.store";

const { BILLING_DATA_TOKEN } = CONSTANTS.STORAGE;

const useListenBillingData = () => {
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const billingQuery = useFetchBillingData({ isAnonymous, uid });
  const selectedBillingData = useBillingDataStore(
    state => state.selectedBillingData
  );
  const setSelectedBillingData = useBillingDataStore(
    state => state.setSelectedBillingData
  );

  const { data: billingDataList, isSuccess, isError } = billingQuery;

  const setBillingDataFromStorage = async (
    billingDataList: BillingData<AdditionalInfo>[]
  ) => {
    const { value } = await Storage.get({
      key: BILLING_DATA_TOKEN
    });
    const lastSelectedBillingData = value ? JSON.parse(value) : undefined;
    const matchingBillingData = billingDataList.find(
      billingData => billingData.id === lastSelectedBillingData?.id
    );

    if (matchingBillingData) {
      setSelectedBillingData(matchingBillingData);
      return;
    }

    setSelectedBillingData(undefined);
  };

  /** Get billing data from local storage and set it on context. */
  useEffect(() => {
    if (!isSuccess) return;

    if (billingDataList.length === 0) {
      setSelectedBillingData(undefined);
      return;
    }

    const defaultBillingData = billingDataList.find(
      billingData => billingData.default
    );

    if (defaultBillingData) {
      setSelectedBillingData(defaultBillingData);
      return;
    }

    setBillingDataFromStorage(billingDataList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  /**
   * Every time the selected billing data changes,
   * save it on local storage.
   */
  useEffect(() => {
    if (!selectedBillingData || isAnonymous) return;
    Storage.set({
      key: BILLING_DATA_TOKEN,
      value: JSON.stringify(selectedBillingData)
    });
  }, [isAnonymous, selectedBillingData]);

  //INFO: Remove the key from local storage if an error occurred with the given value
  useEffect(() => {
    if (!isError) return;

    Storage.remove({ key: BILLING_DATA_TOKEN });
    setSelectedBillingData(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
};

export default useListenBillingData;
