import styled from "styled-components";

import { ListItemStyledProps as Props } from "./ListItem.types";

const ListItemStyled = styled.div<Props>`
  display: grid;
  grid-template-columns: ${props =>
    props.icon ? `2rem auto 3.6rem` : ` auto 3.6rem`};
  align-items: center;
  column-gap: 1.6rem;
  cursor: pointer;
  flex: 1;

  .ListItem {
    &__title {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.4rem;
      color: var(--palette-black-s0-l10);
      color: ${props =>
        props.isActive
          ? "var(--palette-white)"
          : "var(--palette-light-blue-s10-l60)"};
    }

    &__description {
      font-size: 1.6rem;
      color: ${props =>
        props.isActive
          ? "var(--palette-gray-s0-l80)"
          : "var(--palette-light-blue-s10-l60)"};
    }

    &__default {
      font-size: 1.2rem;
    }
  }

  path {
    fill: var(--palette-white);
  }
`;

export default ListItemStyled;
