import SuccessCardSVG from "images/add-card.svg";
import SuccessSVG from "images/success-notification.svg";
import React from "react";

import Styles from "./SuccessNotification.styles";
import { SuccessNotificationProps as Props } from "./SuccessNotification.types";

const SuccessNotification: React.FC<Props> = props => {
  const { className, message, title, isSubscription } = props;

  return (
    <Styles
      className={`SuccessNotification ${className}`}
      showCloseIcon={false}
    >
      {isSubscription ? (
        <SuccessCardSVG className="SuccessNotification__icon" />
      ) : (
        <SuccessSVG className="SuccessNotification__icon" />
      )}
      <div className="SuccessNotification__row">
        <p className="SuccessNotification__title">{title}</p>
        <p className="SuccessNotification__message">{message ?? ""}</p>
      </div>
    </Styles>
  );
};

SuccessNotification.defaultProps = {
  className: ""
};

export default SuccessNotification;
