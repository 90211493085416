import { Storage } from "@capacitor/storage";
import { useCataloguesStore, useShoppingCartStore } from "@multicines/stores";
import { mergeShoppingCart } from "artisn/shopping-cart";
import { ShoppingCart } from "artisn/types";
import CartErrorSVG from "images/cart-error.svg";
import React, { useEffect, useState } from "react";

import Styles from "./MergeCartModal.styles";
import { MergeCartModalProps as Props } from "./MergeCartModal.types";
import CONSTANTS from "config/constants";
import useShippingCost from "hooks/useShippingCost";
import useShoppingCart from "hooks/useShoppingCart/useShoppingCart";
import { useAuthStore } from "stores/auth/auth.store";

const { ARTISN, STORAGE } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = ARTISN;
const { ANONYMOUS_SHOPPING_CART_TOKEN } = STORAGE;

const MergeCartModal: React.FC<Props> = props => {
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);
  const isAnonymousCart = useShoppingCartStore(store => store.isAnonymousCart);
  const catalogueId = useCataloguesStore(
    state => state.selectedCatalogue.catalogueId
  );
  const shippingCost = useShippingCost();
  const { createShoppingCart, deleteShoppingCart } = useShoppingCart();
  const [anonymousCart, setAnonymousCart] = useState<ShoppingCart>();
  const [opened, setOpened] = useState(false);

  const { name: shoppingCartName = SHOPPING_CART_DEFAULT_NAME } =
    shoppingCart ?? {};

  const mergeAnonymousShoppingCart = async () => {
    if (!anonymousCart) return;
    await mergeShoppingCart(anonymousCart, {
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      anonymous: isAnonymous!,
      accountId: ACCOUNT_ID,
      customerId: uid!
    });
    await Storage.remove({
      key: ANONYMOUS_SHOPPING_CART_TOKEN
    });
    closeModal();
  };

  const replaceShoppingCart = async () => {
    if (!anonymousCart) return;
    await Storage.remove({
      key: ANONYMOUS_SHOPPING_CART_TOKEN
    });
    if (shoppingCartName) {
      await deleteShoppingCart?.({
        shoppingCartName
      });
    }
    await createShoppingCart?.(
      {},
      {
        stores: anonymousCart.stores,
        channelId: +catalogueId,
        shippingCost,
        name: shoppingCartName
      }
    );
    closeModal();
  };

  const closeModal = () => {
    setOpened(false);
  };

  const closeModalHandler = async () => {
    await Storage.remove({
      key: ANONYMOUS_SHOPPING_CART_TOKEN
    });
    closeModal();
  };

  useEffect(() => {
    (async () => {
      if (isAnonymous || !shoppingCart) {
        setOpened(false);
        return;
      }
      const { value: incomingCart } = await Storage.get({
        key: ANONYMOUS_SHOPPING_CART_TOKEN
      });
      if (!incomingCart) return;
      const anonymousShoppingCart = JSON.parse(incomingCart);
      setAnonymousCart(anonymousShoppingCart);
      setOpened(true);
    })();
  }, [isAnonymous, shoppingCart]);

  return (
    <Styles
      className="MergeCartModal"
      title="¿Deseas reemplazar tu carrito anterior?"
      description="Ya habías guardado productos anteriormente en tu carrito, ¿Deseas mantener los productos?"
      cancelText="Mantener ambos"
      confirmText="Crear nuevo carrito"
      confirmAction={replaceShoppingCart}
      cancelAction={mergeAnonymousShoppingCart}
      opened={opened && !isAnonymousCart}
      icon={<CartErrorSVG />}
      onClose={closeModalHandler}
    />
  );
};

MergeCartModal.defaultProps = {};

export default MergeCartModal;
