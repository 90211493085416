import { getStoreSetState } from "@multicines/stores";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { defaultValues } from "./modals.helpers";
import { ModalsStoreValues } from "./modals.store.types";

export const useModalsStore = create<ModalsStoreValues>()(
  devtools(
    (set, get) => {
      const values: ModalsStoreValues = {
        ...defaultValues,
        setPrivacyPoliticsModalVisible: payload => {
          const prev = get().privacyPoliticsModalVisible;
          const privacyPoliticsModalVisible = getStoreSetState(payload, prev);
          set({ privacyPoliticsModalVisible }, false, {
            type: "setPrivacyPoliticsModalVisible",
            payload
          });
        }
      };
      return values;
    },
    { name: "Modals store" }
  )
);
