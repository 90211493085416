import { Modal } from "artisn-ui-react";
import styled from "styled-components";

import { ModalStyledProps as Props } from "./Modal.types";
import { getMaxHeight, getMaxWidth } from "utils/styling.utils";

const ModalStyled = styled(Modal)<Props>`
  width: min(${getMaxWidth()}, 60rem);
  height: min(${getMaxHeight()}, 100rem);

  @media all and (hover: none) {
    width: ${getMaxWidth()};
    height: ${getMaxHeight()};
  }

  .Modal {
    &__header {
      background-color: var(--palette-dark-blue-s13-l11);
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
    }

    &__body {
      background-color: var(--palette-dark-blue-s13-l11);
      border-bottom-left-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
    }

    &__close-icon {
      path {
        fill: var(--palette-white);
      }
    }
  }
`;

export default ModalStyled;
