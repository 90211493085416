// Initialize Artisn
import useSetupAnalytics from "./useSetupAnalytics";
import useSetupShoppingCart from "./useSetupShoppingCart";

const useSetupArtisn = () => {
  useSetupShoppingCart();
  useSetupAnalytics();
};

export default useSetupArtisn;
