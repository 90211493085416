import React from "react";

import Styles from "./CommonPlaceholder.styles";
import { CommonPlaceholderProps as Props } from "./CommonPlaceholder.types";

const CommonPlaceholder: React.FC<Props> = props => {
  return (
    <Styles className="CommonPlaceholder">
      <div className="CommonPlaceholder__shine" />
      <div className="CommonPlaceholder__left" />
      <div className="CommonPlaceholder__right">
        <div className="CommonPlaceholder__right__top" />
        <div className="CommonPlaceholder__right__bottom" />
      </div>
    </Styles>
  );
};

CommonPlaceholder.defaultProps = {};

export default CommonPlaceholder;
