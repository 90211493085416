import { usePutCancelOrder } from "@multicines/services";
import { useDeleteTicketsReservation } from "@multicines/services";
import { useCountriesStore } from "@multicines/stores";
import { useMoviesStore, useProductsStore } from "@multicines/stores";
import { events } from "artisn/analytics";
import { deleteShoppingCart } from "artisn/shopping-cart";
import HandStopSVG from "images/hand_stop.svg";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useQueryClient } from "react-query";

import Styles from "./ChangeStoreModal.styles";
import { ChangeStoreModalProps as Props } from "./ChangeStoreModal.types";
import Button from "../Button/Button";
import usePurchaseEvents from "components/checkout/Checkout/hooks/usePurchaseEvents";
import { getAuth } from "config/artisn.config";
import { notify } from "config/bugsnag.config";
import CONSTANTS from "config/constants";
import useAnalytics from "hooks/analytics/useAnalytics";
import useI18n from "hooks/useI18n";
import useTalkShop from "hooks/useTalkShop";
import { useAuthStore } from "stores/auth/auth.store";
import { useCheckoutStore } from "stores/checkout/checkout.store";
import { useStoresStore } from "stores/stores/stores.store";

const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = CONSTANTS.ARTISN;
const { logSetStore, logChangeStore } = events.store;

const ChangeStoreModal: React.FC<Props> = props => {
  const { newStore, newCity, redirect } = props;
  const { setShowChangeModal, setShowSelectModal } = props;
  const { pathname } = useRouter();
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const { isTalkShop, navigateWithTalkShop } = useTalkShop();
  const analyticsHook = useAnalytics({
    isTalkShop
  });
  const t = useI18n();
  const queryClient = useQueryClient();
  const selectedStore = useStoresStore(store => store.selectedStore);
  const setSelectedStore = useStoresStore(store => store.setSelectedStore);
  const setSelectedCity = useCountriesStore(state => state.setSelectedCity);
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState("");
  const resetSeatsToAllocate = useMoviesStore(
    store => store.resetSeatsToAllocate
  );
  const setReservationExpiration = useMoviesStore(
    store => store.setReservationExpiration
  );
  const resetMovies = useMoviesStore(store => store.resetMovies);
  const selectedSession = useMoviesStore(store => store.selectedSession);
  const setSnackRestrictionCard = useProductsStore(
    store => store.setSnackRestrictionCard
  );
  const { mutateAsync: cancelReservation, reset: resetCancel } =
    useDeleteTicketsReservation(selectedStore);
  const { mutateAsync: putCancelOrder, reset: resetPut } = usePutCancelOrder({
    uid,
    isAnonymous
  });
  const placedOrder = useCheckoutStore(state => state.placedOrder);
  const { logPurchaseFail } = usePurchaseEvents();

  const { commonParams } = analyticsHook;

  const [currentTicketStore] = selectedStore ?? [];
  const { storeName: currentStoreName } = currentTicketStore ?? {};
  const [newTicketStore] = newStore;
  const { storeName = "" } = newTicketStore ?? {};

  const closeModal = () => {
    setShowChangeModal(false);
  };

  const exitHandler = async () => {
    if (disable) return;

    setError("");

    try {
      setDisable(true);
      const [prevTicketStore] = selectedStore ?? [];
      const [newTicketStore] = newStore ?? [];
      if (!prevTicketStore && newTicketStore) {
        logSetStore({ ...commonParams, store: newTicketStore });
      } else if (prevTicketStore && newTicketStore) {
        logChangeStore({
          ...commonParams,
          previousStore: prevTicketStore,
          nextStore: newTicketStore
        });
      }
      setSelectedStore(newStore);
      setSelectedCity(newCity);

      const deletedShoppingCart = await deleteShoppingCart({
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
        anonymous: isAnonymous!,
        accountId: ACCOUNT_ID,
        customerId: uid!
      });

      if (deletedShoppingCart && placedOrder) {
        logPurchaseFail("El proceso se ha abandonado", deletedShoppingCart);
        await putCancelOrder(placedOrder.id);
        resetPut();
      }

      if (selectedSession) {
        await cancelReservation();
        resetCancel();
      }

      // Refresh the token
      await getAuth.currentUser?.getIdToken(true);
      await queryClient.invalidateQueries([uid, "loyalty"]);
      resetSeatsToAllocate();
      setReservationExpiration(undefined);
      resetMovies();
      setSnackRestrictionCard(undefined);
      setDisable(false);
      setShowSelectModal(false);
      const [ticketStore] = newStore ?? [];
      const { storeId } = ticketStore ?? {};
      const { id: cityId } = newCity ?? {};
      navigateWithTalkShop(redirect ?? pathname, {
        storeId,
        cityId
      });
      setShowChangeModal(false);
    } catch (error) {
      notify(error, "exitHandler - ChangeStoreModal");
      setError(t.movie.cancelReservationError);
      setDisable(false);
    }
  };

  return (
    <Styles className="ChangeStoreModal">
      <HandStopSVG />
      <h3 className="ChangeStoreModal__title">{t.changeStore.title}</h3>
      <p className="ChangeStoreModal__description">
        {t.changeStore.description}
      </p>
      {error ? (
        <p className="ChangeStoreModal__description">
          {t.movie.cancelReservationError}
        </p>
      ) : null}
      <div className="ChangeStoreModal__buttons">
        <Button
          mode="PRIMARY"
          className="ChangeStoreModal__button ChangeStoreModal__confirm-button"
          onClick={exitHandler}
        >
          {t.changeStore.changeLocation} {storeName}
        </Button>
        <Button
          mode="SECONDARY"
          className="ChangeStoreModal__button ChangeStoreModal__cancel-button"
          onClick={closeModal}
        >
          {t.changeStore.keepCart} {currentStoreName ?? ""}
        </Button>
      </div>
    </Styles>
  );
};

ChangeStoreModal.defaultProps = {};

export default ChangeStoreModal;
