// Checkout store
import { getStoreSetState } from "@multicines/stores";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { resetRejectedOrder } from "./checkout.store.helpers";
import { defaultValues, resetDefaultValues } from "./checkout.store.helpers";
import { CheckoutStoreValues } from "./checkout.store.types";
import { PICK_UP_OPTIONS } from "config/constants";

export const useCheckoutStore = create<CheckoutStoreValues>()(
  devtools(
    (set, get) => {
      const values: CheckoutStoreValues = {
        ...defaultValues,
        setUserError: payload => {
          const prev = get().userError;
          const userError = getStoreSetState(payload, prev);
          set({ userError }, false, {
            type: "setUserError",
            payload
          });
        },
        setTransferAnonymousId: payload => {
          const prev = get().transferAnonymousId;
          const transferAnonymousId = getStoreSetState(payload, prev);
          set({ transferAnonymousId }, false, {
            type: "setTransferAnonymousId",
            payload
          });
        },
        setStep: payload => {
          const prev = get().step;
          const step = getStoreSetState(payload, prev);
          set({ step }, false, {
            type: "setStep",
            payload
          });
        },
        setPassword: payload => {
          const prev = get().password;
          const password = getStoreSetState(payload, prev);
          set({ password }, false, {
            type: "setPassword",
            payload
          });
        },
        setSelectedPickUpOption: (value: string) => {
          const selectedPickUpOption = PICK_UP_OPTIONS?.find(
            item => item.id === +value
          );
          if (!selectedPickUpOption) {
            throw new Error(
              `"${selectedPickUpOption}" does not correspond to a valid pick up option ID`
            );
          }
          set({ selectedPickUpOption }, false, {
            type: "setSelectedPickUpOption",
            value
          });
        },
        setAnonymousBillingData: payload => {
          const prev = get().anonymousBillingData;
          const anonymousBillingData = getStoreSetState(payload, prev);
          set({ anonymousBillingData }, false, {
            type: "setAnonymousBillingData",
            payload
          });
        },
        setUserFormValues: payload => {
          const prev = get().userFormValues;
          const userFormValues = getStoreSetState(payload, prev);
          set({ userFormValues }, false, {
            type: "setUserFormValues",
            payload
          });
        },
        setBillingDataFormValues: payload => {
          const prev = get().billingDataFormValues;
          const billingDataFormValues = getStoreSetState(payload, prev);
          set({ billingDataFormValues }, false, {
            type: "setBillingDataFormValues",
            payload
          });
        },
        setSameBilling: payload => {
          const prev = get().sameBilling;
          const sameBilling = getStoreSetState(payload, prev);
          set({ sameBilling }, false, {
            type: "setSameBilling",
            payload
          });
        },
        setStatus: payload => {
          const prev = get().status;
          const status = getStoreSetState(payload, prev);
          set({ status }, false, {
            type: "setStatus",
            payload
          });
        },
        setMessage: payload => {
          const prev = get().message;
          const message = getStoreSetState(payload, prev);
          set({ message }, false, {
            type: "setMessage",
            payload
          });
        },
        setPlacedOrder: payload => {
          const prev = get().placedOrder;
          const placedOrder = getStoreSetState(payload, prev);
          set({ placedOrder }, false, {
            type: "setPlacedOrder",
            payload
          });
        },
        setAreTermsAccepted: payload => {
          const prev = get().areTermsAccepted;
          const areTermsAccepted = getStoreSetState(payload, prev);
          set({ areTermsAccepted }, false, {
            type: "setAreTermsAccepted",
            payload
          });
        },
        resetCheckoutContext: () => {
          set({ ...resetDefaultValues }, false, {
            type: "resetCheckoutContext"
          });
        },
        resetRejectedOrder: () => {
          set({ ...resetRejectedOrder }, false, { type: "resetRejectedOrder" });
        }
      };
      return values;
    },
    { name: "Checkout store" }
  )
);
