import { Storage } from "@capacitor/storage";
import { useFetchStores } from "@multicines/services";
import { useCountriesStore, useGeoStore } from "@multicines/stores";
import { useEffect, useMemo, useState } from "react";

import CONSTANTS from "config/constants";
import { useStoresStore } from "stores/stores/stores.store";
import { getIsTalkShop } from "utils/talkshop.utils";

const { SELECTED_CITY_TOKEN } = CONSTANTS.STORAGE;

const useListenStores = () => {
  const storeQuery = useFetchStores();
  const selectedCoordinates = useGeoStore(state => state.selectedCoordinates);
  const selectedStore = useStoresStore(store => store.selectedStore);
  const setSelectedStore = useStoresStore(store => store.setSelectedStore);
  const setSelectedCity = useCountriesStore(state => state.setSelectedCity);
  const [url, setUrl] = useState<URL | undefined>();
  const [paramsStoreId, setParamsStoreId] = useState<number | undefined>();

  const { data: stores, isError } = storeQuery;
  const { isSuccess: isSuccessStore } = storeQuery;

  /**
   * Get the closest store by distance if selected coordinates.
   * @returns {Store} The closest store.
   */
  const closestStore = useMemo(() => {
    if (!stores) return;
    if (!selectedCoordinates) return;

    const [closestStore] = stores.sort((storeA, storeB) => {
      const [ticketStoreA] = storeA ?? [];
      const [ticketStoreB] = storeB ?? [];
      const { distance } = ticketStoreA ?? {};
      const { distance: distanceB } = ticketStoreB ?? {};

      if (!distance || !distanceB) return 0;

      return distance - distanceB;
    });
    return closestStore;
  }, [selectedCoordinates, stores]);

  useEffect(() => {
    if (window) {
      const url = new URL(window.location.href);
      setUrl(url);
      const paramsStoreId = url.searchParams.get("storeId")
        ? Number(url.searchParams.get("storeId"))
        : undefined;
      setParamsStoreId(paramsStoreId);
    }
  }, []);

  // INFO: Render only when initial enter url on the browser
  useEffect(() => {
    if (!isSuccessStore || !url) return;

    //INFO: talkShop workflow
    const { isTalkShop, storeId: talkShopStoreId } = getIsTalkShop();
    const talkShopStore = isTalkShop
      ? stores?.find(store => {
          const [ticketStore] = store ?? [];
          const { storeId } = ticketStore ?? {};

          return storeId === talkShopStoreId;
        })
      : undefined;

    //INFO: selectedStore by params
    const selectedParamsStore = paramsStoreId
      ? stores?.find(store => {
          const [ticketStore] = store ?? [];
          const { storeId } = ticketStore ?? {};
          return Number(storeId) === paramsStoreId;
        })
      : undefined;

    //INFO: selectedStore is cache Store by zustand
    const existCacheStore = stores?.find(store => {
      const [ticketStore] = store ?? [];
      const { storeId } = ticketStore ?? {};

      return storeId === selectedStore?.[0]?.storeId;
    });

    //INFO: firstStore
    const [firstStore] = stores ?? [];

    const storeWillSave =
      talkShopStore ??
      selectedParamsStore ??
      closestStore ??
      existCacheStore ??
      firstStore;

    const [ticketStore] = storeWillSave ?? [];
    const { city } = ticketStore ?? {};

    const { id: cityId, name } = city ?? {};
    if (!cityId || !name) {
      console.error("No city found in the store");
      return;
    }
    setSelectedCity({
      id: Number(cityId),
      name
    });
    setSelectedStore(storeWillSave);

    const { storeId } = ticketStore ?? {};

    if (url && paramsStoreId && storeId !== paramsStoreId) {
      url.searchParams.set("cityId", String(cityId ?? ""));
      url.searchParams.set("storeId", String(storeId ?? ""));
      window.history.replaceState({}, "", url.toString());
    }

    //INFO: make sure that it only runs when you log in to the site
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessStore, url, closestStore]);

  //INFO: Remove the key from local storage if an error occurred with the given value
  useEffect(() => {
    if (!isError) return;

    Storage.remove({ key: SELECTED_CITY_TOKEN });
    setSelectedStore(undefined);
    setSelectedCity(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  /**
   * Set coverage if there aren't nearby stores and selected shipping address.
   */
  // useEffect(() => {
  //   if (status !== "success") return;
  //   if (stores?.length > 0) return;
  //   // setSelectedCoordinates({
  //   //   lat: -0.1879668617094344,
  //   //   lng: -78.48067809354744
  //   // });

  //   setNoCoverage(false);
  // }, [setNoCoverage, setSelectedCoordinates, status, stores?.length]);
};

export default useListenStores;
