import CloseSVG from "images/close.svg";
import React from "react";

import Styles from "./Close.styles";
import { CloseProps as Props } from "./Close.types";

const Close: React.FC<Props> = props => {
  const { className, iconSize = 16, onClick } = props;

  return (
    <Styles className={`Close ${className}`} onClick={onClick}>
      <CloseSVG className="Close__icon" width={iconSize} />
    </Styles>
  );
};

Close.defaultProps = {
  className: ""
};

export default Close;
