import styled from "styled-components";

import { DistanceNotificationStyledProps as Props } from "./DistanceNotification.types";
import CONSTANTS from "config/constants";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const DistanceNotificationStyled = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: var(--sizes-navbar-height);
  grid-template-columns: var(--sizes-page-columns);
  align-items: center;
  background-color: var(--palette-mid-blue-s13-l18);

  .DistanceNotification {
    &__row {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.2rem 0;

      @media (max-width: ${mobile - 100}px) {
        justify-content: flex-start;
      }
    }

    &__button {
      padding: 1.2rem 2.4rem;
      font-weight: 700;
      font-size: 1.4rem;
      border: 0.2rem solid var(--palette-primary);
      border-radius: 0.8rem;

      @media (max-width: ${tablet - 1}px) {
        padding: 1.2rem;
      }
    }

    &__title {
      color: var(--palette-red-s95-l65);
      font-size: 1.6rem;
      font-weight: 700;
      text-align: center;
      padding-right: 1.6rem;

      @media (max-width: ${tablet - 1}px) {
        font-size: 1.4rem;
        padding-right: 0;
        max-width: 18rem;
      }
    }

    &__close {
      position: fixed;
      right: 4rem;
      background-color: var(--palette-gray-s0-l40-a40);
      border-radius: 0.8rem;
      padding: 0.8rem 1rem;

      @media (max-width: ${tablet - 1}px) {
        right: 0.8rem;
      }
    }

    &__gradient-bar {
      width: 100%;
      height: 0.8rem;
      position: relative;

      &__progress {
        position: absolute;
        background: linear-gradient(
          270deg,
          var(--palette-purple-s85-l45) -16%,
          var(--palette-pink-s75-l65) 48%,
          var(--palette-blue-s100-l45) 112%
        );
        height: 100%;
        transition: width 0.4s ease-in-out;
      }
    }
  }
`;

export default DistanceNotificationStyled;
