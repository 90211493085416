import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag, { BreadcrumbType } from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";

import CONSTANTS from "./constants";

const { API } = CONSTANTS;
const { BUGSNAG_API_WEB_KEY } = API;

Bugsnag.start({
  apiKey: BUGSNAG_API_WEB_KEY,
  plugins: [new BugsnagPluginReact()]
});
BugsnagPerformance.start({ apiKey: BUGSNAG_API_WEB_KEY });

export const ErrorBoundary =
  Bugsnag.getPlugin("react").createErrorBoundary(React);

/**
 * Bugsnag notify utility to send name and context for errors.
 *
 * @param {unknown} error The error threw by a function
 * @param {string} name Error descriptive name
 * @param {string} context Error context, if the error was generated on a hook, component, service or screen.
 */
export const notify = (error: unknown, name = "Error", context = "Context") => {
  if (error instanceof Error) {
    error.name = name;
    Bugsnag?.notify(error, e => {
      e.context = context;
    });
  }
  if (typeof error === "string") {
    const e = new Error(error);
    e.name = name;
    Bugsnag?.notify(e, e => {
      e.context = context;
    });
  }
};

/**
 * Bugsnag leave breadcrumb utility to help diagnose what events led to the error.
 *
 * @param message The description of the breadcrumb
 * @param metadata 	Diagnostic data relating to the breadcrumb
 * @param type 	The type of breadcrumb left
 */
export const leaveBreadcrumb = (
  message: string,
  metadata?: { [key: string]: string } | undefined,
  type?: BreadcrumbType | undefined
) => {
  Bugsnag?.leaveBreadcrumb(message, metadata, type);
};

export default Bugsnag;
