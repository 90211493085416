import styled from "styled-components";

import { NotificationStyledProps as Props } from "./Notification.types";
import CONSTANTS from "config/constants";
import { getMaxWidth } from "utils/styling.utils";

const { mobile, tablet } = CONSTANTS.BREAKPOINTS;

const NotificationStyled = styled.div<Props>`
  width: clamp(30.4rem, 40rem, calc(${getMaxWidth()} - 1.2rem));
  box-shadow: 0 0.4rem 1.2rem var(--palette-mid-blue-s15-l20),
    0 0.2rem 0.2rem var(--palette-mid-blue-s15-l20),
    inset 0 0 0 0.2rem var(--palette-mid-blue-s15-l20);
  padding: 2rem;
  border-radius: 0.8rem;
  max-width: 39.2rem;
  background-color: var(--palette-dark-blue-s13-l11);
  position: relative;
  margin-right: 15rem;
  @media (max-width: ${tablet}px) {
    margin-right: 8rem;
  }
  @media (max-width: ${mobile}px) {
    width: 27.4rem;
    max-height: 6.2rem;
    margin-right: 8rem;
    margin-bottom: 2rem;
  }

  .Notification {
    &__close {
      position: absolute;
      right: 1.6rem;
      top: 2.4rem;
      cursor: pointer;

      @media (max-width: ${mobile}px) {
        right: 1.2rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;

export default NotificationStyled;
