import { useRouter } from "next/router";

import { en, es } from "i18n";

const useI18n = () => {
  const { locale } = useRouter();
  switch (locale) {
    case "en":
      return en;
    case "es":
      return es;
    default:
      return es;
  }
};

export default useI18n;
