// BillingData store helper functions and data
import { StoreInitialValues } from "@multicines/stores";

import { StoresStoreValues } from "./stores.store.types";

export const defaultValues: StoreInitialValues<StoresStoreValues> = {
  defaultStore: undefined,
  noCoverage: false,
  selectedStore: undefined,
  showStoreNotification: false,
  closestStore: undefined
};

export const resetDefaultValues = {
  noCoverage: false,
  selectedStore: undefined
};
