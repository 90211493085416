// Custom Events

import { events } from "artisn/analytics";

import CONSTANTS from "config/constants";

const { logCustomEvent } = events;
const { PLATFORM } = CONSTANTS.GENERAL;

/**
 * Event used when user view billboard screen.
 * @since 1.0.0
 */
export const logSelectLocationCinema = (logData?: object) => {
  logCustomEvent("select_location_cinema", {
    ...logData
  });
};

/**
 * Event used when user click on the login button.
 * @since 1.0.0
 */
export const logSelectLogin = (logData?: object) => {
  logCustomEvent("select_login", {
    ...logData
  });
};

/**
 * Event used when user update politics.
 * @since 1.0.0
 */
export const logViewPolitics = (logData?: object) => {
  logCustomEvent("view_politics", {
    ...logData
  });
};

/**
 * Event used when user update politics.
 * @since 1.0.0
 */
export const logUpdatePolitics = (logData?: object) => {
  logCustomEvent("update_politics", {
    ...logData
  });
};

/**
 * Event used when user click in register button.
 * @since 1.0.0
 */
export const logSelectRegistration = (logData?: object) => {
  logCustomEvent("select_registration", {
    ...logData
  });
};

/**
 * Event used when user view page registration
 * @since 1.0.0
 */
export const logViewRegistrationStart = (logData?: object) => {
  logCustomEvent("view_registration_start", {
    ...logData
  });
};

/**
 * Event used when user click in multiclub login button.
 * @since 1.0.0
 */
export const logSelectMulticlubLogin = (logData?: object) => {
  logCustomEvent("select_multiclub_login", {
    ...logData
  });
};

/**
 * Event used when user click in ads banner.
 * @since 1.0.0
 */
export const logSelectAdsBanner = (logData?: object) => {
  logCustomEvent("select_ads_banner", {
    platform: PLATFORM ?? "banner without platform",
    ...logData
  });
};

/**
 * Event used when user select date.
 * @since 1.0.0
 */
export const logSelectDate = (logData?: object) => {
  logCustomEvent("select_date", {
    ...logData
  });
};

/**
 * Event used when user select hour.
 * @since 1.0.0
 */
export const logSelectHour = (logData?: object) => {
  logCustomEvent("select_hour", {
    ...logData
  });
};

/**
 * Event used when user click in purchase history.
 * @since 1.0.0
 */
export const logSelectPurchaseHistory = (logData?: object) => {
  logCustomEvent("select_purchase_history", {
    ...logData
  });
};

/**
 * Event used when user click in membership.
 * @since 1.0.0
 */
export const logSelectMembership = (logData?: object) => {
  logCustomEvent("select_membership", {
    ...logData
  });
};

/**
 * Event used when user view profile.
 * @since 1.0.0
 */
export const logViewProfile = (logData?: object) => {
  logCustomEvent("view_profile", {
    ...logData
  });
};

/**
 * Event used when user select multiclub login profile.
 * @since 1.0.0
 */
export const logSelectMulticlubLoginProfile = (logData?: object) => {
  logCustomEvent("select_multiclub_login_profile", {
    ...logData
  });
};

/**
 * Event used when user finish multiclub registration.
 * @since 1.0.0
 */
export const logMulticlubRegistrationFinish = (logData?: object) => {
  logCustomEvent("multiclub_registration_finish", {
    ...logData
  });
};

/**
 * Event used when user view multiclub registered.
 * @since 1.0.0
 */
export const logViewMulticlubRegistered = (logData?: object) => {
  logCustomEvent("view_multiclub_registered", {
    ...logData
  });
};

/**
 * Event used when user view multiclub
 * @since 1.0.0
 */
export const logViewMulticlub = (logData?: object) => {
  logCustomEvent("view_multiclub", {
    ...logData
  });
};

/**
 * Event used when user click in cancel button.
 * @since 1.0.0
 */
export const logCancelAttemptUnsubscribe = (logData?: object) => {
  logCustomEvent("cancel_attempt_unsubscribe", {
    ...logData
  });
};

/**
 * Event used when user unsubscribe account.
 * @since 1.0.0
 */
export const logUnsubscribeAccount = (logData?: object) => {
  logCustomEvent("unsubscribe_account", {
    ...logData
  });
};

/**
 * Event used when start talkshop.
 * @since 1.0.0
 */
export const logInitTalkshop = (logData?: object) => {
  logCustomEvent("init_talkshop", {
    ...logData
  });
};
