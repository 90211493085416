import styled from "styled-components";

import { InfoCardStyledProps as Props } from "./InfoCard.types";

const InfoCardStyled = styled.div<Props>`
  display: flex;
  align-items: center;

  .InfoCard {
    &__icon,
    &__icon-to-right {
      width: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__icon {
      path {
        fill: var(--palette-white);
      }
    }

    &__icon-to-right {
      margin-left: auto;
    }

    &__content {
      padding-left: 1.2rem;
      width: 84%;

      &__title {
        font-family: Inter;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: var(--palette-gray-s0-l70);
      }

      &__value {
        padding-top: 0.6rem;
        font-family: Inter;
        line-height: 2.4rem;
        color: var(--palette-white);
        overflow: hidden;
        white-space: nowrap;
        font-size: 1.6rem;
        text-overflow: ellipsis;
      }
    }
  }
`;

export default InfoCardStyled;
