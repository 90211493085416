import styled from "styled-components";

import { CloseStyledProps as Props } from "./Close.types";

const CloseStyled = styled.div<Props>`
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  cursor: pointer;

  .Close {
    &__icon {
      path {
        fill: var(--palette-white);
      }
    }
  }
`;

export default CloseStyled;
