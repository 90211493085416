// AddressForm helper functions and data
import { PostShippingAddressPayload } from "@multicines/services";
import { PutShippingAddressPayload } from "@multicines/services";
import { LivingPlace, ShippingAddress } from "artisn/types";
import * as yup from "yup";

import { AddressFormValues } from "./AddressForm.types";
import { Country } from "types/country.types";
import { Google } from "types/geo.types";
import { validationRules } from "utils/form.utils";

const { requiredString, requiredPhoneNumber } = validationRules;

export const AddressFormSchema = yup.object().shape({
  reference: requiredString,
  numberContactAddress: requiredPhoneNumber,
  nickname: requiredString,
  addressNumber: requiredString,
  secondaryStreet: requiredString,
  mainStreet: requiredString
});

export const getDefaultLivingPlace = (additionalInfo?: string): LivingPlace => {
  return {
    id: 1,
    name: "",
    active: 0,
    countryId: 0,
    fields: [
      {
        id: "additional_data",
        // HACK: Sirve para conservar las direcciones resultados de la API de Google
        label: additionalInfo ?? "",
        rules: {
          max: 0,
          min: 0
        },
        messages: {
          max: "0",
          min: "0"
        },
        value: "Casa"
      }
    ]
  };
};

export const getSelectedPostPayload = (
  form: AddressFormValues,
  mapAddress: Google.Geocode,
  country: Country
): PostShippingAddressPayload => {
  const { formatted_address, geometry } = mapAddress ?? {};
  const { location: coordinates } = geometry ?? {};
  const { addressNumber, otherNickname, nickname, mainStreet } = form;
  const { numberContactAddress } = form;
  const enhancedNickname = nickname === "Otro" ? otherNickname : nickname;
  for (const key in form) {
    if (key !== "otherNickname") continue;
    delete form[key];
    break;
  }

  return {
    ...form,
    ...coordinates,
    livingPlace: getDefaultLivingPlace(formatted_address),
    country: {
      id: country.id,
      name: country.name
    },
    number: addressNumber,
    nickname: enhancedNickname!,
    mainStreet,
    numberContactAddress: `${numberContactAddress}`
  };
};

export const getSelectedPutPayload = (
  form: AddressFormValues,
  mapAddress: Google.Geocode,
  country: Country,
  initialValues: AddressFormValues,
  editAddress: ShippingAddress
): PutShippingAddressPayload => {
  const { formatted_address, geometry } = mapAddress ?? {};
  const { livingPlace } = editAddress ?? {};
  const { location: coordinates } = geometry ?? {};
  const { addressNumber, otherNickname, nickname, mainStreet } = form;
  const enhancedNickname = nickname === "Otro" ? otherNickname : nickname;
  for (const key in form) {
    if (key !== "otherNickname") continue;
    delete form[key];
    break;
  }

  return {
    ...form,
    ...coordinates,
    livingPlace: mapAddress
      ? getDefaultLivingPlace(formatted_address)
      : livingPlace,
    country: {
      id: country.id,
      name: country.name
    },
    number: addressNumber,
    nickname: enhancedNickname!,
    mainStreet,
    id: initialValues.id!
  };
};

export const getDefaultNickname = (nickname?: string) => {
  if (!nickname) return "Casa";
  if (nickname !== "Casa" && nickname !== "Trabajo") return "Otro";
  return nickname;
};
