import { Image } from "artisn-ui-react";
import router from "next/router";
import React from "react";

import { defaultImageConfig } from "./AddToWishlistNotification.helpers";
import Styles from "./AddToWishlistNotification.styles";
import { AddToWishlistNotificationProps as Props } from "./AddToWishlistNotification.types";
import Button from "components/global/Button/Button";
import { defaultFunction } from "utils/common.utils";

const AddToWishlistNotification: React.FC<Props> = props => {
  const { className, product, onDelete = defaultFunction } = props;
  const { images, name } = product ?? {};

  return (
    <Styles
      className={`AddToWishlistNotification ${className}`}
      showCloseIcon={false}
      onClick={() => router.push("/profile/wishlist")}
    >
      {images?.[0] ? (
        <Image
          image={images?.[0].url}
          config={defaultImageConfig}
          alt="Action icon"
          className="AddToWishlistNotification__image"
        />
      ) : null}
      <p className="AddToWishlistNotification__description">
        Se ha guardado: {name} en Favoritos
      </p>
      <Button
        className="AddToWishlistNotification__button"
        mode="LINK"
        onClick={onDelete}
      >
        Eliminar
      </Button>
    </Styles>
  );
};

AddToWishlistNotification.defaultProps = {};

export default AddToWishlistNotification;
