import { useShoppingCartStore } from "@multicines/stores";
import { useEffect } from "react";

import useShoppingCart from "hooks/useShoppingCart/useShoppingCart";
import { useStoresStore } from "stores/stores/stores.store";
import { cleanShoppingCartAlerts } from "utils/shoppingCart.utils";

const useSetupShoppingCart = () => {
  const { listenShoppingCart, updateShoppingCart } = useShoppingCart();
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);
  const selectedStore = useStoresStore(store => store.selectedStore);

  const hasCart = !!shoppingCart;

  useEffect(() => {
    return listenShoppingCart?.();
  }, [listenShoppingCart]);

  // Clean shopping cart alerts if store changes
  useEffect(() => {
    if (!hasCart) return;
    updateShoppingCart?.(cleanShoppingCartAlerts);
  }, [selectedStore, updateShoppingCart, hasCart]);
};

export default useSetupShoppingCart;
