import styled from "styled-components";

import { ChangeStoreModalStyledProps as Props } from "./ChangeStoreModal.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const ChangeStoreModalStyled = styled.div<Props>`
  background-color: var(--palette-dark-blue-s13-l11);
  border-radius: 1.6rem;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mobile}px) {
    padding: 2.4rem 0.8rem;
  }

  .ChangeStoreModal {
    &__title {
      padding-top: 5.6rem;
      font-size: 2.4rem;
      font-weight: 400;
      text-align: center;
      color: var(--palette-light-blue-s10-l60);
    }

    &__description {
      line-height: 2.4rem;
      padding-top: 3.2rem;
      font-weight: 500;
      text-align: center;
      color: var(--palette-white);
      width: 72%;
    }

    &__buttons {
      display: flex;
      padding-top: 4rem;
      flex-direction: column;
    }

    &__button {
      flex: 1;
      line-height: 2.4rem;
      font-weight: 700;
      font-size: 1.4rem;
      color: var(--palette-black-s0-l10);
      margin-bottom: 1.6rem;

      &:not(:first-child) {
        margin-left: 0.8rem;

        @media (max-width: ${mobile}px) {
          margin-left: 0;
        }
      }
    }

    &__cancel-button {
      border-color: var(--palette-primary);
      color: var(--palette-primary);
    }
  }
`;

export default ChangeStoreModalStyled;
