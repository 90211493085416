import { useFetchCities, useFetchStoresByCity } from "@multicines/services";
import { useShoppingCartStore } from "@multicines/stores";
import { useCountriesStore } from "@multicines/stores";
import { useGeoStore } from "@multicines/stores";
import { CinemaStores, City } from "@multicines/types";
import { getStoreDistanceUnit, titleCase } from "@multicines/utils";
import { getApiMessageError, getTradeCodeApiError } from "@multicines/utils";
import { CINEMARK_TICKETS_VENDOR, TICKETS_VENDOR } from "@multicines/utils";
import { events } from "artisn/analytics";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { ScrollableTabs } from "artisn-ui-react";
import CheckSVG from "images/check.svg";
import MulticinesSVG from "images/logo-responsive.svg";
import CinemarkSVG from "images/logo_brand_cinemark.svg";
import MoviesSVG from "images/movie.svg";
import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import React from "react";

import Styles from "./SelectCinema.styles";
import { SelectCinemaProps as Props } from "./SelectCinema.types";
import SelectCinemaPlaceholder from "./SelectCinemaPlaceholder/SelectCinemaPlaceholder";
import Button from "../Button/Button";
import EmptyState from "../EmptyState/EmptyState";
import ErrorState from "../ErrorState/ErrorState";
import useAnalytics from "hooks/analytics/useAnalytics";
import useI18n from "hooks/useI18n";
import useTalkShop from "hooks/useTalkShop";
import { useStoresStore } from "stores/stores/stores.store";

const { logSetStore, logChangeStore } = events.store;

const SelectCinema: React.FC<Props> = props => {
  const { setShowModal } = props;
  const { onChangeCinema, setSelectShowModal } = props;
  const { back, pathname } = useRouter();
  const { isTalkShop, navigateWithTalkShop } = useTalkShop();
  const analyticsHook = useAnalytics({
    isTalkShop
  });
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);
  const setSelectedCity = useCountriesStore(state => state.setSelectedCity);
  const selectedCity = useCountriesStore(state => state.selectedCity);

  const selectedStore = useStoresStore(store => store.selectedStore);
  const setSelectedStore = useStoresStore(store => store.setSelectedStore);
  const [newCity, setNewCity] = useState<City | undefined>(selectedCity);
  const [newStore, setNewStore] = useState<CinemaStores | undefined>(
    selectedStore
  );
  const selectedCoordinates = useGeoStore(state => state.selectedCoordinates);
  const { data: cities, isLoading: isLoadingCities } = useFetchCities();
  const storeCityQuery = useFetchStoresByCity(newCity?.id);
  const t = useI18n();

  const { data: stores, isLoading: isLoadingStores } = storeCityQuery;
  const { status: storesByCityStatus, error, refetch } = storeCityQuery;

  const { commonParams } = analyticsHook;

  const storesLength = stores?.length;
  const noCinema = !!stores && stores.length === 0;

  const products = shoppingCart
    ? getShoppingCartProducts(shoppingCart)
    : undefined;
  const empty = (products && products.length === 0) || !shoppingCart;

  const multicinesStores = useMemo(() => {
    return stores?.filter(store => store?.[0]?.vendor.id === TICKETS_VENDOR.id);
  }, [stores]);

  const cinemarkStores = useMemo(() => {
    return stores?.filter(
      store => store?.[0]?.vendor.id === CINEMARK_TICKETS_VENDOR.id
    );
  }, [stores]);

  const refetchStoresByCityQuery = async () => {
    await refetch();
  };

  const handleSelectCity = async (city: City) => {
    setNewCity(city);
    setNewStore(undefined);
  };

  const handleSubmit = () => {
    if (!newCity || !newStore) return;

    if (selectedStore?.[0]?.storeId === newStore[0]?.storeId) {
      setSelectShowModal(false);
      return;
    }
    if (!empty) {
      onChangeCinema(newCity, newStore);
      return;
    }

    // Send analytics events
    const [prevTicketStore] = selectedStore ?? [];
    const [newTicketStore] = newStore ?? [];
    if (!prevTicketStore && newTicketStore) {
      logSetStore({ store: newTicketStore, ...commonParams });
    } else if (prevTicketStore && newTicketStore) {
      logChangeStore({
        previousStore: prevTicketStore,
        nextStore: newTicketStore,
        ...commonParams
      });
    }
    setSelectedStore(newStore);
    setSelectedCity(newCity);
    const [ticketStore] = newStore ?? [];
    const { storeId } = ticketStore ?? {};
    const { id: cityId } = newCity ?? {};
    setShowModal(false);
    navigateWithTalkShop(pathname, {
      storeId,
      cityId
    });
  };

  const renderCity = useCallback(
    (listItem: City) => {
      const { id, name } = listItem;
      const selectedCity =
        newCity?.id === id ? "SelectCinema__city__selected" : [];

      return (
        <Button
          onClick={() => handleSelectCity(listItem)}
          disabled={isLoadingCities}
          className={`SelectCinema__city__name ${selectedCity}`}
        >
          <p className="SelectCinema__name">{titleCase(name)}</p>
        </Button>
      );
    },
    [isLoadingCities, newCity]
  );

  const renderStore = useCallback(
    (listItem: CinemaStores) => {
      const cinemaStores = listItem;
      const [ticketsStore] = cinemaStores ?? [];
      const [newTicketsStore] = newStore ?? [];
      const { storeName = "", storeId, distance } = ticketsStore ?? {};
      const storeSelected =
        newTicketsStore?.storeId === storeId
          ? "SelectCinema__store__selected"
          : [];
      const selectedCheck =
        newTicketsStore?.storeId === storeId ? <CheckSVG /> : null;

      return (
        <Button
          onClick={() => setNewStore(cinemaStores)}
          disabled={isLoadingStores}
          className={`SelectCinema__store__name ${storeSelected}`}
        >
          <div className="SelectCinema__name__container">
            <p className="SelectCinema__name">{titleCase(storeName)}</p>
            {selectedCoordinates ? (
              <p className="SelectCinema__distance">
                {`${t.location.to} ${getStoreDistanceUnit(distance)} ${
                  t.location.from
                } `}
              </p>
            ) : null}
          </div>
          <div className="SelectCinema__selected__container">
            {selectedCheck}
          </div>
        </Button>
      );
    },
    [isLoadingStores, newStore, selectedCoordinates, t]
  );

  const renderCityMemoized = useMemo(() => renderCity, [renderCity]);

  const renderStoreMemoized = useMemo(() => renderStore, [renderStore]);

  const orderedCities = useMemo(() => {
    if (!cities || !selectedCity) return cities;
    return cities?.sort((x, y) => {
      return x.id === selectedCity.id ? -1 : y.id === selectedCity.id ? 1 : 0;
    });
  }, [cities, selectedCity]);

  const renderStates = (
    <>
      {storesByCityStatus === "loading" || storesByCityStatus === "idle" ? (
        <SelectCinemaPlaceholder />
      ) : null}

      {storesByCityStatus === "error" ? (
        <ErrorState
          title={getTradeCodeApiError(error, "TS")}
          description={getApiMessageError(error)}
          onClick={refetchStoresByCityQuery}
        />
      ) : null}

      {storesByCityStatus === "success" && noCinema ? (
        <EmptyState
          icon={<MoviesSVG />}
          title={t.movie.emptyStores}
          description={t.movie.emptyStoresMessage}
          buttonTitle={t.common.accept}
          onClick={() => back()}
        />
      ) : null}

      {storesByCityStatus === "success" ? (
        <>
          {multicinesStores && multicinesStores?.length > 0 ? (
            <div className="SelectCinema__brands">
              <MulticinesSVG />
            </div>
          ) : null}
          {multicinesStores?.map((item, index) => {
            return (
              <div key={item[0]?.storeId ?? index}>
                {renderStoreMemoized(item)}
              </div>
            );
          })}
          {cinemarkStores && cinemarkStores?.length > 0 ? (
            <div className="SelectCinema__brands">
              <CinemarkSVG width={240} />
            </div>
          ) : null}
          {cinemarkStores?.map((item, index) => {
            return (
              <div key={item[0]?.storeId ?? index}>
                {renderStoreMemoized(item)}
              </div>
            );
          })}
        </>
      ) : null}
    </>
  );

  return (
    <Styles className="SelectCinema">
      <ScrollableTabs
        className="SelectCinema__tabs"
        activeClassName="SelectCinema__item--active"
        defaultActiveItem={+(selectedCity?.id ?? 0)}
      >
        <div className="SelectCinema__city">
          {orderedCities?.map((item, index) => {
            return <div key={index}>{renderCityMemoized(item)}</div>;
          })}
        </div>
      </ScrollableTabs>
      <div className="SelectCinema__store">{renderStates}</div>
      {storesLength ? (
        <Button
          mode="PRIMARY"
          className="SelectCinema__button"
          onClick={handleSubmit}
          disabled={!newCity || !newStore}
        >
          {t.common.confirm}
        </Button>
      ) : null}
    </Styles>
  );
};

export default SelectCinema;
