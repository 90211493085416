// Stores store
import { getStoreSetState } from "@multicines/stores";
import { create } from "zustand";
import { persist } from "zustand/middleware";

import { defaultValues, resetDefaultValues } from "./stores.store.helpers";
import { StoresStoreValues } from "./stores.store.types";

export const useStoresStore = create(
  persist<StoresStoreValues>(
    (set, get) => {
      return {
        ...defaultValues,
        setDefaultStore: payload => {
          const prev = get().defaultStore;
          const defaultStore = getStoreSetState(payload, prev);
          set({ defaultStore });
        },
        setNoCoverage: payload => {
          const prev = get().noCoverage;
          const noCoverage = getStoreSetState(payload, prev);
          set({ noCoverage });
        },
        setSelectedStore: payload => {
          const prev = get().selectedStore;
          const selectedStore = getStoreSetState(payload, prev);
          set({ selectedStore });
        },
        setShowStoreNotification: payload => {
          const prev = get().showStoreNotification;
          const showStoreNotification = getStoreSetState(payload, prev);
          set({ showStoreNotification });
        },
        setClosestStore: payload => {
          const prev = get().closestStore;
          const closestStore = getStoreSetState(payload, prev);
          set({ closestStore });
        },
        resetStores: () => set({ ...resetDefaultValues })
      };
    },
    {
      name: "stores-storage"
    }
  )
);
