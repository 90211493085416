import styled from "styled-components";

import { StorePreviewStyledProps as Props } from "./StorePreview.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const StorePreviewStyled = styled.div<Props>`
  padding: 1.6rem 2.4rem;
  cursor: pointer;

  @media (max-width: ${mobile - 1}px) {
    padding: 1.2rem 0.4rem;
  }

  .StorePreview {
    &__info {
      display: flex;
      align-items: center;
      padding-top: 0.4rem;

      &__fill {
        min-width: 7.2rem;
        background-color: var(--palette-gray-s0-l98);
        border-radius: 2rem;
        padding: 0.4rem 0.8rem;
        display: flex;
        align-items: center;
      }

      &__fill-point {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background-color: var(--palette-green-s100-l40);
      }

      &__fill-text {
        padding-left: 0.6rem;
      }

      &__fill-closed {
        background-color: var(--palette-red-s61-l87);

        .StorePreview__info__fill-point {
          background-color: var(--palette-red-s65-l60);
        }

        .StorePreview__info__fill-text {
          color: var(--palette-red-s65-l60);
        }
      }

      &__distance {
        display: flex;
        padding-left: 0.4rem;
        color: var(--palette-gray-s0-l80);
      }

      &__distance-status {
        padding-right: 0.6rem;
        color: var(--palette-gray-s0-l80);
      }
    }

    &__info,
    &__address {
      color: var(--palette-gray-s0-l70);
      text-transform: capitalize;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .InfoCard {
    &__content {
      display: flex;
      flex-direction: column-reverse;

      @media (max-width: ${mobile - 1}px) {
        padding: 0 0.4rem;
        flex: 1;
      }

      &__title {
      }

      &__value {
        font-weight: 600;
        padding: 0;
        text-transform: capitalize;
      }
    }

    &__icon-to-right {
      @media (max-width: ${mobile - 1}px) {
        margin-left: 0;
      }
    }
  }

  &.selected {
    box-shadow: 0.2rem 0rem 0rem 0rem var(--palette-primary) inset;

    .InfoCard__content__value {
      color: var(--palette-primary);
    }

    .StorePreview__info__distance,
    .StorePreview__info__distance-status {
      color: var(--palette-green-s63-l42);
    }

    .InfoCard__icon-to-right {
      path {
        fill: var(--palette-primary);
      }
    }
  }
`;

export default StorePreviewStyled;
