import styled from "styled-components";

import { BenefitsNotificationStyledProps as Props } from "./BenefitsNotification.types";
import Notification from "components/global/notifications/Notification/Notification";
import CONSTANTS from "config/constants";
import { getMaxWidth } from "utils/styling.utils";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const BenefitsNotificationStyled = styled(Notification)<Props>`
  display: flex;
  background: url("assets/images/benefits-colors.png") no-repeat;
  background-size: cover;
  background-color: var(--palette-mid-blue-s15-l20);
  justify-content: space-between;
  align-items: center;
  align-content: center;
  box-shadow: none;
  cursor: pointer;
  border-radius: 1.6rem;
  max-width: ${getMaxWidth()};
  width: 48rem;
  height: 7.2rem;

  @media (max-width: ${tablet}px) {
    width: 32rem;
    height: 8rem;
    align-content: space-between;
  }

  @media (max-width: ${mobile}px) {
    /* width: 27.4rem; */
    width: 75%;
  }

  & svg {
    display: none;
  }

  .BenefitsNotification {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: var(--palette-white);

      &__title {
        @media (max-width: ${mobile}px) {
          display: none;
        }
      }

      &__title,
      &__message {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.8rem;
        color: inherit;
        display: block-inline;
      }
    }

    &__iconContainer {
      display: flex;
      align-items: baseline;
      align-content: center;

      & svg {
        height: 2rem;
        width: 2rem;
        display: block;

        &:not(:last-child) {
          margin-right: 1.6rem;

          @media (max-width: ${mobile}px) {
            margin-right: 0.6rem;
          }
        }
      }
    }
  }
`;

export default BenefitsNotificationStyled;
