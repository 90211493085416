import ErrorSVG from "images/error.svg";
import React from "react";

import Styles from "./ErrorState.styles";
import { ErrorStateProps as Props } from "./ErrorState.types";
import Button from "../Button/Button";
import useI18n from "hooks/useI18n";

const ErrorState: React.FC<Props> = props => {
  const { className, title, icon, description, buttonTitle, onClick } = props;
  const { onClickBack, backButtonTitle } = props;
  const t = useI18n();

  return (
    <Styles className={`ErrorState ${className}`}>
      {icon ?? <ErrorSVG />}
      {title ? <h3 className="ErrorState__title">{title}</h3> : null}
      <p className="ErrorState__message">{description}</p>
      {onClick ? (
        <Button mode="PRIMARY" onClick={onClick}>
          {buttonTitle ?? t.common.retry}
        </Button>
      ) : null}
      {onClickBack ? (
        <Button mode="SECONDARY" onClick={onClickBack}>
          {backButtonTitle ?? t.common.goBack}
        </Button>
      ) : null}
    </Styles>
  );
};

export default ErrorState;
