// AddressList helper functions and data
import { ShippingAddress } from "artisn/types";

import { Google } from "types/geo.types";

export const getMatchingAddresses = (
  address: ShippingAddress,
  searchResult: Google.Autocomplete[] | string
) => {
  const { label } = address.livingPlace.fields[0];
  if (typeof searchResult !== "string") {
    return searchResult.some(search => {
      const { main_text } = search.structured_formatting;
      return label.indexOf(main_text) !== -1;
    });
  }
  return label.indexOf(searchResult) !== -1;
};
