import { ServicesConfig, initServices } from "@multicines/services";

import { getQueryClient } from "./reactQuery.config";
import CONSTANTS from "config/constants";
import { shouldMock } from "utils/common.utils";

const { GENERAL, API, ARTISN } = CONSTANTS;
const { PLATFORM } = GENERAL;
const { ACCOUNT_ID } = ARTISN;
const { MOCK_WITH_INITIAL_USER_DATA } = API;

export const initServicesAxios = (config?: Partial<ServicesConfig>) => {
  const payload = {
    platform: PLATFORM,
    accountId: ACCOUNT_ID,
    shouldMock,
    getQueryClient,
    mockWithInitialUserData: MOCK_WITH_INITIAL_USER_DATA
  };

  initServices({
    ...payload,
    ...config
  });
};
