import { useCataloguesStore, useShoppingCartStore } from "@multicines/stores";
import { createShoppingCart, mergeShoppingCart } from "artisn/shopping-cart";
import { getShoppingCart, deleteShoppingCart } from "artisn/shopping-cart";
import CartErrorSVG from "images/cart-error.svg";
import { useRouter } from "next/router";
import React, { useMemo } from "react";

import Styles from "./ShareCartModal.styles";
import { ShareCartModalProps as Props } from "./ShareCartModal.types";
import InfoActionModal from "../InfoActionModal/InfoActionModal";
import CONSTANTS from "config/constants";
import useShippingCost from "hooks/useShippingCost";
import { useAuthStore } from "stores/auth/auth.store";
import { removeQueryParam, sanitizeQueryParams } from "utils/common.utils";

const { ACCOUNT_ID, SHOPPING_CART_DEFAULT_NAME } = CONSTANTS.ARTISN;

const ShareCartModal: React.FC<Props> = props => {
  const { query, asPath, replace } = useRouter();
  const catalogueId = useCataloguesStore(
    state => state.selectedCatalogue.catalogueId
  );
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);
  const { shareId } = sanitizeQueryParams(query);
  const { name: shoppingCartName = SHOPPING_CART_DEFAULT_NAME } =
    shoppingCart ?? {};
  const newPath = useMemo(() => removeQueryParam(asPath, "shareId"), [asPath]);
  const shippingCost = useShippingCost();
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);

  const replaceShoppingCart = async () => {
    if (!shareId) return;

    const incomingCart = await getShoppingCart({
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      accountId: ACCOUNT_ID,
      customerId: shareId,
      anonymous: isAnonymous!
    });

    if (!incomingCart) {
      await replace(newPath, undefined, { shallow: true });
      return;
    }

    if (shoppingCartName) {
      await deleteShoppingCart({
        shoppingCartName,
        anonymous: isAnonymous!,
        accountId: ACCOUNT_ID,
        customerId: uid!
      });
    }

    await createShoppingCart(
      {
        anonymous: isAnonymous!,
        accountId: ACCOUNT_ID,
        customerId: uid!
      },
      {
        stores: incomingCart.stores,
        channelId: +catalogueId,
        shippingCost,
        name: shoppingCartName
      }
    );
    await replace(newPath, undefined, { shallow: true });
  };

  const localMergeShoppingCart = async () => {
    if (!shareId) return;

    const incomingCart = await getShoppingCart({
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      customerId: shareId,
      anonymous: isAnonymous!,
      accountId: ACCOUNT_ID
    });

    if (!incomingCart) {
      await replace(newPath, undefined, { shallow: true });
      return;
    }

    await mergeShoppingCart(incomingCart, {
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      anonymous: isAnonymous!,
      accountId: ACCOUNT_ID,
      customerId: uid!
    });

    await replace(newPath, undefined, { shallow: true });
  };

  return (
    <Styles className="ShareCartModal">
      <InfoActionModal
        title="¿Deseas reemplazar tu carrito anterior?"
        description="Estas creando un nuevo carrito, deseas mantener el que tenias guardado anteriormente"
        cancelText="Mantener ambos"
        confirmText="Crear nuevo carrito"
        confirmAction={replaceShoppingCart}
        cancelAction={localMergeShoppingCart}
        // TODO: SDK check it opened={!!shareId && !!shoppingCart && checkInit()}
        icon={<CartErrorSVG />}
      />
    </Styles>
  );
};

ShareCartModal.defaultProps = {};

export default ShareCartModal;
