// Map helper functions and data

import { MapOptions } from "google-map-react";

export const mapOptions: MapOptions = {
  zoomControl: false,
  fullscreenControl: false
};

export const mapStyles = [
  {
    elementType: "geometry",
    stylers: [{ lightness: 50 }]
  }
];
