import "artisn-ui-react/main.css";
import { AppProps as Props } from "next/app";
import Script from "next/script";
import React from "react";
import { DehydratedState } from "react-query";
import "artisn-ui-react/main.css";
import "dayjs/locale/es";

import Layout from "components/global/Layout/Layout";
import Providers from "containers/Providers/Providers";

// INFO: https://github.com/vercel/next.js/issues/40372
const App: React.FC<Props<{ dehydratedState: DehydratedState }>> = props => {
  const { Component, pageProps } = props;

  return (
    <Providers dehydratedState={pageProps.dehydratedState}>
      <Layout>
        <Script
          id="google-tag-manager"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5ZNLTL6');
          `
          }}
        />
        <Component {...pageProps} />
      </Layout>
    </Providers>
  );
};

export default App;
