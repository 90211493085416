import styled from "styled-components";

import { ChangeStoreStyledProps as Props } from "./ChangeStore.types";
import CONSTANTS from "config/constants";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const ChangeStoreStyled = styled.div<Props>`
  .ChangeStore {
    &__store {
      background-color: unset;
      border: none;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }

      @media (max-width: ${mobile}px) {
        padding-left: 0.4rem;
        padding-right: 0;
      }
    }
    &__icon {
      @media (max-width: ${mobile}px) {
        margin-right: 0.8rem;
      }
    }

    &__text {
      color: var(--palette-white);
      margin: 0 0.4rem;
      font-size: 1.6rem;
      font-weight: 600;

      @media (max-width: ${tablet - 1}px) {
        margin-right: 0.8rem;
        width: 8rem;
      }

      @media (max-width: ${mobile - 100}px) {
        margin: 0;
        width: 4.8rem;
      }
    }
  }
`;

export default ChangeStoreStyled;
