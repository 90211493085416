import { SNACKS_VENDOR, TICKETS_VENDOR } from "@multicines/utils";
import { Providers } from "artisn/analytics";
import { initializeApp } from "firebase/app";
import { getAuth as firebaseGetAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import CONSTANTS from "config/constants";

const { ARTISN, GENERAL, API, PIXEL } = CONSTANTS;
const { PLATFORM } = GENERAL;
const { WEB } = PIXEL;
const { ACCOUNT_ID } = ARTISN;

export const getENVs = (() => {
  const env = process.env.NEXT_PUBLIC_ENV ?? "develop";

  return {
    develop: {
      apiKey: "AIzaSyCUVTt8APF3pPLqJUKPLcbP-DyQ5gsJDjU",
      authDomain: "multicines-dev.firebaseapp.com",
      databaseURL: "https://multicines-dev-default-rtdb.firebaseio.com",
      projectId: "multicines-dev",
      storageBucket: "multicines-dev.appspot.com",
      messagingSenderId: "457354218316",
      appId: "1:457354218316:web:9795fed2f9bf1f60959de9",
      measurementId: "G-Q9LRJ4XHF2",
      mapsApiKey: "AIzaSyBYS3bTEKg_fONPm8gbYYZDE2PDgigD5zQ",
      facebookAppId: "1012130829620580"
    },
    production: {
      apiKey: "AIzaSyD8hLVg2UOOzfWUXHz4pcvl0Hh8k4ZAF_o",
      authDomain: "multicines-prod.firebaseapp.com",
      databaseURL: "https://multicines-prod-default-rtdb.firebaseio.com",
      projectId: "multicines-prod",
      storageBucket: "multicines-prod.appspot.com",
      messagingSenderId: "635586280985",
      appId: "1:635586280985:web:41984bf2512e28ad087488",
      measurementId: "G-2N0MCQF9L7",
      mapsApiKey: "",
      facebookAppId: "230745211011678"
    }
  }[env];
})();

const firebaseConfig = {
  projectId: getENVs?.projectId ?? "",
  apiKey: getENVs?.apiKey ?? "",
  authDomain: `${getENVs?.projectId}.firebaseapp.com`,
  databaseURL: getENVs?.databaseURL,
  appId: getENVs?.appId,
  accountId: ACCOUNT_ID,
  platform: PLATFORM
};

export const app = initializeApp(firebaseConfig);
export const getAuth = firebaseGetAuth(app);
export const db = getFirestore(app);

export const facebookProvider = new Providers.FacebookPixel({
  name: "facebook",
  trackingId: getENVs?.facebookAppId ?? "",
  vendors: [TICKETS_VENDOR.id, SNACKS_VENDOR.id],
  withConversionsAPI: WEB.withConversionsAPI,
  isTrackingAllowed: WEB.isTrackingAllowed,
  conversionsApiConfig: {
    apiURL: API.API_URL,
    authToken: "",
    eventSourceUrl: GENERAL.WEB_URL,
    countryISOCode: WEB.countryISOCode,
    fbAccessToken: WEB.fbAccessToken ?? "",
    fbPixelId: WEB.fbPixelId,
    // TODO: remove when deploy production
    testCode: process.env.NODE_ENV === "development" ? WEB.testCode : undefined
  }
});

export const googleProvider = new Providers.GoogleAnalytics({
  name: "google",
  trackingId: getENVs?.measurementId ?? ""
});
