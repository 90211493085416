// Styling utility functions

export const getMaxWidth = () => {
  if (typeof window === "undefined") {
    return "var(--sizes-page-maxWidth)";
  }
  return `${document?.documentElement.clientWidth}px`;
};

export const getMaxHeight = () => {
  if (typeof window === "undefined") {
    return "var(--sizes-page-maxHeight)";
  }
  return `${window.innerHeight}px`;
};
