import styled from "styled-components";

import { InfoActionModalStyledProps as Props } from "./InfoActionModal.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const InfoActionModalStyled = styled.div<Props>`
  padding: 2.4rem;
  padding-top: 5.6rem;
  max-width: 43.2rem;
  min-width: 32rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--palette-black-s0-l10);

  @media (max-width: ${mobile}px) {
    min-height: 100vh;
    width: 100vw;
    padding-top: 2.4rem;
  }

  .InfoActionModal {
    &__icon {
      width: 64px;
      height: 64px;

      path {
        fill: var(--palette-light-blue-s10-l60);
      }
    }

    &__title {
      padding-top: ${props => (props.hasIcon ? "5.6" : "3.2")}rem;
      font-size: 2.4rem;
      font-weight: 400;
      text-align: center;
      color: var(--palette-light-blue-s10-l60);
    }

    &__description {
      line-height: 2.4rem;
      padding-top: 3.2rem;
      font-weight: 500;
      text-align: center;
      color: var(--palette-white);
    }

    &__buttons {
      display: flex;
      width: 100%;
      padding-top: 6.4rem;

      @media (max-width: ${mobile}px) {
        flex-direction: column;
      }
    }

    &__button {
      flex: 1;
      line-height: 2.4rem;
      font-weight: 700;
      font-size: 1.4rem;
      color: var(--palette-black-s0-l10);

      &:not(:first-child) {
        margin-left: 0.8rem;

        @media (max-width: ${mobile}px) {
          margin-left: 0;
          margin-top: 0.8rem;
        }
      }
    }

    &__cancel-button {
      border-color: var(--palette-primary);
      color: var(--palette-primary);
    }
  }
`;

export default InfoActionModalStyled;
