import styled from "styled-components";

import { FloatingButtonStyledProps as Props } from "./FloatingButton.types";
import CONSTANTS from "config/constants";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const FloatingButtonStyled = styled.button<Props>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--palette-mid-blue-s15-l20);
  border-radius: 1.6rem;
  height: 7.2rem;
  width: 7.5rem;
  bottom: 2rem;
  border: none;
  cursor: pointer;
  right: 8rem;

  @media (max-width: ${tablet}px) {
    bottom: 7rem;
    height: 8rem;
    right: 1.5rem;
    align-content: space-between;
  }

  @media (max-width: ${mobile}px) {
    height: 6.2rem;
    width: 6.2rem;
    right: 2rem;
    bottom: 9rem;
  }

  .FloatingButton {
    &__icon {
      @media (max-width: ${mobile}px) {
        transform: scale(0.6);
      }
    }
  }
`;

export default FloatingButtonStyled;
