import styled from "styled-components";

import { ShareCartModalStyledProps as Props } from "./ShareCartModal.types";

const ShareCartModalStyled = styled.div<Props>`
  .ShareCartModal {
  }
`;

export default ShareCartModalStyled;
