// Hook to log purchase events
import { PaymentMethodId, usePaymentsStore } from "@multicines/stores";
import { IncompleteOrder } from "@multicines/types";
import { events } from "artisn/analytics";
import { OrderDetails, ShoppingCart } from "artisn/types";
import { useCallback, useMemo } from "react";

import useAnalytics from "hooks/analytics/useAnalytics";
import useTalkShop from "hooks/useTalkShop";
import { useTalkShopStore } from "stores/talkshop/talkShop.store";
import { clearLocalValues, getLocalValues } from "utils/common.utils";
import { addPurchaseCount } from "utils/firebase.utils";

const { logPurchaseAttempt, logPurchaseFail } = events.purchase;
const { logPurchaseSuccess } = events.purchase;

const usePurchaseEvents = () => {
  const selectedPaymentMethodId = usePaymentsStore(
    store => store.selectedPaymentMethodId
  );
  const medium = useTalkShopStore(store => store.talkShopMedium);
  const { isTalkShop } = useTalkShop();
  const analyticsHook = useAnalytics({
    isSnackWorkflow: medium === "snack",
    isTalkShop
  });

  const { commonParams } = analyticsHook;

  const isCash = selectedPaymentMethodId === PaymentMethodId.CASH_ID;
  const isCard = selectedPaymentMethodId === PaymentMethodId.CARD_ID;

  const localLogPurchaseAttempt = useCallback(
    (shoppingCart: ShoppingCart | undefined) => {
      if (!shoppingCart) return;
      logPurchaseAttempt({
        fulfillmentType: "Delivery",
        selectedPaymentType: isCash ? "Cash" : isCard ? "Card" : "Other",
        shoppingCart,
        ...commonParams
      });
    },
    [isCard, isCash, commonParams]
  );

  const localLogPurchaseFail = useCallback(
    (errorMessage: string, shoppingCart: ShoppingCart | undefined) => {
      if (!shoppingCart) return;
      logPurchaseFail({
        fulfillmentType: "Delivery",
        selectedPaymentType: isCash ? "Cash" : isCard ? "Card" : "Other",
        shoppingCart,
        errorMessage,
        statusCode: 1,
        ...commonParams
      });
    },
    [isCard, isCash, commonParams]
  );

  const localLogPurchaseSuccess = useCallback(
    async (
      _: ShoppingCart | undefined,
      __: OrderDetails | IncompleteOrder | undefined
    ) => {
      const { order, shoppingCart } = getLocalValues();
      const { id: orderId } = order ?? {};
      if (!shoppingCart || !orderId) return;
      logPurchaseSuccess({
        fulfillmentType: "Delivery",
        selectedPaymentType: isCash ? "Cash" : isCard ? "Card" : "Other",
        shoppingCart,
        orderId,
        ...commonParams
      });
      await addPurchaseCount();
      clearLocalValues();
    },
    [isCard, isCash, commonParams]
  );

  return useMemo(
    () => ({
      logPurchaseAttempt: localLogPurchaseAttempt,
      logPurchaseFail: localLogPurchaseFail,
      logPurchaseSuccess: localLogPurchaseSuccess
    }),
    [localLogPurchaseAttempt, localLogPurchaseFail, localLogPurchaseSuccess]
  );
};

export default usePurchaseEvents;
