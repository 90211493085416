import CloseSVG from "images/close.svg";
import React from "react";

import Styles from "./Notification.styles";
import { NotificationProps as Props } from "./Notification.types";

const Notification: React.FC<Props> = props => {
  const { className, children, onClose, showCloseIcon = true } = props;
  const { onClick } = props;

  return (
    <Styles className={`Notification ${className}`} onClick={onClick}>
      {showCloseIcon ? (
        <CloseSVG className="Notification__close" onClick={onClose} />
      ) : null}
      {children}
    </Styles>
  );
};

Notification.defaultProps = {
  className: ""
};

export default Notification;
