import { getFetchMoviesKeys } from "@multicines/services";
import { useCountriesStore } from "@multicines/stores";
import { useMoviesStore } from "@multicines/stores";
import { checkInit } from "artisn/analytics";
import dayjs from "dayjs";
import Head from "next/head";
import React, { memo, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";

import Styles from "./Layout.styles";
import { LayoutProps as Props } from "./Layout.types";
import HighTrafficModal from "../HighTrafficModal/HighTrafficModal";
import MergeCartModal from "../MergeCartModal/MergeCartModal";
import OutOfServiceModal from "../OutOfServiceModal/OutOfServiceModal";
import DistanceNotification from "../notifications/DistanceNotification/DistanceNotification";
import ShareCartModal from "components/global/ShareCartModal/ShareCartModal";
import StoreNotification from "components/global/notifications/StoreNotification/StoreNotification";
import PrivacyPolitics from "components/home/PrivacyPolitics/PrivacyPolitics";
import { getAuth } from "config/artisn.config";
import { notify } from "config/bugsnag.config";
import CONSTANTS from "config/constants";
import { initServicesAxios } from "config/services.config";
import useSetupArtisn from "hooks/artisn/useSetupArtisn";
import useListeners from "hooks/useListeners";
import useShoppingCart from "hooks/useShoppingCart/useShoppingCart";
import { useAnalyticsStore } from "stores/analytics/analytics.store";
import { useAuthStore } from "stores/auth/auth.store";
import { useGlobalsStore } from "stores/globals/globals.store";
import { useStoresStore } from "stores/stores/stores.store";
import { getIsTalkShop } from "utils/talkshop.utils";

const { TITLE, FEATURE_FLAGS, ARTISN } = CONSTANTS;
const { WITH_SHARE_SHOPPING_CART } = FEATURE_FLAGS;
const { WITH_ANONYMOUS_SHOPPING_CART_TOKEN } = FEATURE_FLAGS;
const { SHOPPING_CART_DEFAULT_NAME } = ARTISN;
const { WEB_URL, PLATFORM } = CONSTANTS.GENERAL;

const Layout: React.FC<Props> = props => {
  const { children } = props;
  const { isTalkShop } = getIsTalkShop();
  initServicesAxios({
    platform: isTalkShop ? "whatsapp" : PLATFORM
  });
  useSetupArtisn();
  useListeners();
  const selectedCountry = useCountriesStore(state => state.selectedCountry);
  const { locale } = selectedCountry;
  const canShowDistanceAlert = useGlobalsStore(
    state => state.canShowDistanceAlert
  );
  const showDistanceAlert = useGlobalsStore(state => state.showDistanceAlert);
  const disabledWeb = useGlobalsStore(state => state.disabledWeb);
  const setGlobalDate = useMoviesStore(store => store.setGlobalDate);
  const showHighTrafficModal = useGlobalsStore(
    state => state.showHighTrafficModal
  );
  const shoppingCartDeleted = useRef(false);
  const setAnalyticsInitialized = useAnalyticsStore(
    state => state.setAnalyticsInitialized
  );
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const queryClient = useQueryClient();
  const { deleteShoppingCart } = useShoppingCart();

  const [shouldShowTrafficModal, setShouldShowTrafficModal] = useState(false);
  const [trafficModalState, setTrafficModalState] = useState(1);

  const selectedStore = useStoresStore(store => store.selectedStore);
  const [ticketsStore] = selectedStore ?? [];
  const { additionalInfo, vendor } = ticketsStore ?? {};
  const { id: vendorId } = vendor ?? {};
  const { externalId: externalStoreId } = additionalInfo ?? {};

  const refetchSessionTypesQuery = async () => {
    const movieKeys = getFetchMoviesKeys(externalStoreId, vendorId);
    await queryClient.invalidateQueries("banners");
    await queryClient.invalidateQueries(movieKeys);
    await queryClient.invalidateQueries([uid, "loyalty"]);
  };

  const onPressContinueBuy = async () => {
    await refetchSessionTypesQuery();
    setShouldShowTrafficModal(false);
  };

  useEffect(() => {
    if (showHighTrafficModal) {
      setShouldShowTrafficModal(true);
      return;
    }
    // Used to turn off traffic modal after a random timeout
    setShouldShowTrafficModal(prev => {
      // Check if prev state was true, then we await for random timeout to change to second modal step to turn off after button is pressed.
      if (prev) {
        setTrafficModalState(2);
      } else {
        // Else we ensures that traffic modal is set off.
        return false;
      }
      return prev; // Returns prev state
    });
  }, [showHighTrafficModal]);

  // Delete shopping cart and set initial date on start up
  useEffect(() => {
    shoppingCartDeleted.current = false;
    setGlobalDate(dayjs());
  }, [isAnonymous, setGlobalDate]);

  useEffect(() => {
    if (
      shoppingCartDeleted.current ||
      typeof isAnonymous === "undefined" ||
      !uid
    ) {
      return;
    }
    if (!deleteShoppingCart) return;

    (async () => {
      try {
        await deleteShoppingCart({
          shoppingCartName: SHOPPING_CART_DEFAULT_NAME
        });
        // Refresh the token
        await getAuth.currentUser?.getIdToken(true);
        await queryClient.invalidateQueries([uid, "loyalty"]);
        shoppingCartDeleted.current = true;
      } catch (e) {
        notify(e, "deleteShoppingCart - Layout");
        console.warn(e.message);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnonymous, uid]);

  useEffect(() => {
    setAnalyticsInitialized(checkInit());
  }, [setAnalyticsInitialized]);

  return (
    <Styles className="Layout">
      <Head>
        <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
        <script
          defer
          src={`https://maps.googleapis.com/maps/api/js?key=AIzaSyD8hLVg2UOOzfWUXHz4pcvl0Hh8k4ZAF_o&loading=async&libraries=places&language=${locale}`}
        />
        <title>{TITLE}</title>
      </Head>
      {children}
      <StoreNotification />
      {showDistanceAlert && canShowDistanceAlert ? (
        <DistanceNotification />
      ) : null}
      {WITH_SHARE_SHOPPING_CART ? <ShareCartModal /> : null}
      {WITH_ANONYMOUS_SHOPPING_CART_TOKEN ? <MergeCartModal /> : null}
      {disabledWeb ? <OutOfServiceModal url={WEB_URL} /> : null}
      {shouldShowTrafficModal ? (
        <HighTrafficModal
          trafficModalState={trafficModalState}
          onPress={onPressContinueBuy}
        />
      ) : null}
      {<PrivacyPolitics />}
    </Styles>
  );
};

Layout.defaultProps = {};

export default memo(Layout);
