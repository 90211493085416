import styled from "styled-components";

import { ShoppingCartNotificationsStyledProps as Props } from "./ShoppingCartNotifications.types";

const ShoppingCartNotificationsStyled = styled.div<Props>`
  .ShoppingCartNotifications {
  }
`;

export default ShoppingCartNotificationsStyled;
