import styled from "styled-components";

import { CounterStyledProps as Props } from "./Counter.types";

const CounterStyled = styled.div<Props>`
  display: flex;
  align-items: center;

  .Counter {
    &__quantity {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--palette-dark-blue-s13-l11);
      color: ${props =>
        props.disabled
          ? "var(--palette-primary-dark)"
          : "var(--palette-white)"};
      border-radius: 0.4rem;
      padding: 0.8rem;
      font-weight: 600;
      margin: 0 0.8rem;
    }

    &__button {
      height: 4rem;
      width: 4rem;
      justify-content: center;
      align-items: center;
      padding: 0;
      background-color: transparent;
      border: solid 0.2rem var(--palette-primary);
      border-radius: 12rem;

      path {
        fill: var(--palette-primary);
      }

      &:disabled {
        border: solid 0.3rem;
        background-color: transparent;
        box-shadow: var(--palette-primary-light) 0 0 0 0.02rem;

        path {
          fill: var(--palette-white);
        }
      }

      &:hover:not([disabled]) {
        background-color: var(--palette-mid-blue-s15-l20);
        box-shadow: none;
        border: solid 0.2rem var(--palette-primary);
      }
    }

    &__icon {
      color: var(--palette-primary);
    }
  }
`;

export default CounterStyled;
