import WhatsappSVG from "images/whatsapp.svg";
import React from "react";

import Styles from "./FloatingButton.styles";
import { FloatingButtonProps as Props } from "./FloatingButton.types";
import CONSTANTS from "../../../config/constants";

const { WHATSAPP_API, WHATSAPP_NUMBER } = CONSTANTS;

const FloatingButton: React.FC<Props> = props => {
  const whatsappHandleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();

    const whatsappLink = `${WHATSAPP_API}${WHATSAPP_NUMBER}`;

    window.open(whatsappLink, "_blank");
  };

  return (
    <Styles className="FloatingButton" onClick={whatsappHandleClick}>
      <WhatsappSVG className="FloatingButton__icon" />
    </Styles>
  );
};

FloatingButton.defaultProps = {};

export default FloatingButton;
