// Analytics store
import { getStoreSetState } from "@multicines/stores";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { defaultValues } from "./analytics.store.helpers";
import { AnalyticsStoreValues } from "./analytics.store.types";

export const useAnalyticsStore = create<AnalyticsStoreValues>()(
  devtools(
    (set, get) => {
      const values: AnalyticsStoreValues = {
        ...defaultValues,
        setAnalyticsInitialized: payload => {
          const prev = get().analyticsInitialized;
          const analyticsInitialized = getStoreSetState(payload, prev);
          set({ analyticsInitialized }, false, {
            type: "setAnalyticsInitialized",
            payload
          });
        },
        resetAnalyticsContext: () =>
          set({ ...defaultValues }, false, { type: "reset" })
      };
      return values;
    },
    { name: "Analytics store" }
  )
);
