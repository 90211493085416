// TalkShop store

import { getStoreSetState } from "@multicines/stores";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { defaultValues } from "./talkShop.store.helpers";
import { TalkShopStoreValues } from "./talkShop.store.types";

export const useTalkShopStore = create<TalkShopStoreValues>()(
  devtools(
    (set, get) => {
      const values: TalkShopStoreValues = {
        ...defaultValues,
        setTalkShopIdentifier: payload => {
          const prev = get().talkShopIdentifier;
          const talkShopIdentifier = getStoreSetState(payload, prev);
          set({ talkShopIdentifier }, false, {
            type: "setTalkShopIdentifier",
            payload
          });
        },
        setTalkShopProvider: payload => {
          const prev = get().talkShopProvider;
          const talkShopProvider = getStoreSetState(payload, prev);
          set({ talkShopProvider }, false, {
            type: "talkShopProvider",
            payload
          });
        },
        setTalkShopMedium: payload => {
          const prev = get().talkShopMedium;
          const talkShopMedium = getStoreSetState(payload, prev);
          set({ talkShopMedium }, false, {
            type: "setTalkShopMedium",
            payload
          });
        },
        clearTalkShopContext: () =>
          set({ ...defaultValues }, false, { type: "clearTalkShopContext" })
      };
      return values;
    },
    { name: "TalkShop store" }
  )
);
