import { Modal } from "artisn-ui-react";
import WifiSVG from "images/wifi-off.svg";
import React from "react";

import { backdropConfig } from "./OutOfServiceModal.helpers";
import Styles from "./OutOfServiceModal.styles";
import { OutOfServiceModalProps as Props } from "./OutOfServiceModal.types";

const OutOfServiceModal: React.FC<Props> = props => {
  // const { url } = props;

  return (
    <Modal
      backdropConfig={backdropConfig}
      className="OutOfServiceModal__modal"
      opened={true}
    >
      <Styles className="OutOfServiceModal">
        <div className="OutOfServiceModal__svg">
          <WifiSVG />
        </div>
        <h1 className="OutOfServiceModal__title">Fuera de servicio</h1>
        <p className="OutOfServiceModal__description">
          Actualmente la web se encuentra fuera de servicio, intenta volver
          luego o visita nuestros canales oficiales.
        </p>
        {/* <Link href={url} passHref>
          <a
            className="Footer__privacy-and-terms__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className="OutOfServiceModal__button">Visita la web</Button>
          </a>
        </Link> */}
      </Styles>
    </Modal>
  );
};

OutOfServiceModal.defaultProps = {};

export default OutOfServiceModal;
