import useListenAddresses from "./listeners/useListenAddresses";
import useListenAuth from "./listeners/useListenAuth";
import useListenBillingData from "./listeners/useListenBillingData";
import useListenGeo from "./listeners/useListenGeo";
import useListenGlobals from "./listeners/useListenGlobals";
import useListenShoppingCart from "./listeners/useListenShoppingCart";
import useListenStores from "./listeners/useListenStores";
import useListenTalkshop from "./listeners/useListenTalkshop";
import useListenTheme from "./listeners/useListenTheme";
import useListenUser from "./listeners/useListenUser";
import useListenVendor from "./listeners/useListenVendor";

const useListeners = () => {
  useListenTheme();
  useListenAuth();
  useListenUser();
  useListenGeo();
  useListenStores();
  useListenVendor();
  useListenAddresses();
  useListenBillingData();
  useListenShoppingCart();
  useListenGlobals();
  useListenTalkshop();
};

export default useListeners;
