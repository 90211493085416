// Custom Events

import { events } from "artisn/analytics";

import CONSTANTS from "config/constants";

const { logCustomEvent } = events;
const { PLATFORM } = CONSTANTS.GENERAL;

/**
 * Event used when user view billboard screen.
 * @since 1.0.0
 */
export const logViewMovieCatalogue = (logData?: object) => {
  logCustomEvent("view_catalogue", {
    category_item: "movies",
    ...logData
  });
};

/**
 * Event used when user select a movie
 * @since 1.0.0
 */
export const logSelectMovie = (logData?: object) => {
  logCustomEvent("select_movie", {
    ...logData
  });
};

/**
 * Event used when user view movie details
 * @since 1.0.0
 */
export const logViewMovieDetails = (logData?: object) => {
  logCustomEvent("view_movie_details", {
    ...logData
  });
};

/**
 * Event used when user select a trailer
 * @since 1.0.0
 */
export const logSelectTrailer = (logData?: object) => {
  logCustomEvent("select_trailer", {
    ...logData
  });
};

/**
 * Event used when user view tickets
 * @since 1.0.0
 */
export const logViewTickets = (logData?: object) => {
  logCustomEvent("view_tickets", {
    ...logData
  });
};

/**
 * Event used when user select tickets
 * @since 1.0.0
 */
export const logSelectTickets = (logData?: object) => {
  logCustomEvent("select_tickets", {
    ...logData
  });
};

/**
 * Event used when user remove tickets
 * @since 1.0.0
 */
export const logRemoveTickets = (logData?: object) => {
  logCustomEvent("remove_tickets", {
    ...logData
  });
};

/**
 * Event used when user view seats
 * @since 1.0.0
 */
export const logViewSeats = (logData?: object) => {
  logCustomEvent("view_seats", {
    ...logData
  });
};

/**
 * Event used when user select seat
 * @since 1.0.0
 */
export const logSelectSeat = (logData?: object) => {
  logCustomEvent("select_seat", {
    ...logData
  });
};

/**
 * Event used when user deselect seat
 * @since 1.0.0
 */
export const logDeselectSeat = (logData?: object) => {
  logCustomEvent("deselect_seat", {
    ...logData
  });
};

/**
 * Event used when user continue the ticket reservation.
 * @since 1.0.0
 */
export const logContentView = (logData?: object) => {
  logCustomEvent("content_view", {
    ...logData
  });
};

/**
 * Event used when user make a reservation
 * @since 1.0.0
 */
export const logReservation = (logData?: object) => {
  logCustomEvent("reservation", {
    ...logData
  });
};

/**
 * Event used when user cancel the ticket reservation.
 * @since 1.0.0
 */
export const logCancelProcess = (logData?: object) => {
  logCustomEvent("cancel_process", {
    ...logData
  });
};

/**
 * Event used when user click in movies banner.
 * @since 1.0.0
 */
export const logSelectMoviesBanner = (logData?: object) => {
  logCustomEvent("select_movies_banner", {
    platform: PLATFORM ?? "banner without platform",
    ...logData
  });
};
