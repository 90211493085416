import styled from "styled-components";

import { MarkerStyledProps as Props } from "./Marker.types";

const MarkerStyled = styled.div<Props>`
  .Marker {
  }
`;

export default MarkerStyled;
