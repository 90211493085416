import React from "react";

import Styles from "./InfoCard.styles";
import { InfoCardProps as Props } from "./InfoCard.types";
import { defaultFunction } from "utils/common.utils";

const InfoCard: React.FC<Props> = props => {
  const { className, Icon, title, value, IconToRight } = props;
  const { onRightIconClick = defaultFunction } = props;
  const { onContentClick = defaultFunction } = props;

  return (
    <Styles className={`InfoCard ${className}`}>
      {Icon ? (
        <div className="InfoCard__icon">
          <Icon />
        </div>
      ) : null}
      <div className="InfoCard__content" onClick={onContentClick}>
        {title ? <div className="InfoCard__content__title">{title}</div> : null}
        <div className="InfoCard__content__value">{value}</div>
      </div>
      {IconToRight ? (
        <div className="InfoCard__icon-to-right" onClick={onRightIconClick}>
          <IconToRight />
        </div>
      ) : null}
    </Styles>
  );
};

InfoCard.defaultProps = {
  className: ""
};

export default InfoCard;
