import { Modal } from "artisn-ui-react";
import styled from "styled-components";

import { PrivacyPoliticsStyledProps as Props } from "./PrivacyPolitics.types";
import CONSTANTS from "config/constants";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const PrivacyPoliticsStyled = styled(Modal)<Props>`
  & > .Modal__body {
    background-color: var(--palette-mid-blue-s15-l20);
    border-radius: 1.6em;
    padding: 3.2rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 48rem;
  }

  .PrivacyPolitics {
    &__title {
      margin: 1.6rem 0;

      @media (max-width: ${tablet}px) {
        margin: 1.6rem;
      }
    }

    &__errorMessage {
      color: var(--palette-red-s65-l60);
      width: 100%;
    }

    &__button {
      width: 100%;
      margin-top: 3.2rem;
      padding: 1.6rem;

      @media (max-width: ${tablet}px) {
        margin-bottom: 0;
      }
    }

    &__separator {
      margin-top: 2rem;
      margin-bottom: 1.6rem;
      background-color: var(--palette-gray-s0-l40-a40);
      height: 0.1rem;
      border-radius: 0.8rem;
      width: 100%;
    }

    &__icon {
      padding-left: 0.2rem;
      margin-bottom: -0.6rem;
    }

    &__text {
      &__primary {
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.4rem;
        color: var(--palette-white);
        padding-bottom: 1.6rem;
      }

      &__paragraph {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.6rem;
        color: var(--palette-white);
        padding-bottom: 1.6rem;

        @media (max-width: ${tablet}px) {
          margin: 0 1.6rem;
        }
      }
    }

    &__checkbox {
      padding-top: 2.4rem;
      display: flex;
      justify-content: flex-start;
      width: 100%;

      @media (max-width: ${tablet}px) {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
      }
    }

    &__terms {
      display: flex;
      align-items: top;
      color: var(--palette-white);
      padding-left: 1.6rem;

      @media (max-width: ${mobile - 1}px) {
        flex-wrap: wrap;
      }

      span > a {
        text-decoration: underline;
        text-decoration-color: var(--palette-mid-blue-s5-l80);
        color: inherit;
        font-weight: 700;
        align-items: center;
      }
    }

    &__containerMulticlub {
      display: flex;
      font-size: 1.4rem;
      color: var(--palette-white);
      align-items: center;
      border: 0.1rem solid var(--palette-primary);
      border-radius: 1.8rem;
      margin-top: 1.8rem;
      padding: 1.4rem;
    }

    &__multiclubText {
      flex-direction: row;
      align-items: center;
    }

    &__info {
      margin-right: 1.4rem;
    }
  }
`;

export default PrivacyPoliticsStyled;
