import { StoreInitialValues } from "@multicines/stores";

import { AuthStoreValues } from "./auth.store.types";

export const defaultValues: StoreInitialValues<AuthStoreValues> = {
  isAnonymous: false,
  uid: undefined,
  providerData: [],
  clubName: undefined
};

export const resetDefaultValues = {
  isAnonymous: false,
  clubName: undefined
};
