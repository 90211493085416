import { CountryMeta, Movie } from "@multicines/types";
import { titleCase } from "@multicines/utils";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { getShoppingCartTotal } from "artisn/shopping-cart";
import { CartProduct, OrderDetails } from "artisn/types";
import { OrderProduct, OrderStore } from "artisn/types";
import dayjs from "dayjs";

import { getLocalValues } from "./common.utils";
import { getSanitizedProductTotals } from "./product.utils";
import CONSTANTS from "config/constants";

const { PHONE_NUMBER } = CONSTANTS.INTEGRATIONS.WHATSAPP;
const { TALKSHOP_PROVIDER, TALKSHOP_IDENTIFIER } = CONSTANTS.STORAGE;
const { WITH_TALK_SHOP } = CONSTANTS.FEATURE_FLAGS;

/**
 * Function that get if we are in the talkShop flow
 *
 * @since 1.0.0
 * @returns {boolean} isTalkShop
 */
export const getIsTalkShop = () => {
  if (typeof window === "undefined") return { isTalkShop: false };

  const query = new URLSearchParams(window.location.search);
  const providerValue = query.get("provider")?.trim();
  const providerUtm = query.get("utm_source")?.trim();
  const provider = providerUtm ?? providerValue;
  const identifier = query.get("identifier")?.trim();
  const storeId = query.get("storeId")?.trim();

  const hasProvider = !!provider && provider.length > 0;
  const hasIdentifier = !!identifier && identifier.length > 0;
  const isTalkShop = hasProvider && hasIdentifier && WITH_TALK_SHOP;

  return { isTalkShop, storeId };
};

export const buildTalkShopErrorMessage = (
  talkShopIdentifier: string,
  id?: number
) => ({
  id: `${id ?? 0}`,
  did: `${PHONE_NUMBER}`,
  msisdn: `${talkShopIdentifier.trim()}`,
  type: "media",
  channel: "WHATSAPP",
  name: "Talkshop",
  content:
    "Lo sentimos, tu compra no ha sido realizada, te invitamos a que vuelvas a intentarlo, por favor digita 1 o empezar",
  isAttachment: false
});

export const buildTalkShopFinishMessage = (
  talkShopIdentifier: string,
  orderId?: number
) => ({
  id: `${orderId ?? 0}`,
  did: `${PHONE_NUMBER}`,
  msisdn: `${talkShopIdentifier.trim()}`,
  type: "media",
  channel: "WHATSAPP",
  name: "Talkshop",
  content:
    "Fue un gusto atenderte, Muchas gracias por tu compra. Esperamos que disfrutes de la película🍿🎬 \n\nPor favor, responde si para continuar caso contrario la conversación será finalizada.",
  isAttachment: false
});

export const movieDetailMessage = (
  movie: Movie,
  storeName = "",
  showtime = "",
  locale: CountryMeta["locale"] = "es"
) => {
  const date = dayjs(showtime).locale(locale);
  const day = titleCase(date.format("dddd DD MMMM YYYY"));
  const hour = date.format("HH:mm");

  return `
${titleCase(movie.title)} :

📍 ${storeName}
🗓️ ${day}
🕐 ${hour}


`;
};

const getProducts = (stores: OrderStore[]): (OrderProduct | CartProduct)[] => {
  const initialArray: (OrderProduct | CartProduct)[] = [];
  const orderProductsOfStores = stores?.reduce((accumulator, store) => {
    return [...accumulator, ...store.products];
  }, initialArray);

  return orderProductsOfStores ?? initialArray;
};

export const getTalkShopOrderDetail = (
  showModifiers: (product: CartProduct) => string,
  formatByCurrency: (value: number | null | undefined) => string,
  locale: CountryMeta["locale"] = "es",
  decimals: number,
  orderDetail: OrderDetails
): string => {
  if (!orderDetail) return "No se pudo obtener el detalle de la compra";

  let response = "";

  const { additionalInfo, shoppingCart } = orderDetail;
  const { movie, session } = additionalInfo ?? {};
  const { stores, billTotal } = shoppingCart ?? {};
  const { storeName } = stores?.[0] ?? {};
  const { showtime } = session ?? {};
  const { total: totalOrder } = billTotal ?? {};

  if (movie) {
    response += movieDetailMessage(movie, storeName, showtime, locale);
  }

  const { shoppingCart: localShoppingCart } = getLocalValues();
  const cartProducts = localShoppingCart
    ? getShoppingCartProducts(localShoppingCart)
    : [];

  if (cartProducts.length > 0) {
    cartProducts?.forEach((product, index) => {
      const { name, amount } = product ?? {};
      const modifiers = showModifiers(product as CartProduct);
      const { totals } = getSanitizedProductTotals(product, { decimals }) ?? {};
      const { unitNetPrice, unitPoints } = totals ?? {};
      const normal = formatByCurrency(Number(unitNetPrice ?? 0));
      const productPoints = formatByCurrency(Number(unitPoints ?? 0));
      response += `${index + 1}. ${name} ${
        amount ? amount + "x" : ""
      } ${normal} ${
        unitPoints && !isNaN(unitPoints) ? "*" + productPoints + "*" : ""
      }\n`;
      response += `${modifiers ? modifiers + "\n" : ""}`;
    });
  } else {
    const products = getProducts(stores ?? []);
    products?.forEach((product, index) => {
      const { productName, additionalInfo } = product as OrderProduct;
      const { billProduct } = product as OrderProduct;
      const { price_in_points } = additionalInfo as {
        price_in_points?: number;
      };
      const { amount, total } = billProduct ?? {};

      let modifiers = "";
      if ("amount" in product) {
        modifiers = showModifiers(product);
      }

      response += `${index + 1}. ${titleCase(productName)} ${
        amount ? amount + "x" : ""
      } ${
        price_in_points
          ? `*${amount * price_in_points}pts*`
          : formatByCurrency(total)
      }`;
      response += `${modifiers ? modifiers + "\n" : ""}`;
    });
  }

  const totals = localShoppingCart
    ? getShoppingCartTotal(localShoppingCart)
    : undefined;
  const total = totals
    ? `${formatByCurrency(totals?.total ?? 0)}`
    : `${formatByCurrency(totalOrder ?? 0)}`;
  const { totalPoints } = totals ?? {};
  const points = totalPoints ? " | " + totalPoints + " puntos" : "";
  response += `\n${total ? "Total: " + total + points + "\n" : ""}`;

  return response;
};

export const buildTalkShopSuccessMessage = (
  talkShopIdentifier: string,
  orderId: number,
  message: string
) => ({
  id: `${orderId}`,
  did: `${PHONE_NUMBER}`,
  msisdn: `${talkShopIdentifier.trim()}`,
  type: "media",
  channel: "WHATSAPP",
  name: "Talkshop",
  content: message,
  isAttachment: false
});

export const finishTalkShop = () => {
  localStorage.removeItem(TALKSHOP_PROVIDER);
  localStorage.removeItem(TALKSHOP_IDENTIFIER);
};
