// Checkout store helper functions and data
import { StoreInitialValues } from "@multicines/stores";
import { AnonymousUserValues } from "@multicines/types";

import { CheckoutStoreValues } from "./checkout.store.types";
import { PICK_UP_OPTIONS } from "config/constants";

export const userInitialState: AnonymousUserValues = {
  documentType: "PASSPORT",
  document: "",
  name: "",
  lastname: "",
  email: "",
  phone: ""
};

export const defaultValues: StoreInitialValues<CheckoutStoreValues> = {
  userError: undefined,
  transferAnonymousId: undefined,
  step: undefined,
  password: undefined,
  selectedPickUpOption: PICK_UP_OPTIONS[0],
  anonymousBillingData: undefined,
  userFormValues: undefined,
  billingDataFormValues: undefined,
  sameBilling: true,
  status: undefined,
  message: undefined,
  placedOrder: undefined,
  areTermsAccepted: false
};

export const resetRejectedOrder = {
  status: undefined,
  placedOrder: undefined,
  message: undefined
};

export const resetDefaultValues = {
  status: undefined,
  placedOrder: undefined,
  userFormValues: undefined,
  billingFormValues: undefined,
  sameBilling: true,
  step: undefined,
  areTermsAccepted: false,
  message: undefined
};
