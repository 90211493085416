import React from "react";

import Styles from "./SelectCinemaPlaceholder.styles";
import { SelectCinemaPlaceholderProps as Props } from "./SelectCinemaPlaceholder.types";

const SelectCinemaPlaceholder: React.FC<Props> = props => {
  const store = (
    <>
      <div className="SelectCinemaPlaceholder__store" />
      <div className="SelectCinemaPlaceholder__distance" />
    </>
  );

  return (
    <Styles className="SelectCinemaPlaceholder">
      {store}
      {store}
      {store}
      {store}
      {store}
      {store}
    </Styles>
  );
};

SelectCinemaPlaceholder.defaultProps = {};

export default SelectCinemaPlaceholder;
