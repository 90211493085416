import React from "react";

import Styles from "./Divider.styles";
import { DividerProps as Props } from "./Divider.types";

const Divider: React.FC<Props> = props => {
  const { className, showMobile = true } = props;
  return <Styles className={`Divider ${className}`} showMobile={showMobile} />;
};

Divider.defaultProps = {
  className: ""
};

export default Divider;
