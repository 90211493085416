import React from "react";

import { ButtonStyled, LinkButtonStyled } from "./Button.styles";
import { PrimaryButtonStyled, SecondaryButtonStyled } from "./Button.styles";
import { ButtonProps } from "./Button.types";

const Button: React.FC<ButtonProps> = props => {
  const { className = "", mode = "PRIMARY", ...rest } = props;

  if (mode === "CUSTOM") {
    return <ButtonStyled {...rest} className={className} />;
  }

  if (mode === "SECONDARY") {
    return <SecondaryButtonStyled {...rest} className={className} />;
  }

  if (mode === "LINK") {
    return <LinkButtonStyled {...rest} className={className} />;
  }

  return <PrimaryButtonStyled {...rest} className={className} />;
};

export default Button;
