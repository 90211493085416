// TalkShop store helper functions and data

import { StoreInitialValues } from "@multicines/stores";

import { TalkShopStoreValues } from "./talkShop.store.types";

export const defaultValues: StoreInitialValues<TalkShopStoreValues> = {
  talkShopIdentifier: undefined,
  talkShopProvider: undefined,
  talkShopMedium: undefined
};
