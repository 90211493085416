// useShippingCost custom hook
import { useFetchShippingCost } from "@multicines/services";
import { useGeoStore } from "@multicines/stores";
import { useCataloguesStore } from "@multicines/stores";
import { useMemo } from "react";

import { useStoresStore } from "stores/stores/stores.store";

const useShippingCost = () => {
  const catalogueId = useCataloguesStore(
    state => state.selectedCatalogue.catalogueId
  );
  const selectedStore = useStoresStore(store => store.selectedStore);
  const [ticketsStore] = selectedStore ?? [];
  const { storeId } = ticketsStore ?? {};
  const selectedCoordinates = useGeoStore(state => state.selectedCoordinates);
  const { lat, lng } = selectedCoordinates ?? {};

  const { data: shippingCost } = useFetchShippingCost({
    storeId,
    catalogueId,
    lat,
    lng
  });

  return useMemo(() => {
    return shippingCost ?? null;
  }, [shippingCost]);
};

export default useShippingCost;
