import styled from "styled-components";

import { PinMarkerStyledProps as Props } from "./PinMarker.types";

const PinMarkerStyled = styled.div<Props>`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  pointer-events: none;

  .PinMarker {
    &__icon {
      transform: translate(-50%, -100%);
    }
  }
`;

export default PinMarkerStyled;
