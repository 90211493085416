import dayjs from "dayjs";
import { doc, collection, getDoc } from "firebase/firestore";
import { updateDoc, setDoc } from "firebase/firestore";

import { db } from "config/artisn.config";
import { notify } from "config/bugsnag.config";

export const addPurchaseCount = async () => {
  try {
    const date = dayjs().format("DDMMYYYY");
    const purchasesCollection = collection(db, `purchases`);
    const purchasesDoc = doc(purchasesCollection, date);
    const purchasesDocDataObject = await getDoc(purchasesDoc);
    const purchasesDocData = purchasesDocDataObject.data();
    if (purchasesDocData) {
      await updateDoc(purchasesDoc, {
        count: purchasesDocData?.count + 1
      });
      return;
    }
    await setDoc(purchasesDoc, {
      count: 1
    });
  } catch (error) {
    notify(error, "addPurchaseCount - Firebase utils");
    return error;
  }
};
