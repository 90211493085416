import { updateUser } from "@multicines/services";
import { User, GoogleAuthProvider, signInWithCustomToken } from "firebase/auth";
import { signInWithPopup, signInWithCredential } from "firebase/auth";
import { FacebookAuthProvider, unlink } from "firebase/auth";
import { signInAnonymously as signInAnonymouslyFirebase } from "firebase/auth";
import { EmailAuthProvider, linkWithCredential } from "firebase/auth";
import { signInWithEmailLink as signInWithEmailLinkFirebase } from "firebase/auth";
import { isSignInWithEmailLink as isSignInWithEmailLinkFirebase } from "firebase/auth";
import { sendSignInLinkToEmail as sendSignInLinkToEmailFirebase } from "firebase/auth";
import { signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase } from "firebase/auth";
import { sendPasswordResetEmail as sendPasswordResetEmailFirebase } from "firebase/auth";
import { useCallback, useMemo } from "react";

import { UseAuthValues } from "./useAuth.types";
import { getAuth } from "config/artisn.config";
import { notify } from "config/bugsnag.config";
import useI18n from "hooks/useI18n";
import { useAuthStore } from "stores/auth/auth.store";
import { getFirebaseAuthErrorMessage } from "utils/common.utils";

const useAuth = (): UseAuthValues => {
  const setIsAnonymous = useAuthStore(state => state.setIsAnonymous);
  const setUid = useAuthStore(state => state.setUid);
  const setProviderData = useAuthStore(state => state.setProviderData);
  const t = useI18n().errors.firebase.auth;

  const signInAnonymously = useCallback(async () => {
    return await signInAnonymouslyFirebase(getAuth);
  }, []);

  const getEmail = () => {
    return getAuth?.currentUser?.email;
  };

  const authStateChangeHandler = useCallback(
    (user: User | null) => {
      updateUser(user);
      const { uid: userId, isAnonymous: isUserAnonymous } = user ?? {};
      const { providerData: provider } = user ?? {};
      setIsAnonymous(!!isUserAnonymous);
      if (provider) {
        setProviderData(provider);
      }
      if (userId) {
        setUid(userId);
        return;
      }
      signInAnonymously();
    },
    [setIsAnonymous, setProviderData, setUid, signInAnonymously]
  );

  return useMemo(
    () => ({
      signInWithEmailAndPassword: async (email: string, password: string) => {
        return await signInWithEmailAndPasswordFirebase(
          getAuth,
          email,
          password
        );
      },
      signInWithGoogle: async () => {
        const provider = new GoogleAuthProvider();
        const response = await signInWithPopup(getAuth, provider);
        const credential = GoogleAuthProvider.credentialFromResult(response);
        if (!credential || getAuth.currentUser?.isAnonymous) return response;
        await signInWithCredential(getAuth, credential);
        return response;
      },
      signInWithFacebook: async () => {
        const provider = new FacebookAuthProvider();
        const response = await signInWithPopup(getAuth, provider);
        const credential = FacebookAuthProvider.credentialFromResult(response);
        if (!credential || getAuth.currentUser?.isAnonymous) return response;
        await signInWithCredential(getAuth, credential);
        return response;
      },
      signInAnonymously: async () => {
        return await signInAnonymouslyFirebase(getAuth);
      },
      signInWithCustomToken: async token => {
        return await signInWithCustomToken(getAuth, token);
      },
      sendPasswordResetEmail: async (email: string) => {
        return await sendPasswordResetEmailFirebase(getAuth, email);
      },
      registerWithEmailAndPassword: async (email: string, password: string) => {
        const credential = EmailAuthProvider.credential(email, password);
        if (!getAuth?.currentUser) return;
        const response = await linkWithCredential(
          getAuth.currentUser,
          credential
        );
        if (response) authStateChangeHandler(response.user);
        return response;
      },
      sendSignInLinkToEmail: async (email: string) => {
        const url = process.env.NEXT_PUBLIC_MAGIC_LINK;
        if (!url) throw new Error("Missing resolve url");
        try {
          return await sendSignInLinkToEmailFirebase(getAuth, email, {
            url,
            handleCodeInApp: true
          });
        } catch (error) {
          notify(error, "sendSignInLinkToEmail - useAuth");
          throw new Error(getFirebaseAuthErrorMessage(t, error));
        }
      },
      signInWithEmailLink: async (email: string, emailLink: string) => {
        try {
          return await signInWithEmailLinkFirebase(getAuth, email, emailLink);
        } catch (error) {
          notify(error, "signInWithEmailLink - useAuth");
          throw new Error(getFirebaseAuthErrorMessage(t, error));
        }
      },
      isSignInWithEmailLink: (emailLink: string) => {
        try {
          return isSignInWithEmailLinkFirebase(getAuth, emailLink);
        } catch (error) {
          notify(error, "isSignInWithEmailLink - useAuth");
          throw new Error(getFirebaseAuthErrorMessage(t, error));
        }
      },
      unlinkGoogle: async () => {
        const provider = new GoogleAuthProvider();
        if (!getAuth?.currentUser) return;
        const user = await unlink(getAuth.currentUser, provider.providerId);
        if (user) authStateChangeHandler(user);
      },
      unlinkFacebook: async () => {
        const provider = new FacebookAuthProvider();
        if (!getAuth?.currentUser) return;
        const user = await unlink(getAuth.currentUser, provider.providerId);
        if (user) authStateChangeHandler(user);
      },
      email: getEmail(),
      listenAuthState: () => {
        const authSubscriber = getAuth.onAuthStateChanged(
          authStateChangeHandler
        );

        return authSubscriber;
      }
    }),
    [authStateChangeHandler, t]
  );
};

export default useAuth;
