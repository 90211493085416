import PinSVG from "images/pin.svg";
import React from "react";

import Styles from "./PinMarker.styles";
import { PinMarkerProps as Props } from "./PinMarker.types";

const PinMarker: React.FC<Props> = props => {
  const { text } = props;

  return (
    <Styles className="PinMarker" title={text}>
      <PinSVG className="PinMarker__icon" />
    </Styles>
  );
};

PinMarker.defaultProps = {};

export default PinMarker;
