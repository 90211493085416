// Auth store
import { getStoreSetState } from "@multicines/stores";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { defaultValues, resetDefaultValues } from "./auth.store.helpers";
import { AuthStoreValues } from "./auth.store.types";

export const useAuthStore = create<AuthStoreValues>()(
  devtools(
    (set, get) => {
      const store: AuthStoreValues = {
        ...defaultValues,
        setClubName: payload => {
          const prev = get().clubName;
          const clubName = getStoreSetState(payload, prev);
          set({ clubName }, false, { type: "setClubName", payload });
        },
        setIsAnonymous: payload => {
          const prev = get().isAnonymous;
          const isAnonymous = getStoreSetState(payload, prev);
          set({ isAnonymous }, false, {
            type: "setIsAnonymous",
            payload
          });
        },
        setUid: payload => {
          const prev = get().uid;
          const uid = getStoreSetState(payload, prev);
          set({ uid }, false, {
            type: "setUid",
            payload
          });
        },
        setProviderData: payload => {
          const prev = get().providerData;
          const providerData = getStoreSetState(payload, prev);
          set({ providerData }, false, { type: "setProviderData", payload });
        },
        resetAuthContext: () => {
          set({ ...resetDefaultValues }, false, { type: "resetAuthContext" });
        }
      };
      return store;
    },
    { name: "Auth store" }
  )
);
